import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Title from '../../components/LeftSidebarTitle/LeftSidebarTitle';
import LeftSidebarLocation from './components/LeftSidebar.Location';
import LeftSidebarMenu from '../../components/LeftSidebarMenu/LeftSidebarMenu';
import Button from '../../components/Button/Button';
import LeftSidebarCustomise from './components/LeftSidebar.Customise';
import LeftSidebarSizeExtras from './components/LeftSidebar.SizeExtras';
import AddToCard from './components/LeftSidebar.AddToCart';
import CloseSidebar from '../../components/CloseSidebar/CloseSidebar';
import { TABLET_SIZE } from '../../constants/mapConstants';
import MapboxInfo from '../../context/MapboxContext';
import PriceTag from '../../components/PriceTag/PriceTag';

const LeftSidebarView = ({
  currentTitleAndText, getRandomCity, bestsellerCities, getUserLocation, onChangeMapSignature,
  currentTab, changeTab, currentMapStyle, changeMapStyle, currentMapOrientation,
  changeMapOrientation, useCityCoordinates, getChoiceSize, activeSizeElement, getChoiceFrame, activeFrameElement,
  nextStep, currentButtonText, customizeMap, hideSidebar, makePDF, windowWidth,
  isCollapsed, closeButtonPosition, finalPrice, getBestSellersPlace, searchCityByName, app, disableRequestButton
}) => {
  const { leftSideBarRef } = useContext(MapboxInfo);
  return (
    <div
      className={cx({
        'left-sidebar': true,
        'left-sidebar-height-auto': isCollapsed,
      })}
      ref={leftSideBarRef}
    >
      <CloseSidebar
        hideSidebar={hideSidebar}
        isCollapsed={isCollapsed}
        closeButtonPosition={closeButtonPosition}
      />
      <div
        className={cx({
          container: true,
          'left-sidebar__content': true,
          'left-sidebar__content--hide-mobile': isCollapsed,
        })}
      >
        <Title
          currentTitleAndText={currentTitleAndText}
        />

        {currentTab === 0
        && (
          <LeftSidebarLocation
            getRandomCity={getRandomCity}
            bestsellerCities={bestsellerCities}
            getUserLocation={getUserLocation}
            getBestSellersPlace={getBestSellersPlace}
            searchCityByName={searchCityByName}
          />
        )}
        {currentTab === 1
        && (
          <LeftSidebarCustomise
            onChangeMapSignature={onChangeMapSignature}
            currentMapStyle={currentMapStyle}
            changeMapStyle={changeMapStyle}
            currentMapOrientation={currentMapOrientation}
            changeMapOrientation={changeMapOrientation}
            useCityCoordinates={useCityCoordinates}
          />
        )}
        {currentTab === 2
        && (
          <LeftSidebarSizeExtras
            getChoiceSize={getChoiceSize}
            activeSizeElement={activeSizeElement}
            getChoiceFrame={getChoiceFrame}
            activeFrameElement={activeFrameElement}
          />
        )}
        {currentTab === 3 && (
          <AddToCard
            finalPrice={finalPrice}
            customizeMap={customizeMap}
            activeFrameElement={activeFrameElement}
            activeSizeElement={activeSizeElement}
            currentMapOrientation={currentMapOrientation}
          />
        )}

        {windowWidth > TABLET_SIZE ? (
          <Button
            text={currentButtonText}
            className="button-next-step"
            onClick={() => (currentTab === 3 ? makePDF() : nextStep())}
            type="button"
            disable={disableRequestButton}
          />
        ) : currentTab === 3 && (
        <Button
          text={currentButtonText}
          className="button-next-step"
          onClick={makePDF}
          type="button"
          disable={disableRequestButton}
        />
        )}
      </div>

      <LeftSidebarMenu
        app={app}
        currentTab={currentTab}
        changeTab={changeTab}
        isCollapsed={isCollapsed}
        windowWidth={windowWidth}
      />
      <PriceTag changeTab={changeTab} />
    </div>
  );
};

LeftSidebarView.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  disableRequestButton: PropTypes.bool.isRequired,
  closeButtonPosition: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  finalPrice: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  activeFrameElement: PropTypes.string.isRequired,
  activeSizeElement: PropTypes.string.isRequired,
  currentMapStyle: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  currentMapOrientation: PropTypes.string.isRequired,
  bestsellerCities: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    total: PropTypes.string,
    zoom: PropTypes.string,
    scrollStep: PropTypes.string,
  })).isRequired,
  currentButtonText: PropTypes.shape({}).isRequired,
  currentTitleAndText: PropTypes.shape({ title: PropTypes.object, text: PropTypes.object }).isRequired,
  hideSidebar: PropTypes.func.isRequired,
  getUserLocation: PropTypes.func.isRequired,
  changeMapStyle: PropTypes.func.isRequired,
  changeMapOrientation: PropTypes.func.isRequired,
  useCityCoordinates: PropTypes.func.isRequired,
  getChoiceSize: PropTypes.func.isRequired,
  getRandomCity: PropTypes.func.isRequired,
  getChoiceFrame: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  customizeMap: PropTypes.func.isRequired,
  makePDF: PropTypes.func.isRequired,
  onChangeMapSignature: PropTypes.func.isRequired,
  getBestSellersPlace: PropTypes.func.isRequired,
  searchCityByName: PropTypes.func.isRequired,
};

export default LeftSidebarView;
