// export default {
//   B1: { // 930/630 = 1.47619047619 // 630 / 930 =  0.67741935483
//     defaultHeight: 432.362903226, // 555 / 1.47619047619 = 375.967741936
//     defaultWidth: 638.25,
//     maxMapHeight: 821.801129033,
//     maxMapWidth: 1213.135,
//     mapScrollSpeedHeightPx: 16.2233467742,
//     mapScrollSpeedWidthPx: 23.94875
//   },
//   B2: { // 640/440 = 1.45454545455 // 700 / 500 = 1.4
//     defaultHeight: 438.796874999, // 555 / 1.45454545455 = 381.562499999
//     defaultWidth: 638.25,
//     maxMapHeight: 834.030312497,
//     maxMapWidth: 1213.135,
//     mapScrollSpeedHeightPx: 16.4647656249,
//     mapScrollSpeedWidthPx: 23.94875
//   },
//   X1: { // 450/350 = 1.28571428571
//     defaultHeight: 496.416666668, // 555 / 1.28571428571 = 431.666666668
//     defaultWidth: 638.25,
//     maxMapHeight: 943.549444448,
//     maxMapWidth: 1213.135,
//     mapScrollSpeedHeightPx: 18.6268055556,
//     mapScrollSpeedWidthPx: 23.94875
//   },
//   A3: { // 360/260 = 1.38461538462
//     defaultHeight: 460.958333332, // 555 / 1.38461538462 = 400.833333332
//     defaultWidth: 638.25,
//     maxMapHeight: 876.153055553,
//     maxMapWidth: 1213.135,
//     mapScrollSpeedHeightPx: 17.2963194444,
//     mapScrollSpeedWidthPx: 23.94875
//   },
// };

export default {
  B1: { // 930/630 = 1.47619047619 // 630 / 930 =  0.67741935483
    defaultHeight: 375.967741936, // 555 / 1.47619047619 = 375.967741936
    defaultWidth: 555,
    maxMapHeight: 714.60967742,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 14.1072580645,
    mapScrollSpeedWidthPx: 20.825
  },
  B2: { // 640/440 = 1.45454545455 // 700 / 500 = 1.4
    defaultHeight: 381.562499999, // 555 / 1.45454545455 = 381.562499999
    defaultWidth: 555,
    maxMapHeight: 725.243749998,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 14.3171875,
    mapScrollSpeedWidthPx: 20.825
  },
  X1: { // 450/350 = 1.28571428571
    defaultHeight: 431.666666668, // 555 / 1.28571428571 = 431.666666668
    defaultWidth: 555,
    maxMapHeight: 820.477777781,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 16.1972222223,
    mapScrollSpeedWidthPx: 20.825
  },
  A3: { // 360/260 = 1.38461538462
    defaultHeight: 400.833333332, // 555 / 1.38461538462 = 400.833333332
    defaultWidth: 555,
    maxMapHeight: 761.87222222,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 15.0402777777,
    mapScrollSpeedWidthPx: 20.825
  },
};
