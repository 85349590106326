const frameSizeWijck = {
  portrait: {
    gold: {
      B1: { height: 169, width: 71, padding: 10 },
      B2: { height: 170, width: 79, padding: 18 },
      X1: { height: 186, width: 94, padding: 24 },
      A3: { height: 178, width: 86, padding: 23 },
    },
    black: {
      B1: { height: 184, width: 86, padding: 20 },
      B2: { height: 186, width: 99, padding: 26 },
      X1: { height: 200, width: 109, padding: 33 },
      A3: { height: 196, width: 107, padding: 31 },
    },
    noFrame: {
      B1: { height: 188, width: 90, padding: 22 },
      B2: { height: 191, width: 100, padding: 28 },
      X1: { height: 204, width: 110, padding: 33 },
      A3: { height: 198, width: 111, padding: 34 },
    }
  },
  landscape: {
    gold: {
      B1: { height: 124, width: 58, padding: 6 },
      B2: { height: 139, width: 69, padding: 10 },
      X1: { height: 151, width: 83, padding: 16 },
      A3: { height: 148, width: 79, padding: 16 },
    },
    black: {
      B1: { height: 143.68, width: 78, padding: 16 },
      B2: { height: 156, width: 88, padding: 19 },
      X1: { height: 170, width: 99, padding: 26 },
      A3: { height: 164, width: 99, padding: 26 },
    },
    noFrame: {
      B1: { height: 147, width: 80, padding: 16 },
      B2: { height: 162, width: 91, padding: 19 },
      X1: { height: 174, width: 102, padding: 26 },
      A3: { height: 170, width: 100, padding: 26 },
    }
  }
};
export default frameSizeWijck;
