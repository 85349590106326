import cityCorrection from '../constants/cityCorrection';
import getLanguage from './getLanguage';

const countrySpecificOptimization = (value) => {
  const result = cityCorrection.filter((item) => (item.en.toLowerCase() === value.city.toLowerCase()));
  const newValue = { ...value };
  newValue.city = result[0][getLanguage()];
  return newValue;
};

export default countrySpecificOptimization;
