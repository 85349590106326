import React, { Component } from 'react';
import HomePageB2BView from './HomePageB2BView';
import portraitSize from '../../constants/portraitSize';
import portraitSizeWijck from '../../constants/portraitSizeWijck';
import landscapeSize from '../../constants/landscapeSize';
import landscapeSizeWijck from '../../constants/landscapeSizeWijck';
import MapboxInfoB2B from '../../context/MapboxContextB2B';
import {
  LEFT_SIDEBAR_WIDTH, LOGO_HEIGHT, MAX_MAP_SIZE, MAX_MAP_SIZE_ON_PHONE, MAX_SCROLL_STEP_MAP, MAX_ZOOM_LVL, MIN_ZOOM_LVL,
  SCREEN_PADDING, SCROLL_SPEED_MULTIPLIER, TABLET_SIZE
} from '../../constants/mapConstants';
import '../../scss/pages/home/home.scss';
import { mapboxStylesApi, PORTRAIT, WIJCK } from '../../constants/general';
import { GET_PLACE_API, MAP_STYLE_WIJCK, MAPBOX_TOKEN } from '../../constants/mapboxAPI';
import FrameSize from '../../constants/frameSize';
import haveCityInBase from '../../utils/haveCityInBase';
import getInformationOnCity from '../../utils/getInformationOnCity';
import getZoomPosition from '../../utils/getZoomPosition';
import recalculationSizeForMobileAndDesktop from '../../utils/recalculationSizeForMobileAndDesktop';
import { checkDisplayAndSizeScreen, convertCoordinate, findDefaultParamsForCity } from '../../utils/general';
import getLanguage from '../../utils/getLanguage';
import showGeolocationError from '../../utils/showGeolocationError';

export default class HomePage extends Component {
  // eslint-disable-next-line react/static-property-placement,react/sort-comp
  static contextType = MapboxInfoB2B;

  constructor(props) {
    super(props);

    this.state = {
      mapboxInfo: {
        style: MAP_STYLE_WIJCK,
        zoom: 11,
        size: 'B2',
        orientation: 'portrait',
        frame: 'black',
        city: 'Amsterdam',
        coordinate: '52.35°N 4.90°E',
        country: 'The Netherlands',
      },
      isMoveMapOrChangeInputCoordinate: true,
      viewport: {
        latitude: 52.36892,
        longitude: 4.90314,
        mapStyle: 'ontouchstart' in window ? mapboxStylesApi.wijck : mapboxStylesApi.wijckDesktop,
        zoom: this.initializationMapZoom(),
        height: '734.045',
        width: '504.656px',
      },
      searchVisibility: true,
      inputSearchValue: '',
      mapWidth: 504.656,
      mapHeight: 734.045,
      maxMapHeight: 1213.135,
      maxMapWidth: 834.030312497,
      scrollStep: 2,
      userLocation: '',
      zoomButtonsPosition: {
        top: -100,
        left: -100,
      },
      isTouchScreen: 'ontouchstart' in window,
      frameSize: {
        height: 188,
        width: 97,
        padding: 29.9
      },
      defaultMapSize: {
        defaultWidth: 443.437499999,
        defaultHeight: 645,
      },
      mapStyleName: WIJCK
    };

    this.mapRef = React.createRef();
    this.geoCoderRef = React.createRef();
    this.mapBlockRef = React.createRef();
    this.leftSidebarRef = React.createRef();

    this.getUserPosition();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    document.removeEventListener('touchmove', this.inputBlur);
    window.addEventListener('orientationchange', () => { this.forceUpdatePage(); });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      zoomButtonsPosition: { top: prevTop, left: prevLeft },
      mapboxInfo: { orientation: prevOrientation },
      mapStyleName: prevMapStyleName
    } = prevState;
    const { mapboxInfo: { orientation, frame }, mapStyleName } = this.state;
    const { left, top } = getZoomPosition(frame, this.mapBlockRef, this.leftSidebarRef);
    if (top !== prevTop || left !== prevLeft || mapStyleName !== prevMapStyleName) {
      this.savePosition(top, left);
    }
    if (orientation !== prevOrientation) {
      this.saveFrameSize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('touchmove', this.inputBlur);
    window.removeEventListener('orientationchange', () => { this.forceUpdatePage(); });
  }

  initializationMapZoom = () => {
    if (checkDisplayAndSizeScreen()) {
      return 11.714;
    }
    return 11;
  };

  inputBlur = () => {
    const input = document.querySelector('input.mapboxgl-ctrl-geocoder--input');
    if (input) {
      input.blur();
    }
  };

  savePosition = (top, left) => {
    this.setState({
      zoomButtonsPosition: {
        top,
        left
      }
    });
  };

  getUserPosition = (params) => {
    if (navigator.geolocation) {
      if (params === 'viewOnMapUserLocation') {
        navigator.geolocation.getCurrentPosition(
          this.fetchPosition,
          showGeolocationError
        );
      } else {
        navigator.geolocation.getCurrentPosition(
          this.fetchPositionForPay,
          showGeolocationError
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by this browser.');
    }
  };

  fetchPositionForPay = async ({ coords: { latitude, longitude } }) => {
    this.getPlaceForGeocoding(latitude, longitude)
      .then(await getInformationOnCity)
      .then(({ country }) => {
        this.setState({
          userLocation: country
        });
      });
  };

  fetchPosition = async ({ coords: { latitude, longitude } }) => {
    this.getPlaceForGeocoding(latitude, longitude)
      .then(await getInformationOnCity)
      .then(({ country, city }) => {
        const coordinate = convertCoordinate(latitude, longitude);
        const { viewport, mapboxInfo } = this.state;
        this.setState({
          viewport: {
            ...viewport,
            latitude,
            longitude,
          },
          mapboxInfo: {
            ...mapboxInfo,
            city,
            country,
            coordinate,
          },
          inputSearchValue: city,
        });
      });
  };

  getPlaceForGeocoding = async (lat, lng) => {
    const api = `${GET_PLACE_API}/${lng},${lat}.json/?language=${getLanguage()}&access_token=${MAPBOX_TOKEN}`;
    const response = await fetch(api, {
      method: 'GET'
    });
    return response.json();
  };

  getWindowDimensions = () => {
    return { width: window.innerWidth, height: window.innerHeight };
  };

  changeTab = (newTab) => {
    this.setState({
      searchVisibility: (newTab === 0),
    });
  };

  changeFrame = (frame) => {
    this.setMapboxInfoValue('frame', frame);
  };

  setMapboxInfoValue = (prop, value) => {
    const { mapboxInfo } = this.state;
    this.setState({
      mapboxInfo: {
        ...mapboxInfo,
        [prop]: value
      },
      isMoveMapOrChangeInputCoordinate: true
    });
  };

  onChangeMapSignature = (name, value) => {
    this.setMapboxInfoValue(name, value);
  };

  setMapboxInfoSettings = (settings) => {
    const { mapboxInfo, scrollStep, mapStyleName } = this.state;
    const nextMapboxInfo = {
      ...mapboxInfo,
      ...settings
    };
    const { orientation, size } = nextMapboxInfo;
    const portrait = mapStyleName === WIJCK ? portraitSizeWijck : portraitSize;
    const landscape = mapStyleName === WIJCK ? landscapeSizeWijck : landscapeSize;
    const mapSizes = orientation === PORTRAIT ? portrait : landscape;
    const newWidth = mapSizes[size].defaultWidth + ((scrollStep - 1)
      * (mapSizes[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER));
    const newHeight = mapSizes[size].defaultHeight + ((scrollStep - 1)
      * (mapSizes[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER));
    const { maxMapWidth, maxMapHeight } = mapSizes[size];
    this.setState({
      mapboxInfo: nextMapboxInfo,
      mapWidth: newWidth,
      mapHeight: newHeight,
      maxMapWidth,
      maxMapHeight,
      isMoveMapOrChangeInputCoordinate: false
    });
  };

  changeOrientation = (orientation) => {
    this.setMapboxInfoSettings({ orientation });
  };

  changeSize = (size) => {
    this.setMapboxInfoSettings({ size });
  };

  changeMapStyle = (mapStyles) => {
    const mapStyle = mapStyles === WIJCK ? mapboxStylesApi.wijckDesktop : mapboxStylesApi[mapStyles];
    const { viewport, mapboxInfo: { size } } = this.state;
    this.setState(
      {
        viewport: { ...viewport, mapStyle },
        mapStyleName: mapStyles
      }, () => this.changeSize(size)
    );
  };

  filterMapSearch = (json) => {
    let result;
    if (json.place_type[0] === 'place' || json.place_type[1] === 'place') {
      result = json;
    }
    return result;
  };

  getDefaultMapSizes = () => {
    const { mapboxInfo: { orientation, size }, mapStyleName } = this.state;
    const portrait = mapStyleName === WIJCK ? portraitSizeWijck : portraitSize;
    const landscape = mapStyleName === WIJCK ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === PORTRAIT ? portrait : landscape;
    const mapWidth = mapSize[size].defaultWidth;
    const mapHeight = mapSize[size].defaultHeight;
    return { mapWidth, mapHeight };
  };

  getRandomCity = async ({
    latitude, longitude, zoom: currentZoom, scrollStep, city
  }) => {
    const { viewport, mapboxInfo, mapStyleName } = this.state;
    const coordinate = convertCoordinate(latitude, longitude);
    const res = await this.getPlaceByCity(city);
    const { country } = await getInformationOnCity(res);
    const result = recalculationSizeForMobileAndDesktop(mapboxInfo, scrollStep, currentZoom, mapStyleName);
    const { mapWidth, mapHeight } = result;
    const zoom = result.newZoom;
    this.setState({
      viewport: {
        ...viewport, longitude, latitude, zoom
      },
      mapboxInfo: {
        ...mapboxInfo, country, city, coordinate
      },
      inputSearchValue: city,
      mapWidth,
      mapHeight,
      scrollStep
    });
  };


  getPlaceByCity = async (city) => {
    const response = await fetch(
      `${GET_PLACE_API}/${city.trim()}.json?limit=5&language=${getLanguage()}&access_token=${MAPBOX_TOKEN}`, {
        method: 'GET',
      }
    );
    return response.json();
  };

  searchCityByName = async () => {
    let zoom = 11;
    const val = document.querySelector('input.mapboxgl-ctrl-geocoder--input').value;
    let searchRes;
    try {
      searchRes = await this.getPlaceByCity(val);
      const { viewport, mapboxInfo } = this.state;
      let { scrollStep } = this.state;
      let longitude; let latitude; let city;
      const results = await getInformationOnCity(searchRes);
      const { country } = results;
      if (haveCityInBase(results.city, results.latitude, results.longitude)) {
        const result = findDefaultParamsForCity(val);
        zoom = parseInt(result.zoom);
        longitude = parseFloat(result.longitude);
        latitude = parseFloat(result.latitude);
        scrollStep = parseInt(result.scrollStep);
        city = result[getLanguage()];
      } else {
        longitude = parseFloat(results.longitude);
        latitude = parseFloat(results.latitude);
        city = results.city;
      }
      const coordinate = convertCoordinate(latitude, longitude);
      const result = recalculationSizeForMobileAndDesktop(mapboxInfo, scrollStep, zoom);
      const { mapWidth, mapHeight } = result;
      zoom = result.newZoom;
      this.setState({
        viewport: {
          ...viewport, longitude, latitude, zoom
        },
        mapboxInfo: {
          ...mapboxInfo, country, city, coordinate
        },
        mapWidth,
        mapHeight,
        inputSearchValue: city,
        scrollStep,
      });
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('There is no such place!');
      return false;
    }
  };

  handleOnResult = async (event) => {
    const { mapboxInfo, viewport } = this.state;
    let { scrollStep } = this.state;
    let latitude = event.result.center[1];
    let longitude = event.result.center[0];
    let city = event.result.text;
    let zoom = 11;
    this.getPlaceForGeocoding(latitude, longitude)
      .then(await getInformationOnCity)
      .then(({ country }) => {
        if (haveCityInBase(city, latitude, longitude)) {
          const result = findDefaultParamsForCity(city);
          zoom = parseInt(result.zoom);
          longitude = parseFloat(result.longitude);
          latitude = parseFloat(result.latitude);
          scrollStep = parseInt(result.scrollStep);
          // eslint-disable-next-line no-prototype-builtins
          city = result.hasOwnProperty('cityName') ? result.cityName : result[getLanguage()];
        }
        const result = recalculationSizeForMobileAndDesktop(mapboxInfo, scrollStep, zoom);
        const { mapWidth, mapHeight } = result;
        zoom = result.newZoom;
        this.setState({
          mapboxInfo: {
            ...mapboxInfo, city, country, zoom
          },
          viewport: {
            ...viewport, zoom, longitude, latitude
          },
          inputSearchValue: city,
          scrollStep,
          mapWidth,
          mapHeight
        });
      });
  };

  zoomChange = (event) => {
    const { viewport: { zoom: zoomLvl } } = this.state;
    let zoom = zoomLvl;
    if (event === 'plus') {
      if (zoom !== MAX_ZOOM_LVL) {
        zoom += 1;
        this.handleViewport({ zoom });
      }
    } else if (zoom !== MIN_ZOOM_LVL) {
      zoom -= 1;
      this.handleViewport({ zoom });
    }
  };

  handleViewport = (newViewport) => {
    const { viewport } = this.state;
    this.setState({
      viewport: { ...viewport, ...newViewport }
    });
  };

  mapWheel = (e) => {
    const {
      mapboxInfo: { size, orientation }, viewport: { zoom }, mapHeight, mapWidth, isTouchScreen, mapStyleName
    } = this.state;

    let newWidth = mapWidth;
    let newHeight = mapHeight;
    const delay = e.deltaY;
    const portrait = mapStyleName === WIJCK ? portraitSizeWijck : portraitSize;
    const landscape = mapStyleName === WIJCK ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait : landscape;
    newHeight = delay < 0 ? newHeight + mapSize[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER
      : newHeight - mapSize[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER;

    newWidth = delay < 0 ? newWidth + mapSize[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER
      : newWidth - mapSize[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER;

    const maxMapSize = !isTouchScreen ? MAX_MAP_SIZE : MAX_MAP_SIZE_ON_PHONE;
    if (zoom === MIN_ZOOM_LVL && (newHeight < maxMapSize && newWidth < maxMapSize)) {
      this.changeMapWidthNHeight(newWidth, newHeight);
    }

    if ((newHeight > this.getSizes('h', 'max') && newWidth > this.getSizes('w', 'max'))) {
      this.zoomJumpUp();
      return;
    }

    if (newHeight < this.getSizes('h', 'min') && newWidth < this.getSizes('w', 'min')) {
      this.zoomJumpDown();
      return;
    }
    this.changeMapWidthNHeight(newWidth, newHeight);
  };


  changeMapWidthNHeight = (mapWidth, mapHeight) => {
    const { mapWidth: mapW, scrollStep } = this.state;
    const newScrollStep = mapW < mapWidth ? scrollStep + 1 : scrollStep - 1;
    this.setState({
      mapWidth,
      mapHeight,
      scrollStep: newScrollStep,
    });
  };

  getSizes = (sizeOrientation, maxOrMin) => {
    const { mapboxInfo: { size, orientation }, mapStyleName } = this.state;
    const portrait = mapStyleName === WIJCK ? portraitSizeWijck : portraitSize;
    const landscape = mapStyleName === WIJCK ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait : landscape;
    if (sizeOrientation === 'h') {
      return maxOrMin === 'max' ? mapSize[size].maxMapHeight : mapSize[size].defaultHeight;
    }
    return maxOrMin === 'max' ? mapSize[size].maxMapWidth : mapSize[size].defaultWidth;
  };

  zoomJumpDown = () => {
    const { viewport: { zoom } } = this.state;
    if (zoom !== MAX_ZOOM_LVL) {
      const newZoom = zoom + 1;
      this.zoomJump(this.getSizes('w', 'max'), this.getSizes('h', 'max'),
        newZoom);
    }
  };

  zoomJumpUp = () => {
    const { viewport: { zoom } } = this.state;
    if (zoom !== MIN_ZOOM_LVL) {
      const newZoom = zoom - 1;
      this.zoomJump(this.getSizes('w', 'min'), this.getSizes('h', 'min'),
        newZoom);
    }
  };

  zoomJump = (mapWidth, mapHeight, zoom) => {
    const { viewport: { zoom: currentZoom } } = this.state;
    const scrollStep = zoom > currentZoom ? MAX_SCROLL_STEP_MAP : 1;
    const width = `${mapWidth}px`;
    const height = `${mapHeight}px`;
    this.setState({
      mapWidth,
      mapHeight,
      scrollStep
    });
    this.handleViewport({ zoom, width, height });
  };

  getScale = () => {
    const { mapboxInfo: { orientation, size }, mapStyleName } = this.state;
    let { height, width } = this.getWindowDimensions();
    const currentWidth = width;

    const { width: frameSizeWidth, height: frameSizeHeight } = this.getFrameSize();
    const portrait = mapStyleName === WIJCK ? portraitSizeWijck : portraitSize;
    const landscape = mapStyleName === WIJCK ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait[size] : landscape[size];
    const frameHeight = mapSize.defaultHeight + frameSizeHeight;
    const frameWidth = mapSize.defaultWidth + frameSizeWidth;

    if (currentWidth > TABLET_SIZE) {
      width -= LEFT_SIDEBAR_WIDTH;
      height -= LOGO_HEIGHT;
    } else {
      height -= LOGO_HEIGHT;
      width -= SCREEN_PADDING * 2;
    }

    const scalePc = Math.min(
      1,
      height / frameHeight,
      width / frameWidth,
    );
    const scalePc4k = orientation === 'portrait'
      ? Math.min(1.3,
        Math.min(1.5, width / (frameWidth * 2) - 0.4),
        Math.min(1.5, height / (frameHeight) - 0.1))
      : Math.min(1.5,
        Math.min(1.5, width / (frameWidth * 2) - 0.1),
        Math.min(1.5, height / (frameHeight) - 0.8));

    const newScale = scalePc > scalePc4k ? scalePc : scalePc4k;

    let marginLeft = 0;
    let marginTop = 0;
    if (currentWidth <= TABLET_SIZE) {
      marginLeft = (frameWidth - width - SCREEN_PADDING * 2) / 2;
      marginTop = (frameHeight - height) / 2 - 115;
    }
    return {
      marginLeft: -marginLeft,
      marginTop: -marginTop,
      scale: parseFloat(newScale.toFixed(2)),
    };
  };

  handleGeocoderViewportChange = (viewport) => {
    return this.handleViewportChange({
      ...viewport,
    });
  };

  handleViewportChange = (newViewport) => {
    const { latitude, longitude } = newViewport;
    const coordinate = convertCoordinate(latitude, longitude);
    const { viewport, mapboxInfo, isMoveMapOrChangeInputCoordinate } = this.state;
    if (!isMoveMapOrChangeInputCoordinate) {
      this.setState({
        isMoveMapOrChangeInputCoordinate: true
      });
      return;
    }
    this.setState({
      viewport: { ...viewport, ...newViewport },
      mapboxInfo: {
        ...mapboxInfo, coordinate
      }
    });
  };

  getFrameSize = () => {
    const { mapboxInfo: { size, orientation, frame } } = this.state;
    const { height, width, padding } = FrameSize[orientation][frame][size];
    return { height, width, padding };
  };

  saveFrameSize = () => {
    const { height, width, padding } = this.getFrameSize();

    const { mapWidth, mapHeight } = this.getDefaultMapSizes();
    this.setState({
      frameSize: {
        height,
        width,
        padding
      },
      defaultMapSize: {
        defaultWidth: mapWidth,
        defaultHeight: mapHeight
      }
    });
  };

  forceUpdatePage = () => {
    this.forceUpdate();
  };

  onResize = () => {
    this.setState(this.getWindowDimensions());
  };

  render() {
    const {
      mapboxInfo, viewport, mapHeight, mapWidth, inputSearchValue, searchVisibility, userLocation,
      zoomButtonsPosition, scrollStep, frameSize, defaultMapSize, isTouchScreen, maxMapHeight, maxMapWidth, mapStyleName
    } = this.state;
    const scaleAndIndentation = this.getScale();
    return (
      <MapboxInfoB2B.Provider value={{
        scrollStep,
        mapboxInfo,
        searchVisibility,
        userLocation,
        viewport,
        isTouchScreen,
        mapStyleName,
        geoCoderRef: this.geoCoderRef,
        leftSideBarRef: this.leftSidebarRef,
        mapRef: this.mapRef,
        getRandomCity: this.getRandomCity,
        forceUpdatePage: this.forceUpdatePage,
        zoomJump: this.zoomJump,
        changeMapWidthNHeight: this.changeMapWidthNHeight,
        searchCityByName: this.searchCityByName,
        handleViewport: this.handleViewport,
        onChangeMapSignature: this.onChangeMapSignature,
        changeTab: this.changeTab,
        changeFrame: this.changeFrame,
        changeSize: this.changeSize,
        changeOrientation: this.changeOrientation,
        changeMapStyle: this.changeMapStyle,
        getUserPosition: this.getUserPosition,
      }}
      >
        <HomePageB2BView
          isTouchScreen={isTouchScreen}
          maxMapWidth={maxMapWidth}
          maxMapHeight={maxMapHeight}
          defaultMapSize={defaultMapSize}
          scaleAndIndentation={scaleAndIndentation}
          frameSize={frameSize}
          zoomButtonsPosition={zoomButtonsPosition}
          mapBlockRef={this.mapBlockRef}
          mapWheel={this.mapWheel}
          zoomChange={this.zoomChange}
          handleViewportChange={this.handleViewportChange}
          handleGeocoderViewportChange={this.handleGeocoderViewportChange}
          inputSearchValue={inputSearchValue}
          filterMapSearch={this.filterMapSearch}
          handleOnResult={this.handleOnResult}
          searchVisibility={searchVisibility}
          mapHeight={mapHeight}
          mapWidth={mapWidth}
          mapStyleName={mapStyleName}
        />
      </MapboxInfoB2B.Provider>
    );
  }
}
