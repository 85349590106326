import goldFramePortrait from '../assets/frame/frame_gold.png';
import goldFrameLandscape from '../assets/frame/frame_gold_landscape.png';

const createCanvas = async (cloneImgSrc, coordinate, city, country, frame, orientation) => {
  const borderColor = {
    gold: '#c9b591',
    black: '#000000',
    noFrame: '#c7c7c7',
  };
  const addText = (canvas, ctx) => {
    const variables = {
      portrait: {
        coordinate: [150, 244],
        city: [150, 259],
        country: [150, 266],
        logo: [150, 280],
        coordinateFZ: '3.5px',
        cityFZ: '16px',
        countryFZ: '3.8px',
        logoFz: '1.8px',
      },
      landscape: {
        coordinate: [150, 216],
        city: [150, 228],
        country: [150, 232.6],
        logo: [150, 245],
        coordinateFZ: '3.5px',
        cityFZ: '11.5px',
        countryFZ: '3.3px',
        logoFz: '1.5px',
      }
    };
    ctx.fillStyle = '#000000';
    ctx.font = `${variables[orientation].coordinateFZ} SourceSansPro-Regular`;
    ctx.textAlign = 'center';
    const newCity = city ? city.split('').join(String.fromCharCode(8202)).toUpperCase() : ' ';
    const newCountry = country || ' ';
    const newCoordinate = coordinate || ' ';
    ctx.fillText(`${newCoordinate}`, variables[orientation].coordinate[0],
      variables[orientation].coordinate[1]);
    ctx.font = `${variables[orientation].cityFZ} LeagueGothic`;
    ctx.fillText(`${newCity}`,
      variables[orientation].city[0], variables[orientation].city[1]);
    ctx.font = `${variables[orientation].countryFZ} DidotLTStd-Italic`;
    ctx.fillText(`${newCountry}`, variables[orientation].country[0],
      variables[orientation].country[1]);
    ctx.font = `${variables[orientation].logoFz} sans-serif`;
    ctx.fillText('WIJCK.', variables[orientation].logo[0], variables[orientation].logo[1]);
  };

  const addGoldBorder = async (ctx) => {
    const position = {
      portrait: [46, 5, 208, 285],
      landscape: [10, 46, 280, 208],
    };
    await new Promise((res) => {
      const image = new Image(100, 100);
      image.onload = () => {
        document.body.appendChild(image);
        ctx.drawImage(image, position[orientation][0], position[orientation][1],
          position[orientation][2], position[orientation][3]);
        res();
      };
      image.src = orientation === 'portrait' ? goldFramePortrait : goldFrameLandscape;
    });
  };
  const addBorder = async (canvas, ctx) => {
    if (frame === 'gold') {
      await addGoldBorder(ctx);
      return;
    }
    const blackFrame = 9;
    const noFrame = 0.5;
    ctx.lineWidth = frame === 'black' ? blackFrame : noFrame;
    ctx.strokeStyle = borderColor[frame];
    const position = {
      portrait: [46, 5, 208, 285],
      landscape: [10, 46, 280, 208]
    };
    ctx.strokeRect(position[orientation][0], position[orientation][1],
      position[orientation][2], position[orientation][3]);
  };


  const createImage = async (canvas, ctx) => {
    const position = {
      portrait: [62, 18, 176, 219],
      landscape: [24, 60, 253, 145]
    };
    await new Promise((res) => {
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, position[orientation][0], position[orientation][1],
          position[orientation][2], position[orientation][3]);
        res();
      };
      image.src = (`${cloneImgSrc}`);
    });
  };

  const fillingCanvas = async (canvas, ctx) => {
    // add border
    await addBorder(canvas, ctx);
    // add image
    await createImage(canvas, ctx);
    // add text
    addText(canvas, ctx);

    return canvas.toDataURL('image/png');
  };

  const initCanvas = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 300;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(255, 255, 255, 0.01)';
    ctx.fillRect(0, 0, 300, 300);
    return { canvas, ctx };
  };

  const start = async () => {
    const { canvas, ctx } = await initCanvas();
    // eslint-disable-next-line no-return-await
    return await fillingCanvas(canvas, ctx);
  };
  // eslint-disable-next-line no-return-await
  return await start();
};

export default createCanvas;
