const getZoomPosition = (frame, mapBlockRef, leftSidebarRef) => {
  let correctionIndent;
  const topOrLeftIndent = 40;
  if (frame === 'noFrame') {
    correctionIndent = 17;
  }
  if (frame === 'black') {
    correctionIndent = -1;
  }
  if (frame === 'gold') {
    correctionIndent = -1;
  }
  const { x, y } = mapBlockRef.current.getBoundingClientRect();
  const { width } = leftSidebarRef.current.getBoundingClientRect();
  const left = x - width - topOrLeftIndent + correctionIndent;
  const top = y + topOrLeftIndent + correctionIndent;
  return { left, top };
};

export default getZoomPosition;
