import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import MapboxInfoB2B from '../../../context/MapboxContextB2B';
import Input from '../../../components/Input/Input';
import t from '../../../i18n/translate';

const LeftSidebarB2BSave = ({
  onChangeName, onChangeDescription, inputSaveDescriptionValue, inputSaveNameValue, intl
}) => {
  const {
    mapboxInfo: {
      city, country, coordinate, orientation
    }
  } = useContext(MapboxInfoB2B);

  return (
    <div className="marginTopSection">
      <div className="save-block">
        <h4 className="tab-name">{t('Save.details')}</h4>
        <div className="save__details">
          <p className="save__details__customization">
            {t('Save.customise')}
            :
            {' '}
          </p>
          <span>
            { city }
            &nbsp;
            -
            &nbsp;
            {country}
            &nbsp;
            -
            &nbsp;
            {coordinate}
            &nbsp;
            -
            &nbsp;
            {t(`Save.${orientation}`)}
          </span>
        </div>
        <Input
          value={inputSaveNameValue}
          placeholder={intl.formatMessage({ id: 'Save.name' })}
          className="save-block__name"
          onChange={onChangeName}
        />
        <Input
          value={inputSaveDescriptionValue}
          placeholder={intl.formatMessage({ id: 'Save.description' })}
          className="save-block__description"
          onChange={onChangeDescription}
        />
      </div>
    </div>
  );
};

LeftSidebarB2BSave.propTypes = {
  inputSaveNameValue: PropTypes.string.isRequired,
  inputSaveDescriptionValue: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  intl: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
};

export default injectIntl(LeftSidebarB2BSave);
