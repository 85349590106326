import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeftSidebarView from './LeftSidebarView';
import bestsellersCities from '../../constants/bestsellersCities';
import {
  titleLeftSidebar, nextStepButtonsText, WIJCK, frameLists, PORTRAIT, LANDSCAPE, mapboxStylesApi,
} from '../../constants/general';
import { TABLET_SIZE } from '../../constants/mapConstants';
import RandomCity from '../../constants/randomCity';
import MapboxInfo from '../../context/MapboxContext';
import t from '../../i18n/translate';
import countrySpecificOptimization from '../../utils/countrySpecificOptimization';
import createCanvas from '../../utils/createCanvas';
import createCanvasWijck from '../../utils/createCanvasWijck';
import { didotLTStd, leagueGothic, sourceSansPro } from '../../constants/reqexpFontFamily';
import {
  generationId, getRandomNumbers, getRedirectURL, isWijck, transformObjectCity
} from '../../utils/general';
import WithAnalytics from '../../HOC/Analytics';
import metrica from '../../utils/metrica';

class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    const withCustomProps = props.location?.pathname.split('/')[2];
    this.state = {
      currentTab: 0,
      app: 'b2c',
      tabs: ['Location', 'Customise', 'SizeExtras', 'AddToCart'],
      currentMapStyle: WIJCK,
      currentMapOrientation: 'portrait',
      currentButtonText: t('Location.button_nextStep'),
      activeSizeElement: withCustomProps ? 'A3' : 'B2',
      activeFrameElement: withCustomProps ? 'noFrame' : 'black',
      finalPrice: 79.90,
      sizePrice: 39.90,
      bestsellerCities: this.getRandomBestsellerCities(),
      isCollapsed: true,
      disableRequestButton: false,
      closeButtonPosition: 1,
      currentTitleAndText: {
        title: t('Location.title'),
        text: t('Location.subTitle'),
        currentTab: 0
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOnDropdownInputSelect);
  }

  componentDidUpdate(prevProps, prevState) {
    const { closeButtonPosition: prevHeight, tabs } = prevState;
    const { currentTitleAndText, currentTab } = this.state;
    const height = this.getZoomPosition();

    if (height !== prevHeight) {
      this.savePosition(height);
    }
    if (currentTitleAndText.currentTab !== currentTab) {
      this.getTitleText();
    }
    const pageTabLocalStorage = localStorage.getItem('currentTab');
    if (pageTabLocalStorage !== tabs[currentTab]) {
      localStorage.setItem('currentTab', tabs[currentTab]);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOnDropdownInputSelect);
  }

  // eslint-disable-next-line react/static-property-placement,react/sort-comp
  static contextType = MapboxInfo;

  savePosition = (closeButtonPosition) => {
    this.setState({
      closeButtonPosition
    });
  };

  getZoomPosition = () => {
    let height = document.querySelector('div.container').offsetHeight;
    height += 100;
    return height;
  };

  getUserLocation = () => {
    const { getUserPosition } = this.context;
    getUserPosition('viewOnMapUserLocation');
    this.hideSidebar();
  };

  customizeMap = () => {
    this.changeTab(1);
  };

  getWindowDimensions = () => {
    return { windowWidth: window.innerWidth, windowHeight: window.innerHeight };
  };

  toggleIsCollapsed = (state) => {
    const { isCollapsed } = this.state;
    if (state === undefined) {
      this.setState({ isCollapsed: !isCollapsed });
    } else {
      this.setState({ isCollapsed: !!state });
    }
  };

  hideSidebar = () => {
    const { forceUpdatePage } = this.context;
    this.setState({
      isCollapsed: true,
    }, () => { forceUpdatePage(); });
  };


  useCityCoordinates = () => {
    const { viewport: { latitude, longitude }, onChangeMapSignature } = this.context;
    const coordinate = `${latitude.toFixed(2)}°N ${longitude.toFixed(2)}°E`;
    onChangeMapSignature('coordinate', coordinate, true);
  };

  getRandomBestsellerCities = () => {
    const randomNumbers = getRandomNumbers(1, bestsellersCities.length - 1, 4);
    return randomNumbers.map((item) => countrySpecificOptimization(bestsellersCities[item]));
  };

  changeTab = (indexNewTab) => {
    const {
      currentTab, isCollapsed, tabs
    } = this.state;
    const { changeTab } = this.context;
    const { windowWidth } = this.getWindowDimensions();
    if (isCollapsed && windowWidth < TABLET_SIZE) {
      this.toggleIsCollapsed(false);
    }
    let currentButtonText = t(`${tabs[indexNewTab]}.button_nextStep`);
    if (indexNewTab === Object.keys(nextStepButtonsText).length - 1) {
      currentButtonText = t('AddToCart.button_nextStep1');
    }

    if (currentTab !== indexNewTab) {
      this.setState({
        currentTab: indexNewTab,
        currentButtonText
      }, () => changeTab(indexNewTab));
    }
  };

  nextStep = () => {
    const { currentTab, tabs } = this.state;
    const { changeTab } = this.context;
    const nextTab = currentTab + 1;
    if (nextTab >= tabs) {
      return;
    }
    let currentButtonText = t(`${tabs[nextTab]}.button_nextStep`);
    if (nextTab === Object.keys(nextStepButtonsText).length - 1) {
      currentButtonText = t('AddToCart.button_nextStep1');
    }
    this.setState({
      currentTab: nextTab,
      currentButtonText
    }, () => changeTab(nextTab));
  };

  getFinalPrice = (withDot = false) => {
    const { finalPrice } = this.state;
    if (withDot) {
      return finalPrice.toFixed(2).toString();
    }
    return finalPrice.toFixed(2).toString()
      .replace('.', ',');
  };

  getTitleText = () => {
    const { currentTab } = this.state;
    this.setState({
      currentTitleAndText: {
        title: titleLeftSidebar.title[currentTab],
        text: titleLeftSidebar.subTitle[currentTab],
        currentTab,
      }
    });
  };

  getChoiceFrame = (name, price) => {
    const { sizePrice } = this.state;
    const { changeFrame } = this.context;
    const newFinalPrice = parseFloat(price) + parseFloat(sizePrice);
    changeFrame(name);
    this.setState({
      activeFrameElement: name,
      finalPrice: newFinalPrice,
    }, () => { this.hideSidebar(); });
  };

  getChoiceSize = (price, sizeName) => {
    const { activeFrameElement } = this.state;
    const framePrice = frameLists[activeFrameElement].price[sizeName];
    const newFinalPrice = parseFloat(framePrice) + parseFloat(price);
    const { changeSize } = this.context;
    this.setState({
      activeSizeElement: sizeName,
      finalPrice: newFinalPrice,
      sizePrice: price
    }, () => { changeSize(sizeName); this.hideSidebar(); });
  };

  getMapOrientation = (index) => (index === 0 ? PORTRAIT : LANDSCAPE);

  changeMapOrientation = (indexOrientation) => {
    const { changeOrientation } = this.context;
    const newMapOrientation = this.getMapOrientation(indexOrientation);
    this.setState({
      currentMapOrientation: newMapOrientation
    }, () => { changeOrientation(newMapOrientation); this.hideSidebar(); });
  };

  changeMapStyle = (currentMapStyle) => {
    const { changeMapStyle } = this.context;
    this.setState({
      currentMapStyle
    }, () => { changeMapStyle(currentMapStyle); this.hideSidebar(); });
  };

  onChangeMapSignature = ({ target: { value } }, name) => {
    const { onChangeMapSignature } = this.context;
    if (value.length <= 25 && name === 'changeCityName') {
      onChangeMapSignature('city', this.regExpFontFamily(value, leagueGothic), false);
    }
    if (value.length <= 37 && name === 'ChangeCountryName') {
      onChangeMapSignature('country', this.regExpFontFamily(value, didotLTStd), false);
    }
    if (value.length <= 50 && name === 'changeCoordinate') {
      onChangeMapSignature('coordinate', this.regExpFontFamily(value, sourceSansPro), false);
    }
  };

  regExpFontFamily = (text, req) => {
    const result = text.match(req);
    return result && result.join('');
  };

  getRandomCity = () => {
    const city = RandomCity[Math.floor(Math.random() * RandomCity.length)];
    const { getRandomCity } = this.context;
    getRandomCity(transformObjectCity(countrySpecificOptimization(city)));
    this.hideSidebar();
  };

  getBestSellersPlace = (item) => {
    const { getRandomCity } = this.context;
    getRandomCity(transformObjectCity(item));
    this.hideSidebar();
  };

  getPdf = async () => {
    const {
      viewport: {
        mapStyle, latitude, longitude, zoom, width, height
      }, mapboxInfo: {
        orientation, size, city, coordinate, country,
      }, scrollStep, isTouchScreen, cloneImgSrc, mapStyleName,
    } = this.context;
    let { mapboxInfo: { frame } } = this.context;
    if (frame === 'noFrame') {
      frame = 'white';
    }

    const image = isWijck(mapStyleName)
      ? await createCanvasWijck(cloneImgSrc, coordinate, city, country, frame, orientation)
      : await createCanvas(cloneImgSrc, coordinate, city, country, frame, orientation);
    const id = generationId();
    const api = '/submit-map';
    const newCity = city ? city.toUpperCase() : '';
    const body = {
      isTouchScreen,
      border_type: frame,
      scrollStep,
      width,
      height,
      type: 'b2c',
      zoom,
      image,
      map_style: isWijck(mapStyleName) ? mapboxStylesApi.wijckPrint : mapStyle,
      isWijckStyle: isWijck(mapStyleName),
      style : mapStyleName,
      location: {
        longitude,
        latitude
      },
      size,
      orientation,
      map_signature: {
        coords: coordinate || '',
        city: newCity,
        country: country || ''
      }
    };
    metrica(newCity, id, this.getFinalPrice(true));
    const response = await fetch(`${api}/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return {
      id,
      response
    };
  };

  makePDF = () => {
    this.handleButton(true);
    this.getPdf()
      .then(({ response, id }) => {
        if (response.status === 404 || response.status === 400) {
          // eslint-disable-next-line no-console
          console.error('400');
        }
        if (response.status === 200 || response.status === 201) {
          const url = getRedirectURL();
          document.location = `${url}?id=${id}`;
        }
        this.handleButton(false);
      })
      .catch(() => {
        this.handleButton(false);
      });
  };

  handleButton = (disableRequestButton) => {
    if (disableRequestButton) {
      this.setState({
        disableRequestButton,
      });
    } else {
      setTimeout(() => {
        this.setState({
          disableRequestButton,
        });
      }, 2000);
    }
  };

  searchCityByName = async () => {
    const { searchCityByName } = this.context;
    const result = await searchCityByName();
    if (result) {
      this.hideSidebar();
    }
  };

  handleClickOnDropdownInputSelect = (event) => {
    if (event.target.className === 'mapboxgl-ctrl-geocoder--suggestion-title'
        || event.target.className === 'mapboxgl-ctrl-geocoder--suggestion-address') {
      setTimeout(() => {
        this.hideSidebar();
      }, 500);
    }
  };

  render() {
    const {
      currentTab, currentMapStyle, currentMapOrientation, activeSizeElement, currentTitleAndText, finalPrice,
      activeFrameElement, currentButtonText, bestsellerCities, closeSidebarVisibility, isCollapsed,
      closeButtonPosition, app, disableRequestButton, count
    } = this.state;

    const { windowHeight, windowWidth } = this.getWindowDimensions();
    return (
      <LeftSidebarView
        disableRequestButton={disableRequestButton}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
        closeButtonPosition={closeButtonPosition}
        finalPrice={finalPrice}
        currentTitleAndText={currentTitleAndText}
        currentTab={currentTab}
        changeTab={this.changeTab}
        bestsellerCities={bestsellerCities}
        getRandomCity={this.getRandomCity}
        nextStep={this.nextStep}
        getUserLocation={this.getUserLocation}
        onChangeMapSignature={this.onChangeMapSignature}
        currentMapStyle={currentMapStyle}
        changeMapStyle={this.changeMapStyle}
        currentMapOrientation={currentMapOrientation}
        changeMapOrientation={this.changeMapOrientation}
        useCityCoordinates={this.useCityCoordinates}
        getChoiceSize={this.getChoiceSize}
        activeSizeElement={activeSizeElement}
        getChoiceFrame={this.getChoiceFrame}
        activeFrameElement={activeFrameElement}
        currentButtonText={currentButtonText}
        customizeMap={this.customizeMap}
        closeSidebarVisibility={closeSidebarVisibility}
        hideSidebar={this.hideSidebar}
        makePDF={this.makePDF}
        getBestSellersPlace={this.getBestSellersPlace}
        searchCityByName={this.searchCityByName}
        isCollapsed={isCollapsed}
        app={app}
        count={count}
      />
    );
  }
}

LeftSidebar.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

export default WithAnalytics(withRouter(LeftSidebar),);
