import getLanguage from './getLanguage';

const getInformationOnCity = ({ features }) => {
  let city = features.find((item) => item.place_type
    .find((type) => (type === 'place')));
  let country = features.find((item) => item.place_type
    .find((type) => (type === 'country')));
  if (!city) {
    city = features.find((any) => any.place_type
      .find((type) => type === 'locality'));
  }
  if (!country) {
    country = {
      text: features[0].context.find((item) => item.id.includes('country'))[`text_${getLanguage()}`]
    };
  }
  city = city ? { text: city.text, longitude: city.center[0], latitude: city.center[1] }
    : { text: 'city', longitude: 0, latitude: 0 };
  country = country ? { text: country.text } : { text: 'country' };
  return {
    city: city.text,
    country: country.text,
    longitude: city.longitude,
    latitude: city.latitude
  };
};

export default getInformationOnCity;
