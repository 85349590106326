export default {
  B1: {
    defaultHeight: 312.19,
    defaultWidth: 555,
    maxMapHeight: 589.83,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 11.64,
    mapScrollSpeedWidthPx: 20.825
  },
  B2: {
    defaultHeight: 312.19,
    defaultWidth: 555,
    maxMapHeight: 605,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 12.20,
    mapScrollSpeedWidthPx: 20.825
  },
  X1: {
    defaultHeight: 357.67,
    defaultWidth: 555,
    maxMapHeight: 679.83,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 13.42,
    mapScrollSpeedWidthPx: 20.825
  },
  A3: {
    defaultHeight: 331.46,
    defaultWidth: 555,
    maxMapHeight: 633.63,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 12.59,
    mapScrollSpeedWidthPx: 20.825
  },
  A4: {
    defaultHeight: 311.80,
    defaultWidth: 555,
    maxMapHeight: 594.29,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 11.77,
    mapScrollSpeedWidthPx: 20.825
  },
  postcard: {
    defaultHeight: 311.14,
    defaultWidth: 555,
    maxMapHeight: 566.27,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 11.67,
    mapScrollSpeedWidthPx: 20.825
  },
  sticker: {
    defaultHeight: 301.12,
    defaultWidth: 555,
    maxMapHeight: 552.90,
    maxMapWidth: 1054.9,
    mapScrollSpeedHeightPx: 11.40,
    mapScrollSpeedWidthPx: 20.825
  },
};
