import React from 'react';
import PropTypes from 'prop-types';
import Mapbox from './components/HomePageMapBox';
import LeftSidebar from '../../layout/LeftSidebar/LeftSidebar';

const HomePageView = ({
  scaleAndIndentation, mapWheel, mapBlockRef, zoomButtonsPosition, zoomChange, frameSize, defaultMapSize,
  handleViewportChange, handleGeocoderViewportChange, inputSearchValue, filterMapSearch, handleOnResult,
  searchVisibility, mapHeight, mapWidth, isTouchScreen, maxMapHeight, maxMapWidth, mapStyleName, isVisibleMobileOverlay,
  onTurnOffMobileOverlay
}) => {
  return (
    <div className="container-fluid">
      <LeftSidebar />
      <Mapbox
        isTouchScreen={isTouchScreen}
        maxMapHeight={maxMapHeight}
        maxMapWidth={maxMapWidth}
        defaultMapSize={defaultMapSize}
        frameSize={frameSize}
        scaleAndIndentation={scaleAndIndentation}
        mapBlockRef={mapBlockRef}
        mapWheel={mapWheel}
        zoomChange={zoomChange}
        zoomButtonsPosition={zoomButtonsPosition}
        handleViewportChange={handleViewportChange}
        handleGeocoderViewportChange={handleGeocoderViewportChange}
        inputSearchValue={inputSearchValue}
        filterMapSearch={filterMapSearch}
        handleOnResult={handleOnResult}
        searchVisibility={searchVisibility}
        mapHeight={mapHeight}
        mapWidth={mapWidth}
        mapStyleName={mapStyleName}
        isVisibleMobileOverlay={isVisibleMobileOverlay}
        onTurnOffMobileOverlay={onTurnOffMobileOverlay}
      />
    </div>
  );
};

HomePageView.propTypes = {
  isVisibleMobileOverlay: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  maxMapHeight: PropTypes.number.isRequired,
  maxMapWidth: PropTypes.number.isRequired,
  searchVisibility: PropTypes.bool.isRequired,
  mapHeight: PropTypes.number.isRequired,
  mapWidth: PropTypes.number.isRequired,
  mapStyleName: PropTypes.string.isRequired,
  inputSearchValue: PropTypes.string.isRequired,
  frameSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  defaultMapSize: PropTypes.shape({
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number
  }).isRequired,
  zoomButtonsPosition: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }).isRequired,
  mapBlockRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  scaleAndIndentation: PropTypes.shape({
    scale: PropTypes.number,
    marginLeft: PropTypes.number,
    marginTop: PropTypes.number,
  }).isRequired,
  zoomChange: PropTypes.func.isRequired,
  mapWheel: PropTypes.func.isRequired,
  handleViewportChange: PropTypes.func.isRequired,
  handleGeocoderViewportChange: PropTypes.func.isRequired,
  filterMapSearch: PropTypes.func.isRequired,
  handleOnResult: PropTypes.func.isRequired,
  onTurnOffMobileOverlay: PropTypes.func.isRequired,
};

export default HomePageView;
