import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeftSidebarB2BView from './LeftSidebarB2BView';
import { TABLET_SIZE } from '../../constants/mapConstants';
import bestsellersCities from '../../constants/bestsellersCities';
import RandomCity from '../../constants/randomCity';
import MapboxInfoB2B from '../../context/MapboxContextB2B';
import t from '../../i18n/translate';
import { mapboxStylesApi, titleLeftSidebarB2B as titleLeftSidebar, WIJCK } from '../../constants/general';
import countrySpecificOptimization from '../../utils/countrySpecificOptimization';
import {
  generationId, getRandomNumbers, getRedirectURL, transformObjectCity
} from '../../utils/general';
import { didotLTStd, leagueGothic, sourceSansPro } from '../../constants/reqexpFontFamily';

class LeftSidebarB2B extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      app: 'b2b',
      tabs: ['Location', 'Customise', 'Save'],
      currentMapStyle: WIJCK,
      currentMapOrientation: 'portrait',
      currentButtonText: t('LocationB2B.button_nextStep'),
      bestsellerCities: this.getRandomBestsellerCities(),
      isCollapsed: true,
      inputSaveNameValue: '',
      inputSaveDescriptionValue: '',
      closeButtonPosition: 1,
      currentTitleAndText: {
        title: t('Location.title'),
        text: t('Location.subTitle'),
        currentTab: 0
      }
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOnDropdownInputSelect);
  }

  componentDidUpdate(prevProps, prevState) {
    const { closeButtonPosition: prevHeight } = prevState;
    const { currentTitleAndText, currentTab } = this.state;
    const height = this.getZoomPosition();
    if (height !== prevHeight) {
      this.savePosition(height);
    }
    if (currentTitleAndText.currentTab !== currentTab) {
      this.getTitleText();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOnDropdownInputSelect);
  }

  savePosition = (closeButtonPosition) => {
    this.setState({ closeButtonPosition });
  };

  getZoomPosition = () => {
    let height = document.querySelector('div.container').offsetHeight;
    height += 100;
    return height;
  };

  getUserLocation = () => {
    const { getUserPosition } = this.context;
    getUserPosition('viewOnMapUserLocation');
    this.hideSidebar();
  };

  getWindowDimensions = () => {
    return { windowWidth: window.innerWidth, windowHeight: window.innerHeight };
  };

  toggleIsCollapsed = (state) => {
    const { isCollapsed } = this.state;
    if (state === undefined) {
      this.setState({ isCollapsed: !isCollapsed });
    } else {
      this.setState({ isCollapsed: !!state });
    }
  };

  hideSidebar = () => {
    const { forceUpdatePage } = this.context;
    this.setState({
      isCollapsed: true,
    }, () => { forceUpdatePage(); });
  };

  useCityCoordinates = () => {
    const { viewport: { latitude, longitude }, onChangeMapSignature } = this.context;
    const coordinate = `${latitude.toFixed(2)}°N ${longitude.toFixed(2)}°E`;
    onChangeMapSignature('coordinate', coordinate);
  };

  getRandomBestsellerCities = () => {
    const randomNumbers = getRandomNumbers(1, bestsellersCities.length - 1, 4);
    return randomNumbers.map((item) => countrySpecificOptimization(bestsellersCities[item]));
  };

  changeTab = (indexNewTab) => {
    const { currentTab, isCollapsed, tabs } = this.state;
    const { changeTab } = this.context;
    const { windowWidth } = this.getWindowDimensions();
    if (isCollapsed && windowWidth <= TABLET_SIZE) {
      this.toggleIsCollapsed(false);
    }
    const currentButtonText = t(`${tabs[indexNewTab]}B2B.button_nextStep`);
    if (currentTab !== indexNewTab) {
      this.setState({
        currentTab: indexNewTab,
        currentButtonText
      }, () => changeTab(indexNewTab));
    }
  };

  nextStep = () => {
    const { currentTab, tabs } = this.state;
    const { changeTab } = this.context;
    const nextTab = currentTab + 1;
    const currentButtonText = t(`${tabs[nextTab]}B2B.button_nextStep`);
    if (nextTab >= tabs.length) {
      return;
    }
    this.setState({
      currentTab: nextTab,
      currentButtonText
    }, () => changeTab(nextTab));
  };

  getTitleText = () => {
    const { currentTab } = this.state;
    this.setState({
      currentTitleAndText: {
        title: titleLeftSidebar.title[currentTab],
        text: titleLeftSidebar.subTitle[currentTab],
        currentTab,
      }
    });
  };

  getMapOrientation = (index) => {
    return index === 0 ? 'portrait' : 'landscape';
  };

  changeMapOrientation = (indexOrientation) => {
    const { changeOrientation } = this.context;
    const newMapOrientation = this.getMapOrientation(indexOrientation);
    this.setState({
      currentMapOrientation: newMapOrientation
    }, () => { changeOrientation(newMapOrientation); this.hideSidebar(); });
  };

  changeMapStyle = (currentMapStyle) => {
    const { changeMapStyle } = this.context;
    this.setState({
      currentMapStyle
    }, () => { changeMapStyle(currentMapStyle); this.hideSidebar(); });
  };

  onChangeMapSignature = ({ target: { value } }, name) => {
    const { onChangeMapSignature } = this.context;
    if (value.length <= 25 && name === 'changeCityName') {
      onChangeMapSignature('city', this.regExpFontFamily(value, leagueGothic));
    }
    if (value.length <= 37 && name === 'ChangeCountryName') {
      onChangeMapSignature('country', this.regExpFontFamily(value, didotLTStd));
    }
    if (value.length <= 50 && name === 'changeCoordinate') {
      onChangeMapSignature('coordinate', this.regExpFontFamily(value, sourceSansPro));
    }
  };

  regExpFontFamily = (text, req) => {
    const result = text.match(req);
    return result && result.join('');
  };

  getRandomCity = () => {
    const city = RandomCity[Math.floor(Math.random() * RandomCity.length)];
    const { getRandomCity } = this.context;
    const cityOptimization = countrySpecificOptimization(city);
    getRandomCity(transformObjectCity(cityOptimization));
    this.hideSidebar();
  };

  getPdf = async () => {
    const {
      viewport: {
        mapStyle, latitude, longitude, zoom
      }, mapboxInfo: {
        orientation, city, coordinate, country
      }, scrollStep, isTouchScreen, mapStyleName
    } = this.context;
    const { inputSaveDescriptionValue, inputSaveNameValue } = this.state;
    const sessionId = generationId();
    const api = '/submit-map';
    const isWijck = mapStyleName === WIJCK;
    const body = {
      isTouchScreen,
      name: inputSaveNameValue || '',
      description: inputSaveDescriptionValue || '',
      scrollStep,
      type: 'b2b',
      zoom,
      map_style: isWijck ? mapboxStylesApi.wijckPrint : mapStyle,
      isWijckStyle: isWijck,
      style : mapStyleName,
      location: {
        longitude,
        latitude
      },
      orientation,
      map_signature: {
        coords: coordinate || '',
        city: city ? city.toUpperCase() : '',
        country: country || '',
      }
    };

    const response = await fetch(`${api}/${sessionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    return {
      response,
      sessionId
    };
  };

  makePDF = () => {
    this.getPdf()
      .then(({ response, sessionId }) => {
        if (response.status === 404 || response.status === 400) {
          // eslint-disable-next-line no-console
          console.log(`err - ${response.status}`);
        }
        if (response.status === 200 || response.status === 201) {
          const url = getRedirectURL();
          document.location = `${url}?id=${sessionId}`;
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  onChangeName = ({ target: { value } }) => {
    this.setState({
      inputSaveNameValue: value
    });
  };

  onChangeDescription = ({ target: { value } }) => {
    this.setState({
      inputSaveDescriptionValue: value
    });
  };

  searchCityByName = async () => {
    const { searchCityByName } = this.context;
    const result = await searchCityByName();
    if (result) {
      this.hideSidebar();
    }
  };

  getBestSellersPlace = (item) => {
    const { getRandomCity } = this.context;
    getRandomCity(transformObjectCity(item));
    this.hideSidebar();
  };

  handleClickOnDropdownInputSelect = (event) => {
    if (event.target.className === 'mapboxgl-ctrl-geocoder--suggestion-title'
        || event.target.className === 'mapboxgl-ctrl-geocoder--suggestion-address') {
      setTimeout(() => {
        this.hideSidebar();
      }, 500);
    }
  };

  // eslint-disable-next-line react/static-property-placement,react/sort-comp
  static contextType = MapboxInfoB2B;

  render() {
    const {
      currentTab, currentMapStyle, currentMapOrientation, currentButtonText, currentTitleAndText,
      bestsellerCities, closeSidebarVisibility, isCollapsed, inputSaveNameValue, inputSaveDescriptionValue,
      closeButtonPosition, app
    } = this.state;
    const { windowWidth } = this.getWindowDimensions();
    return (
      <LeftSidebarB2BView
        app={app}
        windowWidth={windowWidth}
        closeButtonPosition={closeButtonPosition}
        currentTitleAndText={currentTitleAndText}
        currentTab={currentTab}
        changeTab={this.changeTab}
        bestsellerCities={bestsellerCities}
        getRandomCity={this.getRandomCity}
        nextStep={this.nextStep}
        getUserLocation={this.getUserLocation}
        onChangeMapSignature={this.onChangeMapSignature}
        currentMapStyle={currentMapStyle}
        changeMapStyle={this.changeMapStyle}
        currentMapOrientation={currentMapOrientation}
        changeMapOrientation={this.changeMapOrientation}
        useCityCoordinates={this.useCityCoordinates}
        currentButtonText={currentButtonText}
        closeSidebarVisibility={closeSidebarVisibility}
        hideSidebar={this.hideSidebar}
        makePDF={this.makePDF}
        isCollapsed={isCollapsed}
        onChangeName={this.onChangeName}
        inputSaveNameValue={inputSaveNameValue}
        inputSaveDescriptionValue={inputSaveDescriptionValue}
        onChangeDescription={this.onChangeDescription}
        searchCityByName={this.searchCityByName}
        getBestSellersPlace={this.getBestSellersPlace}
      />
    );
  }
}

LeftSidebarB2B.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired
};

export default withRouter(LeftSidebarB2B);
