import { DAMSCO, WIJCK } from '../constants/general';
import { TABLET_SIZE } from '../constants/mapConstants';
import cityCorrection from '../constants/cityCorrection';
import getLanguage from './getLanguage';

// export const isWijck = (mapStyle) => mapStyle === WIJCK;
export const isDamsco = (mapStyle) => mapStyle === DAMSCO;

export const checkDisplayAndSizeScreen = () => 'ontouchstart' in window && window.innerWidth <= TABLET_SIZE;

export const convertCoordinate = (latitude, longitude) => (
  `${parseFloat(latitude.toFixed(2))}°N ${parseFloat(longitude.toFixed(2))}°E`
);

export const generationId = () => (
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
);

export const findDefaultParamsForCity = (cityName) => (
  cityCorrection.find((item) => item[getLanguage()].toLowerCase() === cityName.toLowerCase())
);

export const getRandomNumbers = (start, end, amountNumbers) => {
  const array = [];
  const newArr = [];
  for (let i = start; i <= end; i += 1) {
    array.push(i);
  }
  for (let countCycles = 1; countCycles <= amountNumbers; countCycles += 1) {
    newArr.push(array.splice(Math.random() * array.length, 1)[0]);
  }
  return newArr;
};

export const getRedirectURL = () => {
  if (document.location.hostname === 'onix-systems-wijcknode2.staging.onix.ua') {
    return 'https://stage.immense.nl/wijck/webhook/getcustom.php';
  }
  return 'https://wijck.com/custom';
};

export const transformObjectCity = (city) => {
  return {
    latitude: parseFloat(city.latitude),
    longitude: parseFloat(city.longitude),
    zoom: parseInt(city.zoom),
    scrollStep: parseInt(city.scrollStep),
    city: city.city,
    mobileZoom: city.mobileZoom
  };
};

export const isWijck = (style) => {
  return style === WIJCK;
};

export const isDesktopSize = () => window.innerWidth >= 992;
