const frameSize = {
  portrait: {
    gold: {
      B1: { height: 169, width: 71, padding: 17 },
      B2: { height: 170, width: 79, padding: 20 },
      X1: { height: 186, width: 94, padding: 27 },
      A3: { height: 178, width: 86, padding: 24 },
      A4: { height: 177, width: 89, padding: 24 }
    },
    black: {
      B1: { height: 184, width: 86, padding: 25 },
      B2: { height: 186, width: 99, padding: 30 },
      X1: { height: 200, width: 109, padding: 36 },
      A3: { height: 196, width: 107, padding: 36 },
      A4: { height: 198, width: 109, padding: 36 }
    },
    noFrame: {
      B1: { height: 188, width: 90, padding: 25 },
      B2: { height: 191, width: 100, padding: 30 },
      X1: { height: 204, width: 110, padding: 34 },
      A3: { height: 198, width: 111, padding: 35 },
      A4: { height: 202, width: 115, padding: 37 }
    }
  },
  landscape: {
    gold: {
      B1: { height: 124, width: 58, padding: 11 },
      B2: { height: 139, width: 69, padding: 15 },
      X1: { height: 151, width: 83, padding: 20 },
      A3: { height: 148, width: 79, padding: 20 },
      A4: { height: 143, width: 82, padding: 21 }
    },
    black: {
      B1: { height: 143.68, width: 78, padding: 21 },
      B2: { height: 156, width: 88, padding: 26 },
      X1: { height: 170, width: 99, padding: 31 },
      A3: { height: 164, width: 99, padding: 31 },
      A4: { height: 155, width: 99, padding: 31 }
    },
    noFrame: {
      B1: { height: 147, width: 80, padding: 22 },
      B2: { height: 162, width: 91, padding: 27 },
      X1: { height: 174, width: 102, padding: 31 },
      A3: { height: 170, width: 100, padding: 31 },
      A4: { height: 162, width: 100, padding: 30 }
    }
  }
};
export default frameSize;
