import { LOCALES } from '../locales';

export default {
  [LOCALES.ENGLISH]: {
    'Location.title': 'Choose your location',
    'Location.subTitle': 'Search for a city, zoom in and out, or pane directly in the map to '
      + 'locate your favourite place.',
    'Location.search_input': 'Find your favourite place',
    'Location.label_randomCity': 'Spin the wheel!',
    'Location.button_randomCity': 'Choose a random city',
    'Location.label_bestsellers': 'What about these bestsellers?',
    'Location.button_nextStep': 'Next step: Customise',


    'Customise.title': 'Customise your print',
    'Customise.subTitle': 'Customise your map! Type your own text and determine the orientation of your print.',
    'Customise.label_orientation': 'Orientation',
    'Customise.input_portrait': 'Portrait',
    'Customise.input_landscape': 'Landscape',
    'Customise.label_style': 'Style',
    'Customise.label_text': 'Text',
    'Customise.button_useCoordinatesText': 'Use city coordinates for tagline',
    'Customise.button_nextStep': 'Next step: Size & Extras',


    'SizeExtras.title': 'Size & Extras',
    'SizeExtras.subTitle': 'Choose a size and make it complete with one of our beautiful frames!',
    'SizeExtras.label_size': 'Size',
    'SizeExtras.input_small': 'Small',
    'SizeExtras.input_medium': 'Medium',
    'SizeExtras.input_large': 'Large',
    'SizeExtras.input_extraLarge': 'Extra Large',
    'SizeExtras.label_frame': 'Frame',
    'SizeExtras.input_noFrame': 'No frame',
    'SizeExtras.input_black': 'Black - wooden frame',
    'SizeExtras.input_gold': 'Gold - aluminium frame',
    'SizeExtras.button_nextStep': 'Next step: Result & Price',


    'AddToCart.title': 'Result & Price',
    'AddToCart.subTitle': 'Here is your final result and price. To order click “add to cart”.',
    'AddToCart.size': 'Size',
    'AddToCart.frame': 'Frame',
    'AddToCart.price': 'Price',
    'AddToCart.customise': 'Customise map',
    'AddToCart.button_nextStep': 'Add to Cart - {price}',
    'AddToCart.button_nextStep1': 'Add to Cart',

    'Save.title': 'Save your print',
    'Save.subTitle': 'Your customization is almost ready! Below you can fill in your name and description.'
      + ' Click the button to save your print.',
    'Save.details': 'Details',
    'Save.customise': 'Customization',
    'Save.name': 'Name',
    'Save.description': 'Description',
    'Save.portrait': 'Portrait',
    'Save.landscape': 'Landscape',

    'LocationB2B.button_nextStep': 'Next step: Customise',
    'CustomiseB2B.button_nextStep': 'Next step: Save print',
    'SaveB2B.button_nextStep': 'Save now',


    'Menu.location': 'Location',
    'Menu.customise': 'Customise',
    'Menu.sizeExtras': 'Size & Extras',
    'Menu.addToCart': 'Add to cart',
    'Menu.save': 'Save',
  }
};
