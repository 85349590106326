import React, { Component } from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Page from './layout/Page/Page';
import HomePage from './pages/HomePage/HomePage';
import HomePageB2b from './pages/HomePageB2B/HomePageB2B';
import './scss/index.scss';
import { I18nProvider, LOCALES } from './i18n';

class App extends Component {
  constructor(props) {
    super(props);
    this.history = createBrowserHistory();
  }

  getSessionLanguage = () => {
    const path = window.location.pathname.split('/');
    return path.length > 0 ? path[1] : 'en';
  };

  getLanguage = () => {
    const lang = this.getSessionLanguage();
    switch (lang) {
      case 'en':
        return 'ENGLISH';
      case 'fr':
        return 'FRENCH';
      case 'de':
        return 'GERMAN';
      case 'nl':
        return 'NETHERLANDS';
      default:
        return 'ENGLISH';
    }
  };

  render() {
    const language = this.getLanguage();
    return (
      <I18nProvider locale={LOCALES[language]}>
        <Router history={this.history}>
          <Page>
            <Switch>
              <Route path="/:language/b2b" component={HomePageB2b} />
              <Route path="/:language" component={HomePage} />
              <Route path="/:language/:city" component={HomePage} />
              <Route path="/" component={HomePage} />
            </Switch>
          </Page>
        </Router>
      </I18nProvider>
    );
  }
}

export default App;
