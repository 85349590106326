import React from 'react';
import PropTypes from 'prop-types';
import './MapSignature.scss';

const MapSignature = ({
  orientation, coordinate, city, country, size
}) => {
  return (
    <div className={`mapSignature mapSignature__${orientation} mapSignature-${orientation}-${size}`}>
      <span className={`mapSignature__${orientation}__coordinate mapSignature__textContainer`}>
        <svg height="11" width="500">
          <text className="countryName" textAnchor="middle" x="250" y="8">{coordinate}</text>
        </svg>
      </span>
      <span className={`mapSignature__${orientation}__cityName mapSignature__textContainer`}>
        {city && city.toUpperCase()}
      </span>
      <span className={`mapSignature__${orientation}__countryName mapSignature__textContainer`}>
        <svg height="14" width="500">
          <text className="countryName" textAnchor="middle" x="250" y="11">{country}</text>
        </svg>
      </span>
      <div className="trademarkWrap  mapSignature__textContainer">
        <span className={`mapSignature__${orientation}__trademark`} />
      </div>
    </div>
  );
};

MapSignature.propTypes = {
  coordinate: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  orientation: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired
};

MapSignature.defaultProps = {
  city: '',
  coordinate: '',
  country: '',
};

export default MapSignature;
