import { LOCALES } from '../locales';

export default {
  [LOCALES.NETHERLANDS]: {
    'Location.title': 'Bepaal jouw locatie',
    'Location.subTitle': 'Zoek jouw favoriete stad of dorp, zoom in en uit, of scroll op de kaart om jouw favoriete '
      + 'locatie te vinden.',
    'Location.search_input': 'Vind jouw favoriete locatie',
    'Location.label_randomCity': 'Laat je verrassen!',
    'Location.button_randomCity': 'Kies een willekeurige stad',
    'Location.label_bestsellers': 'Bekijk de bestsellers!',
    'Location.button_nextStep': 'Volgende: Personaliseer',


    'Customise.title': 'Personaliseer jouw print',
    'Customise.subTitle': 'Maak jouw kaart extra persoonlijk! Bepaal jouw eigen tekst of bericht in de kaart en maak '
      + 'de kaart staand of liggend.',
    'Customise.label_orientation': 'Oriëntatie',
    'Customise.input_portrait': 'Staand',
    'Customise.input_landscape': 'Liggend',
    'Customise.label_style': 'Stijl',
    'Customise.label_text': 'Tekst',
    'Customise.button_useCoordinatesText': 'Gebruik stadscoördinaten voor de titel',
    'Customise.button_nextStep': 'Volgende: Formaat & Extra’s',


    'SizeExtras.title': 'Formaat & Extra’s',
    'SizeExtras.subTitle': 'Kies jouw gewenste formaat en maak de kaart compleet met een van onze prachtige lijsten!',
    'SizeExtras.label_size': 'Formaat',
    'SizeExtras.input_small': 'Small',
    'SizeExtras.input_medium': 'Medium',
    'SizeExtras.input_large': 'Large',
    'SizeExtras.input_extraLarge': 'Extra Large',
    'SizeExtras.label_frame': 'Lijst',
    'SizeExtras.input_noFrame': 'Geen lijst',
    'SizeExtras.input_black': 'Zwart - Houten lijst',
    'SizeExtras.input_gold': 'Goud - Aluminium lijst',
    'SizeExtras.button_nextStep': 'Volgende: Resultaat & Prijs',


    'AddToCart.title': 'Resultaat & Prijs',
    'AddToCart.subTitle': 'Bij deze jouw eindresultaat en prijs. Om jouw kaart te bestellen druk je op de bestelknop',
    'AddToCart.size': 'Formaat',
    'AddToCart.frame': 'Lijst',
    'AddToCart.price': 'Prijs',
    'AddToCart.customise': 'Wijzig het design',
    'AddToCart.button_nextStep': 'Bestellen - {price}',
    'AddToCart.button_nextStep1': 'Bestellen',

    'Save.title': 'Bewaar jouw print',
    'Save.subTitle': 'Jouw gepersonaliseerde print is bijna klaar! Vul jouw naam en omschrijving in, druk op de '
      + 'save knop en jouw prints staan klaar.',
    'Save.details': 'Details',
    'Save.customise': 'Personalisering',
    'Save.name': 'Naam',
    'Save.description': 'Omschrijving',
    'Save.portrait': 'Staand',
    'Save.landscape': 'Liggend',


    'LocationB2B.button_nextStep': 'Volgende: Personaliseer',
    'CustomiseB2B.button_nextStep': 'Volgende: Save',
    'SaveB2B.button_nextStep': 'Save',


    'Menu.location': 'Locatie',
    'Menu.customise': 'Personaliseer',
    'Menu.sizeExtras': 'Formaat & Extra\'s',
    'Menu.addToCart': 'Winkelwagen',
    'Menu.save': 'Save',
  }
};
