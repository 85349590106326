export default [
  {
    city: 'Abu Dhabi',
    latitude: '24.47',
    longitude: '54.37',
    total: '24.47°N 54.37°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571

  },
  {
    city: 'Amsterdam',
    latitude: '52.38',
    longitude: '4.90',
    total: '52.38°N 4.90°E',
    zoom: 11,
    scrollStep: 2,
    mobileZoom: 11.714

  },
  {
    city: 'Athens',
    latitude: '37.99',
    longitude: '23.72',
    total: '37.99°N 23.72°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Auckland',
    latitude: '-36.87',
    longitude: '174.82',
    total: '-36.87°N 174.82°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11,
  },
  {
    city: 'Austin',
    latitude: '30.30',
    longitude: '-97.74',
    total: '30.30°N -97.74°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Baku',
    latitude: '40.39',
    longitude: '49.85',
    total: '40.39°N 49.85°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Bangkok',
    latitude: '13.75',
    longitude: '100.52',
    total: '13.75°N 100.52°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Barcelona',
    latitude: '41.39',
    longitude: '2.15',
    total: '41.39°N 2.15°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Beijing',
    latitude: '39.92',
    longitude: '116.38',
    total: '39.92°N 116.38°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Belgrade',
    latitude: '44.82',
    longitude: '20.46',
    total: '44.82°N 20.46°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Berlin',
    latitude: '52.52',
    longitude: '13.40',
    total: '52.52°N 13.40°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Bogotá',
    latitude: '4.62',
    longitude: '-74.10',
    total: '4.62°N -74.10°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Brussels',
    latitude: '50.85',
    longitude: '4.36',
    total: '50.85°N 4.36°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Bucharest',
    latitude: '44.38',
    longitude: '26.10',
    total: '44.43°N 26.10°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Budapest',
    latitude: '47.50',
    longitude: '19.06',
    total: '47.50°N 19.06°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Cairo',
    latitude: '30.05',
    longitude: '31.22',
    total: '30.05°N 31.22°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Calgary',
    latitude: '51.04',
    longitude: '-114.06',
    total: '51.04°N -114.06°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Cape Town',
    latitude: '-34.00',
    longitude: '18.43',
    total: '-34.00°N 18.43°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286,
  },
  {
    city: 'Casablanca',
    latitude: '33.57',
    longitude: '-7.62',
    total: '33.57°N -7.62°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Charlotte',
    latitude: '35.24',
    longitude: '-80.84',
    total: '35.24°N -80.84°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    city: 'Kyoto',
    latitude: '35.01',
    longitude: '135.77',
    total: '35.01°N 135.77°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Chicago',
    latitude: '41.88',
    longitude: '-87.64',
    total: '41.88°N -87.64°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Copenhagen',
    latitude: '55.68',
    longitude: '12.59',
    total: '55.68°N 12.59°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Dallas',
    latitude: '32.79',
    longitude: '-96.80',
    total: '32.79°N -96.80°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Delhi',
    latitude: '28.63',
    longitude: '77.22',
    total: '28.63°N 77.22°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Dubai',
    latitude: '25.20',
    longitude: '55.24',
    total: '25.20°N 55.24°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Dublin',
    latitude: '53.34',
    longitude: '-6.28',
    total: '53.34°N -6.28°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Edinburgh',
    latitude: '55.95',
    longitude: '-3.20',
    total: '55.95°N -3.20°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'El Paso',
    latitude: '31.75',
    longitude: '-106.42',
    total: '31.75°N -106.42°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Frankfurt',
    latitude: '50.11',
    longitude: '8.67',
    total: '50.11°N 8.67°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    city: 'Florence',
    latitude: '43.77',
    longitude: '11.26',
    total: '43.77°N 11.26°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Granada',
    latitude: '37.18',
    longitude: '-3.60',
    total: '37.18°N -3.60°E',
    zoom: '13',
    scrollStep: '3',
    mobileZoom: 13.571
  },
  {
    city: 'Guatemala City',
    latitude: '14.62',
    longitude: '-90.52',
    total: '14.62°N -90.52°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Hamburg',
    latitude: '53.55',
    longitude: '10.01',
    total: '53.55°N 10.01°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Havana',
    latitude: '23.11',
    longitude: '-82.40',
    total: '23.11°N -82.40°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Helsinki',
    latitude: '60.16',
    longitude: '24.95',
    total: '60.16°N 24.95°E',
    zoom: '12',
    scrollStep: '2',
    mobileZoom: 12.714
  },
  {
    city: 'Hong Kong',
    latitude: '22.30',
    longitude: '114.18',
    total: '22.30°N 114.18°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Honolulu',
    latitude: '21.34',
    longitude: '-157.86',
    total: '21.34°N -157.86°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Houston',
    latitude: '29.75',
    longitude: '-95.37',
    total: '29.75°N -95.37°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Istanbul',
    latitude: '41.05',
    longitude: '29.01',
    total: '41.05°N 29.01°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Jakarta',
    latitude: '-6.17',
    longitude: '106.80',
    total: '-6.17°N 106.80°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Johannesburg',
    latitude: '-6.19',
    longitude: '106.81',
    total: '-6.19°N 106.81°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Kingston',
    latitude: '18.02',
    longitude: '-76.80',
    total: '18.02°N -76.80°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Kuala Lumpur',
    latitude: '3.13',
    longitude: '101.71',
    total: '3.13°N 101.71°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Kyiv',
    latitude: '50.45',
    longitude: '30.52',
    total: '50.45°N 30.52°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'La Paz',
    latitude: '-16.49',
    longitude: '-68.14',
    total: '-16.49°N -68.14°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Las Vegas',
    latitude: '36.16',
    longitude: '-115.15',
    total: '36.16°N -115.15°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Lisbon',
    latitude: '38.72',
    longitude: '-9.15',
    total: '38.72°N -9.15°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    city: 'Liverpool',
    latitude: '53.42',
    longitude: '-3',
    total: '53.42°N -3.00°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'London',
    latitude: '51.51',
    longitude: '-0.11',
    total: '51.51°N -0.11°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Los Angeles',
    latitude: '34.05',
    longitude: '-118.24',
    total: '34.05°N -118.24°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Madrid',
    latitude: '40.43',
    longitude: '-3.69',
    total: '40.43°N -3.69°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Málaga',
    latitude: '36.72',
    longitude: '-4.42',
    total: '36.72°N -4.42°E',
    zoom: '12',
    scrollStep: '3',
    mobileZoom: 12.571
  },
  {
    city: 'Manchester',
    latitude: '53.48',
    longitude: '-2.24',
    total: '53.48°N -2.24°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    city: 'Mecca',
    latitude: '21.41',
    longitude: '39.82',
    total: '21.41°N 39.82°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Melbourne',
    latitude: '-37.81',
    longitude: '144.95',
    total: '-37.81°N 144.95°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Mexico City',
    latitude: '19.42',
    longitude: '-99.13',
    total: '19.42°N -99.13°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Miami',
    latitude: '25.79',
    longitude: '-80.19',
    total: '25.79°N -80.19°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    city: 'Milan',
    latitude: '45.47',
    longitude: '9.18',
    total: '45.47°N 9.18°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    city: 'Minsk',
    latitude: '53.91',
    longitude: '27.56',
    total: '53.91°N 27.56°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Monaco',
    latitude: '43.73',
    longitude: '7.42',
    total: '43.73°N 7.42°E',
    zoom: '14',
    scrollStep: '5',
    mobileZoom: 14.285714286
  },
  {
    city: 'Montreal',
    latitude: '45.50',
    longitude: '-73.58',
    total: '45.50°N -73.58°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Moscow',
    latitude: '55.75',
    longitude: '37.62',
    total: '55.75°N 37.62°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Mumbai',
    latitude: '19.02',
    longitude: '72.85',
    total: '19.02°N 72.85°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286,
  },
  {
    city: 'Munich',
    latitude: '48.14',
    longitude: '11.58',
    total: '48.14°N 11.58°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Nashville',
    latitude: '36.17',
    longitude: '-86.78',
    total: '36.17°N -86.78°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'New Orleans',
    latitude: '29.98',
    longitude: '-90.08',
    total: '29.98°N -90.08°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'New York',
    latitude: '40.77',
    longitude: '-73.98',
    total: '40.77°N -73.98°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Osaka',
    latitude: '34.69',
    longitude: '135.50',
    total: '34.69°N 135.50°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Oslo',
    latitude: '59.92',
    longitude: '10.75',
    total: '59.92°N 10.75°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Panama City',
    latitude: '9.02',
    longitude: '-79.51',
    total: '9.02°N -79.51°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    city: 'Paramaribo',
    latitude: '5.85',
    longitude: '-55.15',
    total: '5.85°N -55.15°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Paris',
    latitude: '48.86',
    longitude: '2.34',
    total: '48.86°N 2.34°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Perth',
    latitude: '-32.00',
    longitude: '115.82',
    total: '-32.00°N 115.82°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Philadelphia',
    latitude: '39.96',
    longitude: '-75.13',
    total: '39.96°N -75.13°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Phoenix',
    latitude: '33.46',
    longitude: '-112.05',
    total: '33.46°N -112.05°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142

  },
  {
    city: 'Portland',
    latitude: '45.53',
    longitude: '-122.67',
    total: '45.53°N -122.67°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Porto',
    latitude: '41.15',
    longitude: '-8.62',
    total: '41.15°N -8.62°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Prague',
    latitude: '50.08',
    longitude: '14.44',
    total: '50.08°N 14.44°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428,
  },
  {
    city: 'Quito',
    latitude: '-0.22',
    longitude: '-78.50',
    total: '-0.22°N -78.50°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Reykjavik',
    latitude: '64.13',
    longitude: '-21.87',
    total: '64.13°N -21.87°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Riga',
    latitude: '56.94',
    longitude: '24.10',
    total: '56.94°N 24.10°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'Rio de Janeiro',
    latitude: '-22.93',
    longitude: '-43.20',
    total: '-22.93°N -43.20°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Rome',
    latitude: '41.90',
    longitude: '12.50',
    total: '41.90°N 12.50°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Saint Petersburg',
    latitude: '59.94',
    longitude: '30.31',
    total: '59.94°N 30.31°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Salvador',
    latitude: '-12.95',
    longitude: '-38.47',
    total: '-12.95°N -38.47°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'San Diego',
    latitude: '32.79',
    longitude: '-117.15',
    total: '32.79°N -117.15°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'San Francisco',
    latitude: '37.77',
    longitude: '-122.44',
    total: '37.77°N -122.44°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    city: 'San José',
    latitude: '9.93',
    longitude: '-84.09',
    total: '9.93°N -84.09°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428,
  },
  {
    city: 'Santiago',
    latitude: '-33.46',
    longitude: '-70.67',
    total: '-33.46°N -70.67°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'São Paulo',
    latitude: '-23.55',
    longitude: '-46.64',
    total: '-23.55°N -46.64°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Seattle',
    latitude: '47.60',
    longitude: '-122.33',
    total: '47.60°N -122.33°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Seoul',
    latitude: '37.57',
    longitude: '126.98',
    total: '37.57°N 126.98°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Seville',
    latitude: '37.39',
    longitude: '-5.98',
    total: '37.39°N -5.98°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Shanghai',
    latitude: '31.23',
    longitude: '121.49',
    total: '31.23°N 121.49°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Singapore',
    latitude: '1.36',
    longitude: '103.83',
    total: '1.36°N 103.83°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    city: 'Sofia',
    latitude: '42.69',
    longitude: '23.82',
    total: '42.69°N 23.32°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Stockholm',
    latitude: '59.33',
    longitude: '18.07',
    total: '59.33°N 18.07°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Sydney',
    latitude: '-33.86',
    longitude: '151.17',
    total: '-33.86°N 151.17°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    city: 'Taipei',
    latitude: '25.07',
    longitude: '121.51',
    total: '25.07°N 121.51°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Tokyo',
    latitude: '35.68',
    longitude: '139.80',
    total: '35.68°N 139.80°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12,
  },
  {
    city: 'Toronto',
    latitude: '43.69',
    longitude: '-79.37',
    total: '43.69°N -79.37°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Valencia',
    latitude: '39.47',
    longitude: '-0.38',
    total: '39.47°N -0.38°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Vancouver',
    latitude: '49.27',
    longitude: '-123.10',
    total: '49.27°N -123.10°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    city: 'Vienna',
    latitude: '48.22',
    longitude: '16.37',
    total: '48.22°N 16.37°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Vilnus',
    latitude: '54.68',
    longitude: '25.27',
    total: '54.68°N 25.27°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    city: 'Warsaw',
    latitude: '52.23',
    longitude: '21.03',
    total: '52.23°N 21.03°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428,
  },
  {
    city: 'Washington',
    latitude: '38.90',
    longitude: '-77.02',
    total: '38.90°N -77.02°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    city: 'Zagreb',
    latitude: '15.96',
    longitude: '48.81',
    total: '45.81°N 15.96°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    city: 'Zurich',
    latitude: '47.39',
    longitude: '8.53',
    total: '47.39°N 8.53°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    city: 'Buenos Aires',
    latitude: '-34.6',
    longitude: '-58.44',
    total: '-58.44°E -34.61°N ',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  }
];
