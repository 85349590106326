const getLanguage = () => {
  const path = window.location.pathname.split('/');
  const value = path.length > 0 ? path[1] : 'en';
  switch (value) {
    case 'nl':
      return 'nl';
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'en':
      return 'en';
    default:
      return 'en';
  }
};

export default getLanguage;
