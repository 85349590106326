import React from 'react';
import PropTypes from 'prop-types';

const LeftSidebarTitle = (
  { currentTitleAndText: { title, text } }
) => {
  return (
    <div className="left-sidebar__title-block">
      <p className="left-sidebar__title-block-title">{title}</p>
      <p className="left-sidebar__title-block-text">{text}</p>
    </div>
  );
};

LeftSidebarTitle.propTypes = {
  currentTitleAndText: PropTypes.shape({ title: PropTypes.object, text: PropTypes.object }).isRequired,
};

export default LeftSidebarTitle;
