import PropTypes from 'prop-types';

const PageView = ({ children }) => {
  return (children);
};

PageView.propTypes = {
  children: PropTypes.node.isRequired
};

export default PageView;
