import React from 'react';
import PropTypes from 'prop-types';
import './logoUnderMap.scss';

const LogoUnderMap = ({ size, orientation }) => {
  return <div className={`logoUnderMap logoUnderMap-${orientation}-${size}`} />;
};

LogoUnderMap.propTypes = {
  size: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
};

export default LogoUnderMap;
