import React from 'react';
import PropTypes from 'prop-types';
import './mobileOverlay.scss';

const MobileOverlay = ({
  onTurnOffMobileOverlay, isTouchScreen, isVisibleMobileOverlay, isWijckStyle
}) => {
  return (
    (isTouchScreen && isVisibleMobileOverlay) ? (
      <div
        className={`mobile-overlay
         ${isWijckStyle ? 'mobile-overlay__wijck-height' : 'mobile-overlay__default-height'}
        `}
        onClick={onTurnOffMobileOverlay}
        role="button"
        tabIndex="0"
        onKeyPress={() => {}}
      >
        <div className="glass" />
        <div className="info">
          <p>create your own!</p>
          <p>Zoom in and out, or move the map</p>
          <p>to locate your favorite place.</p>
          <div className="image" />
        </div>
      </div>
    ) : null
  );
};

MobileOverlay.propTypes = {
  isTouchScreen: PropTypes.bool.isRequired,
  isWijckStyle: PropTypes.bool.isRequired,
  isVisibleMobileOverlay: PropTypes.bool.isRequired,
  onTurnOffMobileOverlay: PropTypes.func.isRequired,
};

export default MobileOverlay;
