import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ZoomButtons from '../../../components/ZoomButtons/ZoomButtons';
import MapboxInfoB2B from '../../../context/MapboxContextB2B';
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';
import MapSignature from '../../../components/MapSignature/MapSignature';
import MapSignatureOnMap from '../../../components/MapSignatureOnMap/MapSignatureOnMap';
import LogoAboveMap from '../../../components/LogoAboveMap/LogoAboveMap';
import MapBox from '../../../components/MapBox/MapBox';
import { WIJCK, borderSize } from '../../../constants/general';
import LogoUnderMap from '../../../components/LogoUnderMap/LogoUnderMap';

const HomePageMapBox = ({
  mapWheel, scaleAndIndentation, zoomChange, mapBlockRef, zoomButtonsPosition, frameSize,
  defaultMapSize: { defaultWidth, defaultHeight }, handleViewportChange, handleGeocoderViewportChange, inputSearchValue,
  filterMapSearch, handleOnResult, searchVisibility, mapHeight, mapWidth,
  isTouchScreen, maxMapHeight, maxMapWidth, mapStyleName
}) => {
  const {
    mapboxInfo: {
      city, coordinate, country, orientation, frame, size,
    }, geoCoderRef, mapRef, viewport,
  } = useContext(MapboxInfoB2B);
  const width = isTouchScreen ? maxMapWidth : mapWidth;
  const height = isTouchScreen ? maxMapHeight : mapHeight;
  const isWijckStyle = mapStyleName === WIJCK;
  return (
    <main className="mainContainer">
      <LogoAboveMap />
      <div className="mapBlock">
        <ZoomButtons zoomChange={zoomChange} zoomButtonsPosition={zoomButtonsPosition} />
        <div
          ref={mapBlockRef}
          className={`mapAndText mapAndText__${frame}`}
          style={{
            width: isWijckStyle ? 'auto' : defaultWidth + frameSize.width,
            padding: frameSize.padding,
            transform: `scale(${scaleAndIndentation.scale})`,
            WebkitTransform: `scale(${scaleAndIndentation.scale})`,
            marginTop: scaleAndIndentation.marginTop
          }}
        >
          <div className={`shadowWrapper shadowWrapper_${orientation}`} />
          <div
            className={`${isWijckStyle ? 'innerBorder' : ''}`}
            style={{
              width: isWijckStyle ? defaultWidth + borderSize[orientation][size].width : 'auto',
              height: isWijckStyle ? defaultHeight + borderSize[orientation][size].height : 'auto',
              left: isWijckStyle ? frameSize.padding + borderSize[orientation][size].left : 0,
              top: isWijckStyle ? frameSize.padding + borderSize[orientation][size].top : 0,
            }}
          />
          <div
            className={`${isWijckStyle ? 'outerBorder' : ''}`}
          >
            <div
              className="mapWrapperOverFlowHidden"
              style={{
                height: isWijckStyle ? defaultHeight : defaultHeight, width: defaultWidth
              }}
            >
              <MapBox
                width={width}
                height={height}
                handleViewportChange={handleViewportChange}
                searchVisibility={searchVisibility}
                inputSearchValue={inputSearchValue}
                handleOnResult={handleOnResult}
                filterMapSearch={filterMapSearch}
                handleGeocoderViewportChange={handleGeocoderViewportChange}
                mapWheel={mapWheel}
                geoCoderRef={geoCoderRef}
                mapRef={mapRef}
                viewport={viewport}
                orientation={orientation}
                size={size}
                mapStyleName={mapStyleName}
              />
            </div>
            {isWijckStyle && (
              <MapSignatureOnMap
                orientation={orientation}
                coordinate={coordinate}
                country={country}
                city={city}
                size={size}
                margin={borderSize[orientation][size].margin}
              />
            )}
            {isWijckStyle && <LogoUnderMap size={size} orientation={orientation} />}
          </div>
          {!isWijckStyle && (
            <MapSignature
              orientation={orientation}
              coordinate={coordinate}
              country={country}
              city={city}
              size={size}
            />
          )}
        </div>
      </div>
    </main>
  );
};

HomePageMapBox.propTypes = {
  searchVisibility: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  maxMapHeight: PropTypes.number.isRequired,
  maxMapWidth: PropTypes.number.isRequired,
  mapHeight: PropTypes.number.isRequired,
  mapWidth: PropTypes.number.isRequired,
  inputSearchValue: PropTypes.string.isRequired,
  mapStyleName: PropTypes.string.isRequired,
  zoomButtonsPosition: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
  }).isRequired,
  defaultMapSize: PropTypes.shape({
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number
  }).isRequired,
  scaleAndIndentation: PropTypes.shape({
    scale: PropTypes.number,
    marginTop: PropTypes.number,
  }).isRequired,
  frameSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    padding: PropTypes.number,
  }).isRequired,
  mapBlockRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  zoomChange: PropTypes.func.isRequired,
  mapWheel: PropTypes.func.isRequired,
  handleViewportChange: PropTypes.func.isRequired,
  handleGeocoderViewportChange: PropTypes.func.isRequired,
  filterMapSearch: PropTypes.func.isRequired,
  handleOnResult: PropTypes.func.isRequired,
};
export default HomePageMapBox;
