export default {
  B1: {
    defaultHeight: 555,
    defaultWidth: 442.59,
    maxMapHeight: 1054.9,
    maxMapWidth: 841.25,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 16.60
  },
  B2: {
    defaultHeight: 555,
    defaultWidth: 444,
    maxMapHeight: 1054.9,
    maxMapWidth: 843.85,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 16.66
  },
  X1: {
    defaultHeight: 555,
    defaultWidth: 504.55,
    maxMapHeight: 1054.9,
    maxMapWidth: 958.88,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 18.93,
  },
  A3: {
    defaultHeight: 555,
    defaultWidth: 465.48,
    maxMapHeight: 1054.9,
    maxMapWidth: 884.76,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 17.47
  },
  A4: {
    defaultHeight: 555,
    defaultWidth: 434.35,
    maxMapHeight: 1054.9,
    maxMapWidth: 825.56,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 16.30
  },
  postcard: {
    defaultHeight: 555,
    defaultWidth: 441.03,
    maxMapHeight: 1054.9,
    maxMapWidth: 802.67,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 16.55
  },
  sticker: {
    defaultHeight: 555,
    defaultWidth: 430.13,
    maxMapHeight: 1054.9,
    maxMapWidth: 782.83,
    mapScrollSpeedHeightPx: 20.825,
    mapScrollSpeedWidthPx: 16.14
  },

};
