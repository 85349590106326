/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import MapGL from 'react-map-gl';
import DeckGL from 'deck.gl';
import Geocode from 'react-map-gl-geocoder';
import { MAPBOX_TOKEN } from '../../constants/mapboxAPI';
import getLanguage from '../../utils/getLanguage';
import './mapBox.scss';

const MapBox = ({
  height,
  width,
  handleViewportChange,
  searchVisibility,
  inputSearchValue,
  handleOnResult,
  filterMapSearch,
  handleGeocoderViewportChange,
  mapWheel,
  geoCoderRef,
  mapRef,
  orientation,
  size,
  viewport,
  mapStyleName
}) => {
  return (
    <div
      className={`
      map 
      map-${orientation} 
      map-${orientation}-${size} 
      map-${orientation}-${size}-${mapStyleName}
      `}
      id="map"
      onWheel={(e) => mapWheel(e)}
    >
      <MapGL
        {...viewport}
        ref={mapRef}
        maxZoom={14}
        minZoom={6}
        width={`${width}px`}
        height={`${height}px`}
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        transitionDuration={0}
        doubleClickZoom={false}
        dragRotate={false}
        scrollZoom={false}
      >
        <DeckGL {...viewport} />
      </MapGL>
      {searchVisibility
      && (
        <Geocode
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapRef={mapRef}
          zoom={12}
          containerRef={geoCoderRef}
          inputValue={inputSearchValue}
          onResult={handleOnResult}
          filter={filterMapSearch}
          language={getLanguage()}
          onViewportChange={handleGeocoderViewportChange}
        />
      )}
    </div>
  );
};

MapBox.propTypes = {
  searchVisibility: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  inputSearchValue: PropTypes.string.isRequired,
  mapStyleName: PropTypes.string.isRequired,
  orientation: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  viewport: PropTypes.shape({}).isRequired,
  mapRef: PropTypes.shape({}).isRequired,
  geoCoderRef: PropTypes.shape({}).isRequired,
  handleViewportChange: PropTypes.func.isRequired,
  handleOnResult: PropTypes.func.isRequired,
  filterMapSearch: PropTypes.func.isRequired,
  handleGeocoderViewportChange: PropTypes.func.isRequired,
  mapWheel: PropTypes.func.isRequired,
};

export default MapBox;
