import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../../components/Button/Button';
import LeftSidebarTitle from '../../components/LeftSidebarTitle/LeftSidebarTitle';
import LeftSidebarCloseSidebar from '../../components/CloseSidebar/CloseSidebar';
import LeftSidebarLocation from './components/LeftSidabarB2B.Location';
import LeftSidebarCustomize from './components/LeftSidebarB2B.Customize';
import LeftSidebarSave from './components/LeftSidebarB2B.Save';
import { TABLET_SIZE } from '../../constants/mapConstants';
import MapboxInfoB2B from '../../context/MapboxContextB2B';
import LeftSidebarMenu from '../../components/LeftSidebarMenu/LeftSidebarMenu';

const LeftSidebarB2BView = ({
  currentTitleAndText, getRandomCity, bestsellerCities, getUserLocation, onChangeMapSignature,
  currentTab, changeTab, currentMapStyle, changeMapStyle, currentMapOrientation,
  changeMapOrientation, useCityCoordinates, nextStep, currentButtonText, hideSidebar, makePDF, windowWidth,
  isCollapsed, closeButtonPosition, onChangeName, onChangeDescription, inputSaveDescriptionValue, inputSaveNameValue,
  searchCityByName, getBestSellersPlace, app
}) => {
  const { leftSideBarRef } = useContext(MapboxInfoB2B);
  return (
    <div
      className={cx({
        'left-sidebar': true,
        'left-sidebar-height-auto': isCollapsed,
      })}
      ref={leftSideBarRef}
    >
      <LeftSidebarCloseSidebar
        hideSidebar={hideSidebar}
        isCollapsed={isCollapsed}
        closeButtonPosition={closeButtonPosition}
      />
      <div
        className={cx({
          container: true,
          'left-sidebar__content': true,
          'left-sidebar__content--hide-mobile': isCollapsed,
        })}
      >
        <LeftSidebarTitle
          currentTitleAndText={currentTitleAndText}
        />
        {currentTab === 0
        && (
          <LeftSidebarLocation
            getRandomCity={getRandomCity}
            bestsellerCities={bestsellerCities}
            getUserLocation={getUserLocation}
            searchCityByName={searchCityByName}
            getBestSellersPlace={getBestSellersPlace}
          />
        )}
        {currentTab === 1
        && (
          <LeftSidebarCustomize
            onChangeMapSignature={onChangeMapSignature}
            currentMapStyle={currentMapStyle}
            changeMapStyle={changeMapStyle}
            currentMapOrientation={currentMapOrientation}
            changeMapOrientation={changeMapOrientation}
            useCityCoordinates={useCityCoordinates}
          />
        )}
        {currentTab === 2
        && (
          <LeftSidebarSave
            onChangeName={onChangeName}
            onChangeDescription={onChangeDescription}
            inputSaveDescriptionValue={inputSaveDescriptionValue}
            inputSaveNameValue={inputSaveNameValue}
          />
        )}
        {windowWidth >= TABLET_SIZE ? (
          <Button
            text={currentButtonText}
            className="button-next-step"
            onClick={() => (currentTab === 2 ? makePDF() : nextStep())}
            type="button"
          />
        ) : currentTab === 2 && (
          <Button
            text={currentButtonText}
            className="button-next-step"
            onClick={makePDF}
            type="button"
          />
        )}
      </div>

      <LeftSidebarMenu
        app={app}
        currentTab={currentTab}
        changeTab={changeTab}
        isCollapsed={isCollapsed}
        windowWidth={windowWidth}
      />

    </div>
  );
};

LeftSidebarB2BView.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  closeButtonPosition: PropTypes.number.isRequired,
  inputSaveNameValue: PropTypes.string.isRequired,
  inputSaveDescriptionValue: PropTypes.string.isRequired,
  currentButtonText: PropTypes.shape({}).isRequired,
  currentMapStyle: PropTypes.string.isRequired,
  app: PropTypes.string.isRequired,
  currentMapOrientation: PropTypes.string.isRequired,
  currentTab: PropTypes.number.isRequired,
  bestsellerCities: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    total: PropTypes.string,
    zoom: PropTypes.string,
    scrollStep: PropTypes.string,
  })).isRequired,
  currentTitleAndText: PropTypes.shape({ title: PropTypes.object, text: PropTypes.object }).isRequired,
  hideSidebar: PropTypes.func.isRequired,
  getUserLocation: PropTypes.func.isRequired,
  changeMapStyle: PropTypes.func.isRequired,
  changeMapOrientation: PropTypes.func.isRequired,
  useCityCoordinates: PropTypes.func.isRequired,
  getRandomCity: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  makePDF: PropTypes.func.isRequired,
  onChangeMapSignature: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onChangeDescription: PropTypes.func.isRequired,
  searchCityByName: PropTypes.func.isRequired,
  getBestSellersPlace: PropTypes.func.isRequired,
};

export default LeftSidebarB2BView;
