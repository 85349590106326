import React from 'react';
import PropTypes from 'prop-types';
import './thumbnailBorder.scss';

const ThumbnailBorder = ({ currentMapOrientation, frame }) => {
  return (
    <>
      <div className={` 
       outerThumbnailBorder 
       outerThumbnailBorder__${frame} 
       outerThumbnailBorder__${frame}__${currentMapOrientation}`}
      />
      {/* <div className={` */}
      {/* innerThumbnailBorder */}
      {/* innerThumbnailBorder__${frame} */}
      {/* innerThumbnailBorder__${frame}__${currentMapOrientation}`} */}
      {/* /> */}
    </>
  );
};

ThumbnailBorder.propTypes = {
  currentMapOrientation: PropTypes.string.isRequired,
  frame: PropTypes.string.isRequired,
};

export default ThumbnailBorder;
