import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle, debounce } from 'lodash';
import HomePageView from './HomePageView';
import portraitSize from '../../constants/portraitSize';
import portraitSizeWijck from '../../constants/portraitSizeWijck';
import landscapeSize from '../../constants/landscapeSize';
import landscapeSizeWijck from '../../constants/landscapeSizeWijck';
import MapboxInfo from '../../context/MapboxContext';
import FrameSize from '../../constants/frameSize';
import FrameSizeWijck from '../../constants/frameSizeWijck';
import {
  LEFT_SIDEBAR_WIDTH, LOGO_HEIGHT, MAX_MAP_SIZE, MAX_SCROLL_STEP_MAP, MAX_ZOOM_LVL, MIN_ZOOM_LVL, SCREEN_PADDING,
  SCROLL_SPEED_MULTIPLIER, TABLET_SIZE, MAX_MAP_SIZE_ON_PHONE
} from '../../constants/mapConstants';
import '../../scss/pages/home/home.scss';
import {
  mapboxStylesApi, currencyTypes, sizeList, frameLists, WIJCK, PORTRAIT
} from '../../constants/general';
import {
  GET_PLACE_API, MAP_STYLE_WIJCK, MAPBOX_TOKEN, GET_IMAGE_FROM_API
} from '../../constants/mapboxAPI';
import haveCityInBase from '../../utils/haveCityInBase';
import getInformationOnCity from '../../utils/getInformationOnCity';
import getZoomPosition from '../../utils/getZoomPosition';
import getLanguage from '../../utils/getLanguage';
import recalculationSizeForMobileAndDesktop from '../../utils/recalculationSizeForMobileAndDesktop';
import {
  checkDisplayAndSizeScreen, convertCoordinate, findDefaultParamsForCity, isDesktopSize, isWijck
} from '../../utils/general';
import showGeolocationError from '../../utils/showGeolocationError';

export default class HomePage extends Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = MapboxInfo;

  constructor(props) {
    super(props);
    const withCustomProps = props.location?.pathname.split('/')[2];
    this.state = {
      mapboxInfo: {
        style: MAP_STYLE_WIJCK,
        zoom: 14,
        size: withCustomProps ? 'A3' : 'B2',
        orientation: 'portrait',
        frame: withCustomProps ? 'noFrame' : 'black',
        city: 'Amsterdam',
        coordinate: '52.35°N 4.90°E',
        country: 'The Netherlands',
      },
      isMoveMapOrChangeInputCoordinate: true,
      viewport: {
        latitude: 52.38,
        longitude: 4.90,
        mapStyle: 'ontouchstart' in window ? mapboxStylesApi.wijckMobile : mapboxStylesApi.wijckDesktop,
        zoom: this.initializationMapZoom(),
        height: '734.045',
        width: '504.656px',
      },
      searchVisibility: true,
      inputSearchValue: '',
      mapWidth: 504.656,
      mapHeight: 734.045,
      maxMapHeight: 1213.135,
      maxMapWidth: 834.030312497,
      scrollStep: 2,
      userLocation: '',
      zoomButtonsPosition: {
        top: -100,
        left: -100,
      },
      isTouchScreen: 'ontouchstart' in window,
      frameSize: {
        height: 188,
        width: 97,
        padding: 30
      },
      defaultMapSize: {
        defaultWidth: 443.437499999,
        defaultHeight: 645,
      },
      cloneImgSrc: null,
      firstStart: false,
      mapStyleName: WIJCK,
      isVisibleMobileOverlay: true,
      flagUserWriteSomeText: false
    };

    this.mapRef = React.createRef();
    this.geoCoderRef = React.createRef();
    this.mapBlockRef = React.createRef();
    this.leftSidebarRef = React.createRef();
    this.getUserPosition();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    document.addEventListener('touchmove', this.inputBlur);
    window.addEventListener('orientationchange', () => { this.forceUpdatePage(); });
    this.firstStart();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      mapboxInfo: { size, orientation, frame }, viewport, cloneImgSrc, firstStart, mapStyleName
    } = this.state;
    const {
      zoomButtonsPosition: { top: prevTop, left: prevLeft },
      mapboxInfo: { size: prevSize, orientation: prevOrientation, frame: prevFrame },
      viewport: prevViewport,
      mapStyleName: prevMapStyleName
    } = prevState;
    const { left, top } = getZoomPosition(frame, this.mapBlockRef, this.leftSidebarRef);

    if (top !== prevTop || left !== prevLeft) {
      this.savePosition(top, left);
    }
    if (size !== prevSize || orientation !== prevOrientation || frame !== prevFrame
      || mapStyleName !== prevMapStyleName) {
      this.saveFrameSize();
    }
    if (!firstStart) {
      this.saveFrameSize();
      this.changeFirstStart();
    }
    if (prevViewport !== viewport || cloneImgSrc === null) {
      this.saveMiniMap();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    document.removeEventListener('touchmove', this.inputBlur);
    window.removeEventListener('orientationchange', () => { this.forceUpdatePage(); });
  }


  firstStart = () => {
    const { location: { pathname } } = this.props;
    const link = pathname.split('/')[2];
    if (link) {
      const result = /-/g.test(link) ? link.replace('-', ' ') : link;
      this.searchCityByName(result).then(() => {
        this.setState({
          firstStart: true
        });
      });
    }
  };

  changeFirstStart = () => {
    this.setState({
      firstStart: true
    });
  };

  initializationMapZoom = () => {
    if (checkDisplayAndSizeScreen()) {
      return 11.714;
    }
    return 11;
  };

  cloneImg = async () => {
    if ('ontouchstart' in window) {
      this.getThumbOnMobile();
    } else {
      this.getThumbOnDesktop();
    }
  };

  getThumbOnDesktop = async () => {
    const { mapWidth, mapHeight } = this.state;
    if (mapWidth > 1279 || mapHeight > 1279) {
      const canvas = document.querySelector('.mapboxgl-canvas');
      const source = canvas.toDataURL('image/jpeg', 0.1);
      this.setState({
        cloneImgSrc: source,
      });
    } else {
      const source = await this.getImageFromMapboxAPI()
        .then((response) => {
          return URL.createObjectURL(response);
        });
      this.setState({
        cloneImgSrc: source,
      });
    }
  };

  getThumbOnMobile = () => {
    this.getImageFromMapboxAPI()
      .then((response) => {
        const source = URL.createObjectURL(response);
        this.setState({
          cloneImgSrc: source,
        });
      });
  };

  saveMiniMap = () => {
    const { mapWidth, mapHeight } = this.state;
    if (mapWidth > 1279 || mapHeight > 1279) {
      this.cloneImg();
      this.debounces();
    } else {
      this.throttleSaveMap();
    }
  };

  // eslint-disable-next-line react/sort-comp
  throttleSaveMap = throttle(this.cloneImg, 1000, { maxWait: 1200 });

  getImageFromMapboxAPI = async () => {
    const {
      viewport: {
        latitude, longitude, zoom, mapStyle,
      }, mapboxInfo, mapStyleName, scrollStep
    } = this.state;
    const newMapBoxInfo = { ...mapboxInfo, size: 'B2' };
    const { mapWidth, mapHeight } = recalculationSizeForMobileAndDesktop(
      newMapBoxInfo, scrollStep, zoom, mapStyleName
    );
    const resMapStyle = mapStyle.match(/[^/]+$/)[0];
    const api = `${GET_IMAGE_FROM_API}/${resMapStyle}/static/${longitude},${latitude},${zoom},0,0/`
        + `${parseInt(mapWidth)}x${parseInt(mapHeight)}?access_token=${MAPBOX_TOKEN}`;
    const response = await fetch(api, {
      method: 'GET',
    });
    return response.blob();
  };

  inputBlur = () => {
    const input = document.querySelector('input.mapboxgl-ctrl-geocoder--input');
    if (input) {
      input.blur();
    }
  };

  savePosition = (top, left) => {
    this.setState({
      zoomButtonsPosition: {
        top,
        left
      }
    });
  };

  getUserPosition = (params) => {
    if (navigator.geolocation) {
      if (params === 'viewOnMapUserLocation') {
        navigator.geolocation.getCurrentPosition(this.fetchPosition, showGeolocationError);
      } else {
        navigator.geolocation.getCurrentPosition(this.fetchPositionForPay, showGeolocationError);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by this browser.');
    }
  };

  fetchPositionForPay = async ({ coords: { latitude, longitude } }) => {
    this.getPlaceForGeocoding(latitude, longitude)
      .then(await getInformationOnCity)
      .then(({ country }) => {
        localStorage.setItem('country', country);
        this.setState({
          userLocation: country
        });
      });
  };

  fetchPosition = async ({ coords: { latitude, longitude } }) => {
    this.getPlaceForGeocoding(latitude, longitude)
      .then(await getInformationOnCity)
      .then(({ country, city }) => {
        const coordinate = convertCoordinate(latitude, longitude);
        const { viewport, mapboxInfo } = this.state;
        this.setState({
          viewport: {
            ...viewport,
            latitude,
            longitude,
          },
          mapboxInfo: {
            ...mapboxInfo,
            city,
            country,
            coordinate,
          },
          inputSearchValue: city,
        });
      });
  };

  getPlaceForGeocoding = async (lat, lng) => {
    const api = `${GET_PLACE_API}/${lng},${lat}.json/?language=${getLanguage()}&access_token=${MAPBOX_TOKEN}`;
    const response = await fetch(api, {
      method: 'GET'
    });
    return response.json();
  };

  getWindowDimensions = () => ({ width: window.innerWidth, height: window.innerHeight });

  changeTab = (newTab) => { this.setState({ searchVisibility: (newTab === 0) }); };

  changeFrame = (frame) => { this.setMapboxInfoValue('frame', frame); };

  setMapboxInfoValue = (prop, value, flag) => {
    const { mapboxInfo, flagUserWriteSomeText } = this.state;
    let newFlag = flagUserWriteSomeText;
    if (prop === 'coordinate') {
      newFlag = true;
      if (flag) {
        newFlag = false;
      }
    }
    this.setState({
      mapboxInfo: {
        ...mapboxInfo,
        [prop]: value,
        flagUserWriteSomeText: prop === 'coordinate'
      },
      isMoveMapOrChangeInputCoordinate: true,
      flagUserWriteSomeText: newFlag,
    });
    this.setState({
      mapboxInfo: {
        ...mapboxInfo,
        [prop]: value
      }
    });
  };

  onChangeMapSignature = (name, value, flag) => {
    this.setMapboxInfoValue(name, value, flag);
  };

  setMapboxInfoSettings = (settings) => {
    const {
      mapboxInfo, scrollStep, viewport, mapStyleName
    } = this.state;
    const nextMapboxInfo = {
      ...mapboxInfo,
      ...settings
    };
    const { orientation, size } = nextMapboxInfo;
    const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
    const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
    const mapSizes = orientation === PORTRAIT ? portrait : landscape;
    const newWidth = mapSizes[size].defaultWidth + ((scrollStep - 1)
      * (mapSizes[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER));
    const newHeight = mapSizes[size].defaultHeight + ((scrollStep - 1)
      * (mapSizes[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER));
    const { maxMapWidth, maxMapHeight } = mapSizes[size];

    const nextViewport = {
      ...viewport,
      width: 'ontouchstart' in window ? maxMapWidth : newWidth,
      height: 'ontouchstart' in window ? maxMapHeight : newHeight,
    };
    this.setState({
      mapboxInfo: nextMapboxInfo,
      mapWidth: newWidth,
      mapHeight: newHeight,
      viewport: { ...viewport, ...nextViewport },
      maxMapWidth,
      maxMapHeight,
      isMoveMapOrChangeInputCoordinate: false
    });
  };

  changeOrientation = (orientation) => {
    this.setMapboxInfoSettings({ orientation });
  };

  changeSize = (size) => {
    this.setMapboxInfoSettings({ size });
  };

  changeMapStyle = (mapStyle) => {
    let mapStyleUri = mapboxStylesApi[mapStyle];
    if (mapStyle === WIJCK) {
      mapStyleUri = 'ontouchstart' in window && !isDesktopSize()
        ? mapboxStylesApi.wijckMobile : mapboxStylesApi.wijckDesktop;
    }
    const { viewport, mapboxInfo: { size } } = this.state;
    this.setState({
      viewport: { ...viewport, mapStyle: mapStyleUri },
      mapStyleName: mapStyle
    }, () => { this.debounces(); this.changeSize(size); });
  };

  fixMiniMap = () => {
    const { viewport } = this.state;
    let { longitude } = viewport;
    longitude += 0.000001;
    this.setState({
      viewport: { ...viewport, longitude }
    });
  };

  // eslint-disable-next-line react/sort-comp
  debounces = debounce(this.fixMiniMap, 1000, { maxWait: 1200 });

  getDefaultMapSizes = () => {
    const { mapboxInfo: { orientation, size }, mapStyleName } = this.state;
    const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
    const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === PORTRAIT ? portrait : landscape;
    const mapWidth = mapSize[size].defaultWidth;
    const mapHeight = mapSize[size].defaultHeight;
    return { mapWidth, mapHeight };
  };

  filterMapSearch = (json) => {
    let result;
    if (json.place_type[0] === 'place' || json.place_type[1] === 'place') {
      result = json;
    }
    return result;
  };

  getPlaceByCity = async (city) => {
    const response = await fetch(
      `${GET_PLACE_API}/${city.trim()}.json?limit=5&language=${getLanguage()}&access_token=${MAPBOX_TOKEN}`, {
        method: 'GET',
      }
    );
    return response.json();
  };

  getRandomCity = async ({
    latitude, longitude, zoom: currentZoom, scrollStep, city, mobileZoom,
  }) => {
    const { viewport, mapboxInfo, mapStyleName } = this.state;
    const coordinate = convertCoordinate(latitude, longitude);
    const res = await this.getPlaceByCity(city);
    const { country } = await getInformationOnCity(res);
    const { mapWidth, mapHeight } = recalculationSizeForMobileAndDesktop(
      mapboxInfo, scrollStep, currentZoom, mapStyleName
    );
    let zoom;
    if ('ontouchstart' in window) {
      zoom = parseFloat(mobileZoom);
    } else {
      zoom = parseInt(currentZoom);
    }
    this.setState({
      viewport: {
        ...viewport, longitude, latitude, zoom
      },
      mapboxInfo: {
        ...mapboxInfo, country, city, coordinate
      },
      inputSearchValue: city,
      scrollStep,
      mapWidth,
      mapHeight,
    });
  };

  searchCityByName = async (params) => {
    let zoom = 11;
    const val = document.querySelector('input.mapboxgl-ctrl-geocoder--input').value;
    let searchRes;
    try {
      searchRes = await this.getPlaceByCity(params || val);
      const { viewport, mapboxInfo, mapStyleName } = this.state;
      let { scrollStep } = this.state;
      let longitude; let latitude; let city;
      const results = await getInformationOnCity(searchRes);
      const { country } = results;
      if (haveCityInBase(results.city, results.latitude, results.longitude)) {
        const result = findDefaultParamsForCity(params || val);
        if ('ontouchstart' in window) {
          zoom = result.mobileZoom;
        } else {
          zoom = parseInt(result.zoom);
        }
        longitude = parseFloat(result.longitude);
        latitude = parseFloat(result.latitude);
        scrollStep = parseInt(result.scrollStep);
        city = result[getLanguage()];
      } else {
        longitude = results.longitude;
        latitude = results.latitude;
        city = results.city;
      }
      const coordinate = convertCoordinate(latitude, longitude);
      const result = recalculationSizeForMobileAndDesktop(mapboxInfo, scrollStep, zoom, mapStyleName);
      const { mapWidth, mapHeight } = result;
      if (longitude === 0 && latitude === 0) {
        // eslint-disable-next-line no-throw-literal
        throw 'err';
      }
      this.setState({
        viewport: {
          ...viewport, longitude, latitude, zoom,
        },
        mapboxInfo: {
          ...mapboxInfo, country, city, coordinate
        },
        mapWidth,
        mapHeight,
        inputSearchValue: city,
        scrollStep,
      });
      return true;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('There is no such place!');
      return false;
    }
  };

  handleOnResult = async (event) => {
    const { mapboxInfo, viewport, mapStyleName } = this.state;
    let { scrollStep } = this.state;
    let latitude = event.result.center[1];
    let longitude = event.result.center[0];
    let city = event.result.text;
    let zoom = 11;
    try {
      const PlaceForGeo = await this.getPlaceForGeocoding(latitude, longitude);
      const { country } = await getInformationOnCity(PlaceForGeo);
      if (haveCityInBase(city, latitude, longitude)) {
        const defaultParamsForCity = findDefaultParamsForCity(city);
        if ('ontouchstart' in window) {
          zoom = defaultParamsForCity.mobileZoom;
        } else {
          zoom = parseInt(defaultParamsForCity.zoom);
        }
        longitude = parseFloat(defaultParamsForCity.longitude);
        latitude = parseFloat(defaultParamsForCity.latitude);
        scrollStep = parseInt(defaultParamsForCity.scrollStep);
        // eslint-disable-next-line no-prototype-builtins
        city = defaultParamsForCity.hasOwnProperty('cityName')
          ? defaultParamsForCity.cityName
          : defaultParamsForCity[getLanguage()];
      }
      const { mapWidth, mapHeight } = recalculationSizeForMobileAndDesktop(mapboxInfo, scrollStep, zoom, mapStyleName);
      this.setState({
        mapboxInfo: {
          ...mapboxInfo, city, country, zoom,
        },
        viewport: {
          ...viewport, zoom, longitude, latitude
        },
        inputSearchValue: city,
        scrollStep,
        mapWidth,
        mapHeight
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  renderCurrency = (obj, userLocation) => {
    return Object.entries(obj).reduce((acc, [, value]) => {
      if (value[0] === userLocation) {
        return value[1];
      }
      return acc;
    }, '€');
  };

  zoomChange = (event) => { this.mapWheel({ deltaY: event === 'plus' ? -1 : 1 }); };


  mapWheel = (e) => {
    const {
      mapboxInfo: { size, orientation }, viewport: { zoom }, mapHeight, mapWidth, isTouchScreen, mapStyleName
    } = this.state;
    let newWidth = mapWidth;
    let newHeight = mapHeight;
    const delay = e.deltaY;
    const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
    const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait : landscape;
    newHeight = delay > 0 ? newHeight + mapSize[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER
      : newHeight - mapSize[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER;

    newWidth = delay > 0 ? newWidth + mapSize[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER
      : newWidth - mapSize[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER;

    const maxMapSize = !isTouchScreen ? MAX_MAP_SIZE : MAX_MAP_SIZE_ON_PHONE;
    if (zoom === MIN_ZOOM_LVL && (newHeight < maxMapSize && newWidth < maxMapSize)) {
      this.changeMapWidthNHeight(newWidth, newHeight);
    }
    if (newHeight > this.getSizes('h', 'max') && newWidth > this.getSizes('w', 'max')) {
      this.zoomJumpUp();
      return;
    }
    if (newHeight < this.getSizes('h', 'min') && newWidth < this.getSizes('w', 'min')) {
      this.zoomJumpDown();
      return;
    }
    this.changeMapWidthNHeight(newWidth, newHeight);
  };

  changeMapWidthNHeight = (mapWidth, mapHeight) => {
    const { mapWidth: mapW, scrollStep } = this.state;
    const newScrollStep = mapW < mapWidth ? scrollStep + 1 : scrollStep - 1;
    this.setState({
      mapWidth,
      mapHeight,
      scrollStep: newScrollStep,
    });
  };

  getSizes = (sizeOrientation, maxOrMin) => {
    const { mapboxInfo: { size, orientation }, mapStyleName } = this.state;
    const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
    const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait : landscape;
    if (sizeOrientation === 'h') {
      return maxOrMin === 'max' ? mapSize[size].maxMapHeight : mapSize[size].defaultHeight;
    }
    return maxOrMin === 'max' ? mapSize[size].maxMapWidth : mapSize[size].defaultWidth;
  };

  zoomJumpDown = () => {
    const { viewport: { zoom } } = this.state;
    if (zoom !== MAX_ZOOM_LVL) {
      const newZoom = zoom + 1;
      this.zoomJump(this.getSizes('w', 'max'), this.getSizes('h', 'max'),
        newZoom);
    }
  };

  zoomJumpUp = () => {
    const { viewport: { zoom } } = this.state;
    if (parseInt(zoom) !== MIN_ZOOM_LVL) {
      const newZoom = zoom - 1;
      this.zoomJump(this.getSizes('w', 'min'), this.getSizes('h', 'min'),
        newZoom);
    }
  };

  zoomJump = (mapWidth, mapHeight, zoom) => {
    const { viewport: { zoom: currentZoom }, viewport } = this.state;
    const scrollStep = zoom > currentZoom ? MAX_SCROLL_STEP_MAP : 1;
    const width = `${mapWidth}px`;
    const height = `${mapHeight}px`;
    this.setState({
      mapWidth,
      mapHeight,
      scrollStep,
      viewport: {
        ...viewport, zoom, width, height
      }
    });
  };

  getScale = () => {
    const { mapboxInfo: { orientation, size }, mapStyleName } = this.state;
    let { height, width } = this.getWindowDimensions();
    const currentWidth = width;
    const { width: frameSizeWidth, height: frameSizeHeight } = this.getFrameSize();
    const widthCompensation = orientation === PORTRAIT ? 61.875 : 0;
    const heightCompensation = orientation === PORTRAIT ? 85 : 0;
    const newFrameWidth = isWijck(mapStyleName) ? frameSizeWidth - widthCompensation : frameSizeWidth;
    const newFrameHeight = isWijck(mapStyleName) ? frameSizeHeight - heightCompensation : frameSizeHeight;
    const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
    const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
    const mapSize = orientation === 'portrait' ? portrait[size] : landscape[size];
    const frameHeight = mapSize.defaultHeight + newFrameHeight;
    const frameWidth = mapSize.defaultWidth + newFrameWidth;
    if (currentWidth > TABLET_SIZE) {
      width -= LEFT_SIDEBAR_WIDTH;
      height -= LOGO_HEIGHT;
    } else {
      height -= LOGO_HEIGHT;
      width -= SCREEN_PADDING * 2;
    }
    const scale = Math.min(
      1,
      height / frameHeight,
      width / frameWidth,
    );
    const scale4k = orientation === 'portrait'
      ? Math.min(1.3,
        Math.min(1.5, width / (frameWidth * 2) - 0.4),
        Math.min(1.5, height / (frameHeight) - 0.1))
      : Math.min(1.5,
        Math.min(1.5, width / (frameWidth * 2) - 0.1),
        Math.min(1.5, height / (frameHeight) - 0.8));
    const newScale = scale > scale4k ? scale : scale4k;
    let marginTop = 0;
    if (currentWidth <= TABLET_SIZE) {
      marginTop = (frameHeight - height) / 2 - 115;
    }
    return {
      marginTop: -marginTop,
      scale: parseFloat(newScale.toFixed(2)),
    };
  };

  handleGeocoderViewportChange = (viewport) => {
    return this.handleViewportChange({
      ...viewport,
    });
  };

  handleViewportChange = (newViewport) => {
    const {
      viewport,
      isMoveMapOrChangeInputCoordinate,
      mapboxInfo,
      flagUserWriteSomeText
    } = this.state;
    const { latitude, longitude } = newViewport;
    const coordinate = convertCoordinate(latitude, longitude);
    if (!isMoveMapOrChangeInputCoordinate) {
      this.setState({
        isMoveMapOrChangeInputCoordinate: true
      });
      return;
    }
    const newMapboxInfo = flagUserWriteSomeText ? mapboxInfo : { ...mapboxInfo, coordinate };
    this.setState({
      viewport: { ...viewport, ...newViewport },
      mapboxInfo: newMapboxInfo
    });
  };

  getFrameSize = () => {
    const { mapboxInfo: { size, orientation, frame }, mapStyleName } = this.state;
    const frameSizes = isWijck(mapStyleName) ? FrameSizeWijck : FrameSize;
    const { height, width, padding } = frameSizes[orientation][frame][size];
    return { height, width, padding };
  };

  saveFrameSize = () => {
    const { height, width, padding } = this.getFrameSize();
    const { mapWidth, mapHeight } = this.getDefaultMapSizes();
    this.setState({
      frameSize: {
        height,
        width,
        padding
      },
      defaultMapSize: {
        defaultWidth: mapWidth,
        defaultHeight: mapHeight
      }
    });
  };

  forceUpdatePage = () => { this.forceUpdate(); };

  onResize = () => { this.setState(this.getWindowDimensions()); };

  getPrice = () => {
    const { mapboxInfo: { size, frame }, userLocation } = this.state;
    let price = this.renderCurrency(currencyTypes, userLocation);
    const framePrice = frameLists[frame].price[size];
    const picturePrice = sizeList.find((val) => val.sizeName === size).price;
    const result = parseFloat(framePrice) + parseFloat(picturePrice);
    price += ` ${result.toFixed(2).replace('.', ',')}`;
    return price;
  };

  onTurnOffMobileOverlay = () => this.setState({ isVisibleMobileOverlay: false })

  render() {
    const {
      mapboxInfo, viewport, mapHeight, mapWidth, inputSearchValue, searchVisibility, userLocation,
      zoomButtonsPosition, scrollStep, frameSize, defaultMapSize, cloneImgSrc, isTouchScreen, maxMapHeight, maxMapWidth,
      mapStyleName, isVisibleMobileOverlay,
    } = this.state;
    const scaleAndIndentation = this.getScale();
    const price = this.getPrice();
    return (
      <MapboxInfo.Provider value={{
        scrollStep,
        mapboxInfo,
        userLocation,
        cloneImgSrc,
        viewport,
        isTouchScreen,
        price,
        mapStyleName,
        geoCoderRef: this.geoCoderRef,
        leftSideBarRef: this.leftSidebarRef,
        mapRef: this.mapRef,
        getRandomCity: this.getRandomCity,
        forceUpdatePage: this.forceUpdatePage,
        zoomJump: this.zoomJump,
        changeMapWidthNHeight: this.changeMapWidthNHeight,
        searchCityByName: this.searchCityByName,
        onChangeMapSignature: this.onChangeMapSignature,
        changeTab: this.changeTab,
        changeFrame: this.changeFrame,
        changeSize: this.changeSize,
        changeOrientation: this.changeOrientation,
        changeMapStyle: this.changeMapStyle,
        renderCurrency: this.renderCurrency,
        getUserPosition: this.getUserPosition,
      }}
      >
        <HomePageView
          isTouchScreen={isTouchScreen}
          maxMapHeight={maxMapHeight}
          maxMapWidth={maxMapWidth}
          scaleAndIndentation={scaleAndIndentation}
          frameSize={frameSize}
          zoomButtonsPosition={zoomButtonsPosition}
          mapBlockRef={this.mapBlockRef}
          mapWheel={this.mapWheel}
          zoomChange={this.zoomChange}
          defaultMapSize={defaultMapSize}
          handleViewportChange={this.handleViewportChange}
          handleGeocoderViewportChange={this.handleGeocoderViewportChange}
          inputSearchValue={inputSearchValue}
          filterMapSearch={this.filterMapSearch}
          handleOnResult={this.handleOnResult}
          searchVisibility={searchVisibility}
          mapHeight={mapHeight}
          mapWidth={mapWidth}
          mapStyleName={mapStyleName}
          isVisibleMobileOverlay={isVisibleMobileOverlay}
          onTurnOffMobileOverlay={this.onTurnOffMobileOverlay}
        />
      </MapboxInfo.Provider>
    );
  }
}
HomePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};
