import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const CloseSidebar = ({
  hideSidebar, isCollapsed, closeButtonPosition
}) => {
  return (
    <button
      style={{
        bottom: closeButtonPosition
      }}
      className={cx({
        'left-sidebar__close': true,
        'left-sidebar__close-hidden': isCollapsed
      })}
      onClick={hideSidebar}
      tabIndex="0"
      type="button"
    >
      <span className="left-sidebar__close-icon" />
    </button>
  );
};

CloseSidebar.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  hideSidebar: PropTypes.func.isRequired,
  closeButtonPosition: PropTypes.number.isRequired,
};

export default CloseSidebar;
