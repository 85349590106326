import portraitSize from '../constants/portraitSize';
import landscapeSize from '../constants/landscapeSize';
import getMapSizeWithScrollStep from './getMapSizeWithScrollStep';

const recalculationSizeForMobileAndDesktop = (mapboxInfo, scrollStep, zoom, mapStyleName) => {
  let mapWidth;
  let mapHeight;
  let newZoom;
  const reversScrollStep = {
    7: 0,
    6: 0.142,
    5: 0.285714286,
    4: 0.428,
    3: 0.571,
    2: 0.714,
    1: 0.857,
  };
  if ('ontouchstart' in window) {
    const mapSizes = mapboxInfo.orientation === 'portrait' ? portraitSize : landscapeSize;
    mapWidth = mapSizes[mapboxInfo.size].maxMapWidth;
    mapHeight = mapSizes[mapboxInfo.size].maxMapHeight;
    newZoom = reversScrollStep[scrollStep] + parseInt(zoom);
  } else {
    const size = getMapSizeWithScrollStep(scrollStep, mapboxInfo.size, mapboxInfo.orientation, mapStyleName);
    mapWidth = size.mapWidth;
    mapHeight = size.mapHeight;
    newZoom = zoom;
  }
  return { mapWidth, mapHeight, newZoom };
};

export default recalculationSizeForMobileAndDesktop;
