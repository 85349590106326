import React from 'react';
import PropTypes from 'prop-types';
import MapboxB2B from './components/HomePageB2BMapBox';
import LeftSidebar from '../../layout/LedtSidebarB2B/LeftSidebarB2B';

const HomePageB2BView = ({
  scaleAndIndentation, mapWheel, mapBlockRef, zoomButtonsPosition, zoomChange, frameSize, defaultMapSize, mapHeight,
  mapWidth, handleOnResult, searchVisibility, filterMapSearch, inputSearchValue, handleGeocoderViewportChange,
  handleViewportChange, isTouchScreen, maxMapWidth, maxMapHeight, mapStyleName
}) => {
  return (
    <div className="container-fluid">
      <LeftSidebar />
      <MapboxB2B
        defaultMapSize={defaultMapSize}
        scaleAndIndentation={scaleAndIndentation}
        mapBlockRef={mapBlockRef}
        mapWheel={mapWheel}
        zoomChange={zoomChange}
        zoomButtonsPosition={zoomButtonsPosition}
        frameSize={frameSize}
        handleViewportChange={handleViewportChange}
        handleGeocoderViewportChange={handleGeocoderViewportChange}
        inputSearchValue={inputSearchValue}
        filterMapSearch={filterMapSearch}
        handleOnResult={handleOnResult}
        searchVisibility={searchVisibility}
        mapHeight={mapHeight}
        mapWidth={mapWidth}
        isTouchScreen={isTouchScreen}
        maxMapWidth={maxMapWidth}
        maxMapHeight={maxMapHeight}
        mapStyleName={mapStyleName}
      />
    </div>
  );
};

HomePageB2BView.propTypes = {
  isTouchScreen: PropTypes.bool.isRequired,
  maxMapHeight: PropTypes.number.isRequired,
  maxMapWidth: PropTypes.number.isRequired,

  searchVisibility: PropTypes.bool.isRequired,
  mapHeight: PropTypes.number.isRequired,
  mapWidth: PropTypes.number.isRequired,
  inputSearchValue: PropTypes.string.isRequired,
  mapStyleName: PropTypes.string.isRequired,
  frameSize: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  defaultMapSize: PropTypes.shape({
    defaultWidth: PropTypes.number,
    defaultHeight: PropTypes.number
  }).isRequired,
  zoomButtonsPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
  mapBlockRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  scaleAndIndentation: PropTypes.shape({
    scale: PropTypes.number,
    marginLeft: PropTypes.number,
    marginTop: PropTypes.number,
  }).isRequired,
  zoomChange: PropTypes.func.isRequired,
  mapWheel: PropTypes.func.isRequired,
  handleOnResult: PropTypes.func.isRequired,
  handleGeocoderViewportChange: PropTypes.func.isRequired,
  handleViewportChange: PropTypes.func.isRequired,
  filterMapSearch: PropTypes.func.isRequired,

};

export default HomePageB2BView;
