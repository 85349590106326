const metrica = (productName, productId, productPrice) => {
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [{
          name: productName, // Productname (city)
          id: productId, // Main Product-ID
          price: productPrice, // Stukprijs
          category: 'custom',
          dimension10: 'custom', // Product-type -> 'district', 'city' or 'building'
          quantity: 1 // Aantal
        }]
      }
    }
  });
};

export default metrica;
