import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { allTabsB2B, allTabsB2C } from '../../constants/general';
import t from '../../i18n/translate';

const LeftSidebarMenu = ({
  currentTab, changeTab, isCollapsed, windowWidth, app
}) => {
  const menuTabs = app === 'b2c' ? allTabsB2C : allTabsB2B;
  return (
    <ul className="left-sidebar__menu">
      {menuTabs.map((item, index) => (
        <li
          key={item}
          className={cx({
            [`left-sidebar__menu-item-${app}`]: true,
            [`left-sidebar__menu-item-${app}--active`]: currentTab === index,
            'left-sidebar__menu-item--active-disable': windowWidth < 992 && isCollapsed,
          })}
          onClick={() => changeTab(index)}
          role="presentation"
        >
          <div
            className={cx({
              [`left-sidebar__menu-item-${app}-img-${app}`]: true,
              [`left-sidebar__menu-item-${app}-img-${app}-${index}`]: true,
            })}
          />
          <span>{t(`Menu.${item}`)}</span>
        </li>
      ))}
    </ul>
  );
};

LeftSidebarMenu.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  windowWidth: PropTypes.number.isRequired,
  currentTab: PropTypes.number.isRequired,
  app: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
};

export default LeftSidebarMenu;
