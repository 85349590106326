import React, { Component } from 'react';
import getLanguage from '../utils/getLanguage';
import { currencyTypes } from '../constants/general';

const WithAnalytics = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pageTab: 'Location',
        firstStart: false
      };
    }

    componentDidUpdate() {
      const { pageTab, firstStart } = this.state;
      const pageTabLocalStorage = localStorage.getItem('currentTab');
      const country = localStorage.getItem('currentTab');
      if (pageTabLocalStorage !== pageTab || !firstStart) {
        this.trackPage(getLanguage(),
          this.renderCurrency(currencyTypes, country), this.convertPageType(pageTabLocalStorage));
      }
    }

    convertPageType = (pageTypes) => {
      switch (pageTypes) {
        case 'Location':
          return 'custom_location';
        case 'Customise':
          return 'custom_customize';
        case 'SizeExtras':
          return 'custom_size';
        case 'AddToCart':
          return 'custom_addtocart';
        case 'custom_location':
          return 'Location';
        case 'custom_customize':
          return 'Customise';
        case 'custom_size':
          return 'SizeExtras';
        case 'custom_addtocart':
          return 'AddToCart';
        default:
          return 'en';
      }
    };

    renderCurrency = (currencyType, userLocation) => {
      return Object.entries(currencyType).reduce((acc, [, value]) => {
        if (value[0] === userLocation) {
          return value[1];
        }
        return acc;
      }, 'EUR');
    };

    trackPage = (lang, currency, pageTab) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'page',
        siteSection: 'custom',
        language: lang, // 'NL' / 'EN' or 'FR'
        currency, // 'EUR' / 'GBP' or 'USD'
        pageType: pageTab // custom_location / custom_customize / custom_size / custom_addtocart (funnel steps)
      });
      this.setState({
        pageTab: this.convertPageType(pageTab),
        firstStart: true
      });
    };

    render() {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent {...this.props} />
      );
    }
  };
};

export default WithAnalytics;
