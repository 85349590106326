import cityCorrection from '../constants/cityCorrection';
import getLanguage from './getLanguage';

const similarityLat = (dataLat, findLat) => {
  const result = Math.max(Math.abs(dataLat), Math.abs(findLat)) - Math.min(Math.abs(dataLat), Math.abs(findLat));
  return result <= 1;
};

const similarityLng = (dataLng, findLng) => {
  const result = Math.max(Math.abs(dataLng), Math.abs(findLng)) - Math.min(Math.abs(dataLng), Math.abs(findLng));
  return result <= 1;
};

const haveCityInBase = (cityName, lat, lng) => {
  const findCity = cityName.toLowerCase();
  const result = cityCorrection.find((item) => {
    if (item[getLanguage()].toLowerCase() === findCity) {
      return similarityLat(item.latitude, lat) && similarityLng(item.longitude, lng);
    }
    return false;
  });
  return !!result;
};
export default haveCityInBase;
