import en from './en-US';
import de from './de-DE';
import fr from './fr-CA';
import nl from './nl-NL';

export default {
  ...en,
  ...de,
  ...fr,
  ...nl
};
