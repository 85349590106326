import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import MapboxInfo from '../../../context/MapboxContext';
import { sizeList, frameLists } from '../../../constants/general';
import t from '../../../i18n/translate';
import Thumbnail from '../../../components/Thumbnail/Thumbnail';

const LeftSidebarAddToCart = (
  {
    customizeMap, activeFrameElement, activeSizeElement, currentMapOrientation, finalPrice
  }
) => {
  const {
    mapboxInfo: {
      city, country, coordinate, frame,
    }, cloneImgSrc, mapStyleName
  } = useContext(MapboxInfo);
  return (
    <section>
      <div className={`add-to-cart add-to-cart-${currentMapOrientation}`}>
        <Thumbnail
          frame={frame}
          currentMapOrientation={currentMapOrientation}
          cloneImgSrc={cloneImgSrc}
          coordinate={coordinate}
          city={city}
          country={country}
          mapStyleName={mapStyleName}
        />
        <div className="add-to-cart__params-block">
          <p className="add-to-cart__params-block__title">{city}</p>
          <span className="add-to-cart__params-block__text">
            {t('AddToCart.size')}
            :&nbsp;
            {sizeList.map((item) => {
              if (item.sizeName === activeSizeElement) {
                return item.sizeWithSpace;
              }
              return false;
            })}
            &nbsp;
            cm
          </span>
          <span className="add-to-cart__params-block__text">
            {t('AddToCart.frame')}
            :
            &nbsp;
            {frameLists[activeFrameElement].text}
          </span>
          <span className="add-to-cart__params-block__text">
            {t('AddToCart.price')}
            :&nbsp;€&nbsp;
            {parseFloat(finalPrice).toFixed(2).toString()
              .replace('.', ',')}
          </span>
          <Button
            text={t('AddToCart.customise')}
            className="add-to-cart__params-block__customize"
            onClick={customizeMap}
          />
        </div>
      </div>
    </section>
  );
};

LeftSidebarAddToCart.propTypes = {
  finalPrice: PropTypes.number.isRequired,
  activeFrameElement: PropTypes.string.isRequired,
  activeSizeElement: PropTypes.string.isRequired,
  currentMapOrientation: PropTypes.string.isRequired,
  customizeMap: PropTypes.func.isRequired
};

export default LeftSidebarAddToCart;
