import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import MapboxInfo from '../../../context/MapboxContext';
import t from '../../../i18n/translate';

const LeftSidebarLocation = (
  {
    bestsellerCities, getRandomCity, getUserLocation, getBestSellersPlace, searchCityByName
  }
) => {
  const { geoCoderRef } = useContext(MapboxInfo);
  return (
    <div className="marginTopSection">
      <h4 className="tab-name">{t('Location.search_input')}</h4>
      <div className="findCity-block">
        <div
          className="citySearch-input"
          ref={geoCoderRef}
        />
        <div className="findCity-block__buttons">
          <Button
            text={<div className="searchIcon location-icon" />}
            className="searchButton"
            onClick={searchCityByName}
          />
          <div className="whiteStrip" />
          <Button
            text={<div className="currentLocation location-icon" />}
            className="getMyGeo-button"
            onClick={getUserLocation}
          />
        </div>
      </div>

      <div className="marginTopSection">
        <h4 className="tab-name">{t('Location.label_randomCity')}</h4>
        <div className="bestsellersCity-block">
          <Button
            onClick={getRandomCity}
            className="citySelectButton"
            text={t('Location.button_randomCity')}
          />
        </div>
      </div>

      <div className="marginTopSection">
        <h4 className="tab-name">{t('Location.label_bestsellers')}</h4>
        <div className="bestsellersCity-block">
          {bestsellerCities.map((item) => (
            <Button
              key={item.city}
              onClick={() => getBestSellersPlace(item)}
              className="citySelectButton"
              text={item.city}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

LeftSidebarLocation.propTypes = {
  bestsellerCities: PropTypes.arrayOf(PropTypes.shape({
    city: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    total: PropTypes.string,
    zoom: PropTypes.string,
    scrollStep: PropTypes.string,
  })).isRequired,
  getRandomCity: PropTypes.func.isRequired,
  getUserLocation: PropTypes.func.isRequired,
  getBestSellersPlace: PropTypes.func.isRequired,
  searchCityByName: PropTypes.func.isRequired,
};

export default LeftSidebarLocation;
