import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../../../components/Button/Button';
import MapboxInfo from '../../../context/MapboxContext';
import Input from '../../../components/Input/Input';
import useBlurInputs from '../../../utils/useBlurInputs';
import { allMapStyle, mapOrientations } from '../../../constants/general';
import t from '../../../i18n/translate';

const LeftSidebarCustomise = ({
  onChangeMapSignature, currentMapStyle, changeMapStyle,
  currentMapOrientation, changeMapOrientation, useCityCoordinates,
}) => {
  useBlurInputs();
  const { mapboxInfo: { city, country, coordinate } } = useContext(MapboxInfo);
  return (
    <div className="customize js-customize-tab">
      <div className="marginTopSection">
        <h4 className="tab-name">{t('Customise.label_orientation')}</h4>
        <div className="orientation">
          {mapOrientations.map((item, index) => (
            <div
              className={cx({
                orientationItem: true,
                'orientationItem-active': item === currentMapOrientation
              })}
              onClick={() => changeMapOrientation(index)}
              key={item}
              role="button"
              tabIndex="0"
              onKeyPress={() => {}}
            >
              <div
                className={`orientationItem__${item}`}
              />
              <span className="orientationItem__text">{t(`Customise.input_${item}`)}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="marginTopSection">
        <h4 className="tab-name">{t('Customise.label_style')}</h4>
        <div className="chooseMapStyle">
          {Object.entries(allMapStyle).map(([key, value], index) => (
            <div
              className={cx({
                chooseMapStyle__item: true,
                'chooseMapStyle-active': key === currentMapStyle,
              })}
              onClick={() => changeMapStyle(key)}
              onKeyPress={() => {}}
              role="button"
              tabIndex={index}
              key={key}
            >
              <div className={key} />
              <span className="chooseMapStyle__item__text">{value}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="mapStyling__text-block marginTopSection">
        <h4 className="tab-name">{t('Customise.label_text')}</h4>
        <Input
          className="mapStyling__changeTextInput"
          type="text"
          onChange={(e) => onChangeMapSignature(e, 'changeCoordinate')}
          value={coordinate || ''}
        />
        <Input
          className="mapStyling__changeTextInput"
          type="text"
          onChange={(e) => onChangeMapSignature(e, 'changeCityName')}
          value={city || ''}
          id="input"
        />
        <Input
          className="mapStyling__changeTextInput"
          type="text"
          onChange={(e) => onChangeMapSignature(e, 'ChangeCountryName')}
          value={country || ''}
        />
        <Button
          className="mapStyling__defaultParams"
          onClick={useCityCoordinates}
          text={t('Customise.button_useCoordinatesText')}
        />
      </div>
    </div>
  );
};

LeftSidebarCustomise.propTypes = {
  currentMapStyle: PropTypes.string.isRequired,
  currentMapOrientation: PropTypes.string.isRequired,
  onChangeMapSignature: PropTypes.func.isRequired,
  changeMapStyle: PropTypes.func.isRequired,
  changeMapOrientation: PropTypes.func.isRequired,
  useCityCoordinates: PropTypes.func.isRequired,
};

export default LeftSidebarCustomise;
