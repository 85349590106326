import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import './zoomButtons.scss';

const ZoomButtons = ({
  zoomChange, zoomButtonsPosition
}) => {
  return (
    <div
      className="changeZoomContainer"
      style={{
        top: zoomButtonsPosition.top,
        left: zoomButtonsPosition.left
      }}
    >
      <Button
        className="changeZoomContainer__button change-zoom__plus"
        onClick={() => zoomChange('plus')}
      />
      <Button
        className="changeZoomContainer__button change-zoom__minus"
        onClick={() => zoomChange('minus')}
      />
    </div>
  );
};


ZoomButtons.propTypes = {
  zoomChange: PropTypes.func.isRequired,
  zoomButtonsPosition: PropTypes.shape({
    top: PropTypes.number,
    left: PropTypes.number,
  }).isRequired,
};

export default ZoomButtons;
