import t from '../i18n/translate';

export const DAMSCO = 'damsco';
export const WIJCK = 'wijck';
export const PORTRAIT = 'portrait';
export const LANDSCAPE = 'landscape';

export const allMapStyle = {
  wijck: 'WIJCK.',
  damsco: 'Damsco',
  hoxton: 'Hoxton',
  vogue: 'Vogue',
  kinfolk: 'Kinfolk',
};
export const mapOrientations = ['portrait', 'landscape'];
export const allTabsB2B = ['location', 'customise', 'save'];
export const allTabsB2C = ['location', 'customise', 'sizeExtras', 'addToCart'];
export const currencyTypes = {
  euro: ['euro', 'EUR'],
  usa: ['United States of America', 'USD'],
  unitedKingdom: ['United Kingdom', 'GBP'],
};

export const mapboxStylesApi = {
  wijckDesktop: 'mapbox://styles/wijckprints/ckm4qzp6aamy817lb0hjt7dt9',
  wijckMobile: 'mapbox://styles/wijckprints/ckmdisjfsar7y17lhgbsnnkys',
  wijckPrint: 'mapbox://styles/wijckprints/ckosyj68l0by917myzwalnete',
  damsco: 'mapbox://styles/wijckprints/ckeieem5t4p4k19lzplaakzsm',
  kinfolk: 'mapbox://styles/wijckprints/ckeiedqqb4p4p19unwe77rxhx',
  hoxton: 'mapbox://styles/wijckprints/ckeied2nw1zdr19p88j2lyr6y',
  vogue: 'mapbox://styles/wijckprints/ckeiefbn83obi1apvv0v2iggi',
};

export const sizeList = [
  {
    id: 1, text: t('SizeExtras.input_small'), size: '30x40', sizeWithSpace: '30 x 40', price: '24.95', sizeName: 'A3',
  },
  {
    id: 2, text: t('SizeExtras.input_medium'), size: '40x50', sizeWithSpace: '40 x 50', price: '29.95', sizeName: 'X1',
  },
  {
    id: 3, text: t('SizeExtras.input_large'), size: '50x70', sizeWithSpace: '50 x 70', price: '39.95', sizeName: 'B2',
  },
  {
    id: 4,
    text: t('SizeExtras.input_extraLarge'),
    size: '70x100',
    sizeWithSpace: '70 x 100',
    price: '49.95',
    sizeName: 'B1',
  }
];

export const frameLists = {
  noFrame: {
    id: 1,
    name: 'noFrame',
    text: t('SizeExtras.input_noFrame'),
    price: {
      A4: '0.00',
      A3: '0.00',
      X1: '0.00',
      B2: '0.00',
      B1: '0.00',
    },
  },
  black: {
    id: 2,
    name: 'black',
    text: t('SizeExtras.input_black'),
    price: {
      A4: '2.00',
      A3: '25.00',
      X1: '39.00',
      B2: '49.00',
      B1: '69.00',
    },
  },
};


export const titleLeftSidebar = {
  title: {
    0: t('Location.title'),
    1: t('Customise.title'),
    2: t('SizeExtras.title'),
    3: t('AddToCart.title'),
  },
  subTitle: {
    0: t('Location.subTitle'),
    1: t('Customise.subTitle'),
    2: t('SizeExtras.subTitle'),
    3: t('AddToCart.subTitle')
  }
};

export const titleLeftSidebarB2B = {
  title: {
    0: t('Location.title'),
    1: t('Customise.title'),
    2: t('Save.title'),
  },
  subTitle: {
    0: t('Location.subTitle'),
    1: t('Customise.subTitle'),
    2: t('Save.subTitle'),
  }
};

export const nextStepButtonsText = {
  0: {
    text: t('Location.button_nextStep'),
  },
  1: {
    text: t('Customise.button_nextStep'),
  },
  2: {
    text: t('SizeExtras.button_nextStep'),
  },
  3: {
    text: t('AddToCart.button_nextStep'),
  },
};


// export const doubleBorderSize = {
//   B2: { inSide: 3, outSide: 6 },
//   B1: { inSide: 2.5, outSide: 5 },
//   X1: { inSide: 2, outSide: 4 },
//   A3: { inSide: 2, outSide: 4 },
// };

export const borderSize = {
  portrait: {
    B1: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    B2: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    X1: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    A3: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    }
  },
  landscape: {
    B1: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    B2: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    X1: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    },
    A3: {
      height: 2,
      width: 2,
      top: 4,
      left: 4,
      margin: 2
    }
  },
};
