import { useEffect } from 'react';

const useBlurInputs = () => {
  const inputBlur = () => {
    document.querySelectorAll('.js-customize-tab input:focus').forEach((item) => {
      item.blur();
    });
  };
  useEffect(() => {
    document.addEventListener('touchmove', inputBlur);
    return () => {
      document.removeEventListener('touchmove', inputBlur);
    };
  }, []);
};

export default useBlurInputs;
