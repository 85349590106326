import { LOCALES } from '../locales';

export default {
  [LOCALES.FRENCH]: {
    'Location.title': 'Choisissez votre emplacement',
    'Location.subTitle': 'Recherchez une ville, zoomez en avant ou en  arrière, ou naviguez direct ement dans la '
      + 'carte pour localiser votre lieu favori.',
    'Location.search_input': 'Trouvez votre lieu favori',
    'Location.label_randomCity': 'Tentex votre chance!',
    'Location.button_randomCity': 'Choisissez une ville aléatoire',
    'Location.label_bestsellers': 'Nos best-sellers!',
    'Location.button_nextStep': 'Étape suivante: Personnalisez',


    'Customise.title': 'Personnalisez votre impression',
    'Customise.subTitle': 'Personnalisez votre carte davantage! Écrivez votre propre texte ou message et'
      + ' déterminez l’orientation de votre impression',
    'Customise.label_orientation': 'Orientation',
    'Customise.input_portrait': 'Portrait',
    'Customise.input_landscape': 'Paysage',
    'Customise.label_style': 'Style',
    'Customise.label_text': 'Texte',
    'Customise.button_useCoordinatesText': 'Utiliser les coordonnées de la ville pour le titre',
    'Customise.button_nextStep': 'Étape suivante: Format & Options',


    'SizeExtras.title': 'Format & Options',
    'SizeExtras.subTitle': 'Sélectionnez le format désiré et complétez avec l’un de nos cadre de qualité',
    'SizeExtras.label_size': 'Format',
    'SizeExtras.input_small': 'Petit',
    'SizeExtras.input_medium': 'Moyen',
    'SizeExtras.input_large': 'Large',
    'SizeExtras.input_extraLarge': 'Extra Large',
    'SizeExtras.label_frame': 'Cadre',
    'SizeExtras.input_noFrame': 'Aucun cadre',
    'SizeExtras.input_black': 'Noir - cadre en bois',
    'SizeExtras.input_gold': 'Or - cadre en aluminium',
    'SizeExtras.button_nextStep': 'Étape suivante: Résultat et Prix',


    'AddToCart.title': 'Résultat & Prix',
    'AddToCart.subTitle': 'Voici votre résultat et prix définitifs. Pour commander, cliquez sur “Rajouter au panier”',
    'AddToCart.size': 'Format',
    'AddToCart.frame': 'Cadre',
    'AddToCart.price': 'Prix',
    'AddToCart.customise': 'Personnaliser la carte',
    'AddToCart.button_nextStep': 'Rajouter au panier - {price}',
    'AddToCart.button_nextStep1': 'Rajouter au panier',


    'Save.title': 'Sauvegardez votre impression',
    'Save.subTitle': 'Votre personnalisation est bientôt prête! Veuillez remplir ci-dessous votre nom et la description'
      + ' de votre commande. Cliquez sur le bouton pour sauvegarder votre impression.',
    'Save.details': 'Détails',
    'Save.customise': 'Personnalisation',
    'Save.name': 'Nom',
    'Save.description': 'Description',
    'Save.portrait': 'Portrait',
    'Save.landscape': 'Paysage',


    'LocationB2B.button_nextStep': 'Weiter: Anpassen',
    'CustomiseB2B.button_nextStep': 'Étape suivante: Sauvegardez l\'impression',
    'SaveB2B.button_nextStep': 'Sauvegarder maintenant',


    'Menu.location': 'Emplacement',
    'Menu.customise': 'Personnaliser',
    'Menu.sizeExtras': 'Format & Options',
    'Menu.addToCart': 'Ajouter',
    'Menu.save': 'Enregistrer',

  }
};
