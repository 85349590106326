export const MAX_ZOOM_LVL = 14;
export const MIN_ZOOM_LVL = 6;
export const SCROLL_SPEED_MULTIPLIER = 4;
export const MAX_SCROLL_STEP_MAP = 7;
export const MAX_MAP_SIZE = 2250;
export const MAX_MAP_SIZE_ON_PHONE = 1199;
export const LOGO_HEIGHT = 230;
export const TABLET_SIZE = 992;
export const SCREEN_PADDING = 15;
export const LEFT_SIDEBAR_WIDTH = 500;
