import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MapboxInfo from '../../context/MapboxContext';
import './priceTag.scss';

const PriceTag = ({ changeTab }) => {
  const { price } = useContext(MapboxInfo);
  return (
    <button
      className="priceTagButton"
      onClick={() => changeTab(3)}
    >
      <div className="priceTagContainer">
        <div className="priceTagContainer__image" />
        <p className="priceTagContainer__text">{`${price}`}</p>
      </div>
    </button>
  );
};

PriceTag.propTypes = {
  changeTab: PropTypes.func.isRequired
};

export default PriceTag;
