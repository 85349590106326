import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import MapboxInfo from '../../../context/MapboxContext';
import t from '../../../i18n/translate';
import { currencyTypes, sizeList, frameLists } from '../../../constants/general';

const LeftSidebarSizeExtras = ({
  getChoiceSize, activeSizeElement, getChoiceFrame, activeFrameElement
}) => {
  const { userLocation, renderCurrency } = useContext(MapboxInfo);
  const currencySymbol = renderCurrency(currencyTypes, userLocation);
  return (
    <div className="sizesExtras-block">
      <div className="marginTopSection">
        <h4 className="tab-name">{t('SizeExtras.label_size')}</h4>
        <ul className="sizesExtras-block__size__ul">
          {sizeList.map(({
            text, size, price, sizeName
          }, index) => (
            <li
              key={sizeName}
              tabIndex={index}
              className={cx({
                'sizesExtras-block__size__ul__li': true,
                'sizesExtras-block__size__ul__li-active': activeSizeElement === sizeName
              })}
              onClick={() => getChoiceSize(price, sizeName)}
              role="presentation"
            >
              <span className="sizesExtras-block__size__ul__li__text">
                {text}
                &nbsp;
                -
                &nbsp;
                {size}
                &nbsp;
                cm
              </span>
              <span className="sizesExtras-block__size__ul__li__text">
                {currencySymbol}
                  &nbsp;
                {price.replace('.', ',')}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <div className="marginTopSection">
        <h4 className="tab-name">{t('SizeExtras.label_frame')}</h4>
        <ul className="sizesExtras-block__frame__ul">
          {Object.values(frameLists).map(({ name, price, text }, index) => (
            <li
              key={name}
              className={cx({
                'sizesExtras-block__frame__ul__li': true,
                'sizesExtras-block__frame__ul__li-active': activeFrameElement === name
              })}
              onClick={() => getChoiceFrame(name, price[activeSizeElement])}
              role="presentation"
              tabIndex={index}
            >
              <span className="sizesExtras-block__frame__ul__li__text">{text}</span>
              <span className="sizesExtras-block__frame__ul__li__text">
                +
                &nbsp;
                {currencySymbol}
                  &nbsp;
                {price[activeSizeElement].replace('.', ',')}
              </span>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

LeftSidebarSizeExtras.propTypes = {
  activeFrameElement: PropTypes.string.isRequired,
  activeSizeElement: PropTypes.string.isRequired,
  getChoiceFrame: PropTypes.func.isRequired,
  getChoiceSize: PropTypes.func.isRequired,
};
export default LeftSidebarSizeExtras;
