const showGeolocationError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      // eslint-disable-next-line no-console
      console.log('User denied the request for Geolocation.');
      break;
    case error.POSITION_UNAVAILABLE:
      // eslint-disable-next-line no-console
      console.log('LeftSidebarLocation information is unavailable.');
      break;
    case error.TIMEOUT:
      // eslint-disable-next-line no-console
      console.log('The request to get user location timed out.');
      break;
    case error.UNKNOWN_ERROR:
      // eslint-disable-next-line no-console
      console.log('An unknown error occurred.');
      break;
    default:
  }
};

export default showGeolocationError;
