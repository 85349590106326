import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({
  text, className, onClick, refs, disable
}) => {
  return (
    <button
      className={className}
      onClick={disable ?  null :  onClick}
      tabIndex="0"
      type="button"
      ref={refs}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  refs: PropTypes.node,
  disable: PropTypes.bool
};

Button.defaultProps = {
  text: '',
  className: '',
  onClick: undefined,
  refs: undefined,
  disable: false
};

export default Button;
