// 100%·555 / 635 = 11100 /127 % ≈ 87.4015748031496%
// 555 + 14.4144144144% = 635
// 555 + 15% = 638.25
// 555 + 16.2162162162% = 645
export default {
  B1: { // 930/630 = 1.47619047619
    defaultHeight: 645, // 635 / 1.47619047619 = 430.161290323
    defaultWidth: 436.935483871,
    maxMapHeight: 1213.135, // 1316.52
    maxMapWidth: 821.801129033,
    mapScrollSpeedHeightPx: 24.202027027,
    mapScrollSpeedWidthPx: 16.3949215344
  },
  B2: { // 640/440 = 1.45454545455
    defaultHeight: 645, // 635 / 1.45454545455 = 436.562499999
    defaultWidth: 443.437499999,
    maxMapHeight: 1213.135,
    maxMapWidth: 834.030312497,
    mapScrollSpeedHeightPx: 24.202027027,
    mapScrollSpeedWidthPx: 16.638893581
  },
  X1: { // 450/350 = 1.28571428571
    defaultHeight: 645,
    defaultWidth: 501.666666668,
    maxMapHeight: 1213.135,
    maxMapWidth: 943.549444448,
    mapScrollSpeedHeightPx: 24.202027027,
    mapScrollSpeedWidthPx: 18.8237987988
  },
  A3: { // 360/260 = 1.38461538462
    defaultHeight: 645,
    defaultWidth: 465.833333332,
    maxMapHeight: 1213.135,
    maxMapWidth: 876.153055553,
    mapScrollSpeedHeightPx: 24.202027027,
    mapScrollSpeedWidthPx: 17.4792417417
  }
};


//
// export default {
//   B1: { // 930/630 = 1.47619047619
//     defaultHeight: 555, // 635 / 1.47619047619 = 430.161290323
//     defaultWidth: 375.967741936,
//     maxMapHeight: 1144.8,
//     maxMapWidth: 775.50967742,
//     mapScrollSpeedHeightPx: 20.825,
//     mapScrollSpeedWidthPx: 14.1072580645
//   },
//   B2: { // 640/440 = 1.45454545455
//     defaultHeight: 555, // 635 / 1.45454545455 = 436.562499999
//     defaultWidth: 381.562499999,
//     maxMapHeight: 1144.8,
//     maxMapWidth: 787.049999998,
//     mapScrollSpeedHeightPx: 20.825,
//
//     mapScrollSpeedWidthPx: 14.3171875
//   },
//   X1: { // 450/350 = 1.28571428571
//     defaultHeight: 555,
//     defaultWidth: 431.666666668,
//     maxMapHeight: 1144.8,
//     maxMapWidth: 890.400000003,
//     mapScrollSpeedHeightPx: 20.825,
//     mapScrollSpeedWidthPx: 16.1972222223,
//   },
//   A3: { // 360/260 = 1.38461538462
//     defaultHeight: 555,
//     defaultWidth: 400.833333332,
//     maxMapHeight: 1144.8,
//     maxMapWidth: 826.799999997,
//     mapScrollSpeedHeightPx: 20.825,
//     mapScrollSpeedWidthPx: 15.0402777777
//   },
// };
