import React from 'react';
import PropTypes from 'prop-types';
import './MapSignatureOnMap.scss';
import { isDesktopSize } from '../../utils/general';

const MapSignatureOnMap = ({
  orientation, coordinate, city, country, size, margin
}) => {
  const mobileMargin = isDesktopSize() ? 0 : 1;
  return (
    <div
      style={{
        width: `calc(100% - ${(margin + mobileMargin) * 2}px)`,
        margin: `0 ${margin + mobileMargin}px`
      }}
      className={`MapSignatureOnMap MapSignatureOnMap__${orientation} MapSignatureOnMap-${orientation}-${size}`}
    >
      <span className={`MapSignatureOnMap__${orientation}__coordinate`}>
        <svg height="11" width="500">
          <text className="countryName" textAnchor="middle" x="250" y="8">{coordinate}</text>
        </svg>
      </span>
      <span className={`MapSignatureOnMap__${orientation}__cityName`}>{city && city.toUpperCase()}</span>
      <span className={`MapSignatureOnMap__${orientation}__countryName`}>
        <svg height="14" width="500">
          <text className="countryName" textAnchor="middle" x="250" y="11">{country}</text>
        </svg>
      </span>
      <div className="marginBottom" />
    </div>
  );
};

MapSignatureOnMap.propTypes = {
  margin: PropTypes.number.isRequired,
  city: PropTypes.string,
  coordinate: PropTypes.string,
  country: PropTypes.string,
  orientation: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

MapSignatureOnMap.defaultProps = {
  city: '',
  coordinate: '',
  country: '',
};

export default MapSignatureOnMap;
