import portraitSize from '../constants/portraitSize';
import landscapeSize from '../constants/landscapeSize';
import portraitSizeWijck from '../constants/portraitSizeWijck';
import landscapeSizeWijck from '../constants/landscapeSizeWijck';
import { SCROLL_SPEED_MULTIPLIER } from '../constants/mapConstants';
import { PORTRAIT } from '../constants/general';
import { isWijck } from './general';

const getMapSizeWithScrollStep = (scrollStep, size, orientation, mapStyleName) => {
  const portrait = isWijck(mapStyleName) ? portraitSizeWijck : portraitSize;
  const landscape = isWijck(mapStyleName) ? landscapeSizeWijck : landscapeSize;
  const mapSize = orientation === PORTRAIT ? portrait : landscape;
  const mapWidth = mapSize[size].defaultWidth + ((scrollStep - 1)
        * (mapSize[size].mapScrollSpeedWidthPx * SCROLL_SPEED_MULTIPLIER));
  const mapHeight = mapSize[size].defaultHeight + ((scrollStep - 1)
        * (mapSize[size].mapScrollSpeedHeightPx * SCROLL_SPEED_MULTIPLIER));
  return { mapHeight, mapWidth };
};

export default getMapSizeWithScrollStep;
