export default [
  {
    en: 'Abu Dhabi',
    fr: 'Abou Dabi',
    de: 'Abu Dhabi',
    nl: 'Abu Dhabi',
    country: 'United Arab Emirates',
    latitude: '24.47',
    longitude: '54.37',
    total: '24.47°N 54.37°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Amsterdam',
    fr: 'Amsterdam',
    de: 'Amsterdam',
    nl: 'Amsterdam',
    country: 'The Netherlands',
    latitude: '52.38',
    longitude: '4.90',
    total: '52.38°N 4.90°E',
    zoom: 11,
    scrollStep: 2,
    mobileZoom: 11.714
  },
  {
    en: 'Athens',
    fr: 'Athènes',
    de: 'Athens',
    nl: 'Athene',
    country: 'United States of America',
    latitude: '37.99',
    longitude: '23.72',
    total: '37.99°N 23.72°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Auckland',
    fr: 'Auckland',
    de: 'Auckland',
    nl: 'Auckland',
    country: 'New Zealand',
    latitude: '-36.87',
    longitude: '174.82',
    total: '-36.87°N 174.82°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Austin',
    fr: 'Austin',
    de: 'Austin',
    nl: 'Austin',
    country: 'United States of America',
    latitude: '30.30',
    longitude: '-97.74',
    total: '30.30°N -97.74°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Baku',
    fr: 'Bakou',
    de: 'Baku',
    nl: 'Bakoe',
    country: 'Azerbaijan',
    latitude: '40.39',
    longitude: '49.85',
    total: '40.39°N 49.85°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Bangkok',
    fr: 'Bangkok',
    de: 'Bangkok',
    nl: 'Bangkok',
    country: 'Thailand',
    latitude: '13.75',
    longitude: '100.52',
    total: '13.75°N 100.52°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'Barcelona',
    fr: 'Barcelona',
    de: 'Barcelona',
    nl: 'Barcelona',
    country: 'Spain',
    latitude: '41.39',
    longitude: '2.15',
    total: '41.39°N 2.15°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Beijing',
    fr: 'Pékin',
    de: 'Beijing',
    nl: 'Beijing',
    country: "People's Republic of China",
    latitude: '39.92',
    longitude: '116.38',
    total: '39.92°N 116.38°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Belgrade',
    fr: 'Belgrade',
    de: 'Belgrade',
    nl: 'Belgrade',
    country: 'Serbia',
    latitude: '44.82',
    longitude: '20.46',
    total: '44.82°N 20.46°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Berlin',
    fr: 'Berlin',
    de: 'Berlin',
    nl: 'Berlijn',
    country: 'Germany',
    latitude: '52.52',
    longitude: '13.40',
    total: '52.52°N 13.40°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Bogotá',
    fr: 'Bogotá',
    de: 'Bogotá',
    nl: 'Bogotá',
    country: 'Colombia',
    latitude: '4.62',
    longitude: '-74.10',
    total: '4.62°N -74.10°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Brussels',
    nl: 'Brussel',
    de: 'Brüssel',
    fr: 'Bruxelles',
    country: 'Belgium',
    latitude: '50.85',
    longitude: '4.36',
    total: '50.85°N 4.36°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'Bucharest',
    nl: 'Boekarest',
    de: 'Bukarest',
    fr: 'Bucarest',
    country: 'Romania',
    latitude: '44.38',
    longitude: '26.10',
    total: '44.43°N 26.10°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Budapest',
    nl: 'Boedapest',
    de: 'Budapest',
    fr: 'Budapest',
    country: 'Hungary',
    latitude: '47.50',
    longitude: '19.06',
    total: '47.50°N 19.06°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Cairo',
    nl: 'Cairo',
    de: 'Cairo',
    fr: 'Le Caire',
    country: 'Egypt',
    latitude: '30.05',
    longitude: '31.22',
    total: '30.05°N 31.22°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Calgary',
    nl: 'Calgary',
    de: 'Calgary',
    fr: 'Calgary',
    country: 'Canada',
    latitude: '51.04',
    longitude: '-114.06',
    total: '51.04°N -114.06°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Cape Town',
    nl: 'Kaapstad',
    de: 'Kapstadt',
    fr: 'Le Cap',
    country: 'South Africa',
    latitude: '-34.00',
    longitude: '18.43',
    total: '-34.00°N 18.43°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Casablanca',
    nl: 'Casablanca',
    de: 'Casablanca',
    fr: 'Casablanca',
    country: 'Morocco',
    latitude: '33.57',
    longitude: '-7.62',
    total: '33.57°N -7.62°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Charlotte',
    nl: 'Charlotte',
    de: 'Charlotte',
    fr: 'Charlotte',
    country: 'United States of America',
    latitude: '35.24',
    longitude: '-80.84',
    total: '35.24°N -80.84°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    en: 'Chicago',
    nl: 'Chicago',
    de: 'Chicago',
    fr: 'Chicago',
    country: 'United States of America',
    latitude: '41.88',
    longitude: '-87.64',
    total: '41.88°N -87.64°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Copenhagen',
    nl: 'Kopenhagen',
    de: 'Copenhagen',
    fr: 'copenhague',
    country: 'Denmark',
    latitude: '55.68',
    longitude: '12.59',
    total: '55.68°N 12.59°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Dallas',
    nl: 'Dallas',
    de: 'Dallas',
    fr: 'Dallas',
    country: 'United States of America',
    latitude: '32.79',
    longitude: '-96.80',
    total: '32.79°N -96.80°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Delhi',
    nl: 'Delhi',
    de: 'Delhi',
    fr: 'Delhi',
    country: 'India',
    latitude: '28.63',
    longitude: '77.22',
    total: '28.63°N 77.22°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Dubai',
    nl: 'Dubai',
    de: 'Dubai',
    fr: 'dubaï',
    country: 'United Arab Emirates',
    latitude: '25.20',
    longitude: '55.24',
    total: '25.20°N 55.24°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Dublin',
    nl: 'Dublin',
    de: 'Dublin',
    fr: 'Dublin',
    country: 'Ireland',
    latitude: '53.34',
    longitude: '-6.28',
    total: '53.34°N -6.28°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Edinburgh',
    nl: 'Edinburgh',
    de: 'Edinburgh',
    fr: 'Édimbourg',
    country: 'United Kingdom',
    latitude: '55.95',
    longitude: '-3.20',
    total: '55.95°N -3.20°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'El Paso',
    nl: 'El Paso',
    de: 'El Paso',
    fr: 'El Paso',
    country: 'United States of America',
    latitude: '31.75',
    longitude: '-106.42',
    total: '31.75°N -106.42°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Frankfurt',
    nl: 'Frankfurt',
    de: 'Frankfurt',
    fr: 'Francfort-sur-le-Main',
    country: 'Germany',
    latitude: '50.11',
    longitude: '8.67',
    total: '50.11°N 8.67°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    en: 'Florence',
    nl: 'Florence',
    de: 'Florenz',
    fr: 'Florence',
    country: 'Italy',
    latitude: '43.77',
    longitude: '11.26',
    total: '43.77°N 11.26°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Granada',
    nl: 'Granada',
    de: 'Granada',
    fr: 'Granade',
    country: 'Spain',
    latitude: '37.18',
    longitude: '-3.60',
    total: '37.18°N -3.60°E',
    zoom: '13',
    scrollStep: '3',
    mobileZoom: 13.571
  },
  {
    en: 'Guatemala City',
    nl: 'Guatemala City',
    de: 'Guatemala City',
    fr: 'Guatemala',
    country: 'Guatemala',
    latitude: '14.62',
    longitude: '-90.52',
    total: '14.62°N -90.52°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Hamburg',
    nl: 'Hamburg',
    de: 'Hamburg',
    fr: 'Hambourg',
    country: 'Germany',
    latitude: '53.55',
    longitude: '10.01',
    total: '53.55°N 10.01°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Havana',
    nl: 'Havana',
    de: 'Havana',
    fr: 'Havana',
    country: 'United States of America',
    latitude: '23.11',
    longitude: '-82.40',
    total: '23.11°N -82.40°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Helsinki',
    nl: 'Helsinki',
    de: 'Helsinki',
    fr: 'Helsinki',
    country: 'Finland',
    latitude: '60.16',
    longitude: '24.95',
    total: '60.16°N 24.95°E',
    zoom: '12',
    scrollStep: '2',
    mobileZoom: 12.714
  },
  {
    en: 'Hong Kong',
    nl: 'Hong Kong',
    de: 'Hong Kong',
    fr: 'Hong Kong',
    country: 'Hong Kong',
    latitude: '22.30',
    longitude: '114.18',
    total: '22.30°N 114.18°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Honolulu',
    nl: 'Honolulu',
    de: 'Honolulu',
    fr: 'Honolulu',
    country: 'United States of America',
    latitude: '21.34',
    longitude: '-157.86',
    total: '21.34°N -157.86°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Houston',
    nl: 'Houston',
    de: 'Houston',
    fr: 'Houston',
    country: 'United States of America',
    latitude: '29.75',
    longitude: '-95.37',
    total: '29.75°N -95.37°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Istanbul',
    nl: 'Istanbul',
    de: 'Istanbul',
    fr: 'Istanbul',
    country: 'Turkey',
    latitude: '41.05',
    longitude: '29.01',
    total: '41.05°N 29.01°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Jakarta',
    nl: 'Jakarta',
    de: 'Jakarta',
    fr: 'Jakarta',
    country: 'Indonesia',
    latitude: '-6.17',
    longitude: '106.80',
    total: '-6.17°N 106.80°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Johannesburg',
    nl: 'Johannesburg',
    de: 'Johannesburg',
    fr: 'Johannesburg',
    country: 'South Africa',
    latitude: '-6.19',
    longitude: '106.81',
    total: '-6.19°N 106.81°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Kingston',
    nl: 'Kingston',
    de: 'Kingston',
    fr: 'Kingston',
    country: 'United Kingdom',
    latitude: '18.02',
    longitude: '-76.80',
    total: '18.02°N -76.80°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Kuala Lumpur',
    nl: 'Kuala Lumpur',
    de: 'Kuala Lumpur',
    fr: 'Kuala Lumpur',
    country: 'Malaysia',
    latitude: '3.13',
    longitude: '101.71',
    total: '3.13°N 101.71°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Kyiv',
    nl: 'Kiev',
    de: 'kiew',
    fr: 'kiev',
    country: 'Ukraine',
    latitude: '50.45',
    longitude: '30.52',
    total: '50.45°N 30.52°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'La Paz',
    nl: 'La Paz',
    de: 'La Paz',
    fr: 'La Paz',
    country: 'Bolivia',
    latitude: '-16.49',
    longitude: '-68.14',
    total: '-16.49°N -68.14°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Las Vegas',
    nl: 'Las Vegas',
    de: 'Las Vegas',
    fr: 'Las Vegas',
    country: 'United States of America',
    latitude: '36.16',
    longitude: '-115.15',
    total: '36.16°N -115.15°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'Lisbon',
    nl: 'Lissabon',
    de: 'Lissabon',
    fr: 'Lisbonne',
    country: 'Portugal',
    latitude: '38.72',
    longitude: '-9.15',
    total: '38.72°N -9.15°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    en: 'Liverpool',
    nl: 'Liverpool',
    de: 'Liverpool',
    fr: 'Liverpool',
    country: 'United Kingdom',
    latitude: '53.421',
    longitude: '-3.00',
    total: '53.42°N -3.00°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'London',
    nl: 'Londen',
    de: 'London',
    fr: 'Londres',
    country: 'United Kingdom',
    latitude: '51.51',
    longitude: '-0.11',
    total: '51.51°N -0.11°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Los Angeles',
    nl: 'Los Angeles',
    de: 'Los Angeles',
    fr: 'Los Angeles',
    country: 'United States of America',
    latitude: '34.05',
    longitude: '-118.24',
    total: '34.05°N -118.24°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Madrid',
    nl: 'Madrid',
    de: 'Madrid',
    fr: 'Madrid',
    country: 'Spain',
    latitude: '40.43',
    longitude: '-3.69',
    total: '40.43°N -3.69°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Málaga',
    nl: 'Málaga',
    de: 'Málaga',
    fr: 'Málaga',
    country: 'Spain',
    latitude: '36.72',
    longitude: '-4.42',
    total: '36.72°N -4.42°E',
    zoom: '12',
    scrollStep: '3',
    mobileZoom: 12.571
  },
  {
    en: 'Manchester',
    nl: 'Manchester',
    de: 'Manchester',
    fr: 'Manchester',
    country: 'United Kingdom',
    latitude: '53.48',
    longitude: '-2.24',
    total: '53.48°N -2.24°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    en: 'Mecca',
    nl: 'Mekka',
    de: 'mekka',
    fr: 'La Mecque',
    country: 'Saudi Arabia',
    latitude: '21.41',
    longitude: '39.82',
    total: '21.41°N 39.82°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Melbourne',
    nl: 'Melbourne',
    de: 'Melbourne',
    fr: 'Melbourne',
    country: 'Australia',
    latitude: '-37.81',
    longitude: '144.95',
    total: '-37.81°N 144.95°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Mexico City',
    nl: 'Mexico-stad',
    de: 'Mexiko-Stadt',
    fr: 'Mexico',
    country: 'Mexico',
    latitude: '19.42',
    longitude: '-99.13',
    total: '19.42°N -99.13°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Miami',
    nl: 'Miami',
    de: 'Miami',
    fr: 'Miami',
    country: 'United States of America',
    latitude: '25.79',
    longitude: '-80.19',
    total: '25.79°N -80.19°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    en: 'Milan',
    nl: 'Milan',
    de: 'Milan',
    fr: 'Milan',
    country: 'Italy',
    latitude: '45.47',
    longitude: '9.18',
    total: '45.47°N 9.18°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    en: 'Minsk',
    nl: 'Minsk',
    de: 'Minsk',
    fr: 'Minsk',
    country: 'Belarus',
    latitude: '53.91',
    longitude: '27.56',
    total: '53.91°N 27.56°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Monaco',
    nl: 'Monaco',
    de: 'Monaco',
    fr: 'Monaco',
    country: 'Monaco',
    latitude: '43.73',
    longitude: '7.42',
    total: '43.73°N 7.42°E',
    zoom: '14',
    scrollStep: '5',
    mobileZoom: 14.285714286
  },
  {
    en: 'Montreal',
    nl: 'Montreal',
    de: 'Montreal',
    fr: 'Montreal',
    country: 'Canada',
    latitude: '45.50',
    longitude: '-73.58',
    total: '45.50°N -73.58°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Moscow',
    nl: 'Moskou',
    de: 'Moskau',
    fr: 'Moscou',
    country: 'Russia',
    latitude: '55.75',
    longitude: '37.62',
    total: '55.75°N 37.62°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Mumbai',
    nl: 'Mumbai',
    de: 'Mumbai',
    fr: 'Mumbai',
    country: 'India',
    latitude: '19.02',
    longitude: '72.85',
    total: '19.02°N 72.85°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Munich',
    nl: 'München',
    de: 'München',
    fr: 'Munich',
    country: 'Germany',
    latitude: '48.14',
    longitude: '11.58',
    total: '48.14°N 11.58°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Nashville',
    nl: 'Nashville',
    de: 'Nashville',
    fr: 'Nashville',
    country: 'United States of America',
    latitude: '36.17',
    longitude: '-86.78',
    total: '36.17°N -86.78°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'New Orleans',
    nl: 'New Orleans',
    de: 'New Orleans',
    fr: 'New Orleans',
    country: 'United States of America',
    latitude: '29.98',
    longitude: '-90.08',
    total: '29.98°N -90.08°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'New York',
    nl: 'New York',
    de: 'New York',
    fr: 'New York',
    country: 'United States of America',
    latitude: '40.77',
    longitude: '-73.98',
    total: '40.77°N -73.98°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'New York City',
    nl: 'New York City',
    de: 'New York City',
    fr: 'New York City',
    country: 'United States of America',
    latitude: '40.77',
    longitude: '-73.98',
    total: '40.77°N -73.98°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286,
    cityName: 'New York'
  },
  {
    en: 'Osaka',
    nl: 'Osaka',
    de: 'Osaka',
    fr: 'Osaka',
    country: 'Japan',
    latitude: '34.69',
    longitude: '135.50',
    total: '34.69°N 135.50°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Oslo',
    nl: 'Oslo',
    de: 'Oslo',
    fr: 'Oslo',
    country: 'Norway',
    latitude: '59.92',
    longitude: '10.75',
    total: '59.92°N 10.75°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'Panama City',
    nl: 'Panama-stad',
    de: 'Oslo',
    fr: 'Panama',
    country: 'Panama',
    latitude: '9.02',
    longitude: '-79.51',
    total: '9.02°N -79.51°E',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857
  },
  {
    en: 'Paramaribo',
    nl: 'Paramaribo',
    de: 'Paramaribo',
    fr: 'Paramaribo',
    country: 'Suriname',
    latitude: '5.85',
    longitude: '-55.15',
    total: '5.85°N -55.15°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Paris',
    nl: 'Parijs',
    de: 'Paris',
    fr: 'Paris',
    country: 'France',
    latitude: '48.86',
    longitude: '2.34',
    total: '48.86°N 2.34°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Perth',
    nl: 'Perth',
    de: 'Perth',
    fr: 'Perth',
    country: 'Australia',
    latitude: '-32.00',
    longitude: '115.82',
    total: '-32.00°N 115.82°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Philadelphia',
    nl: 'Philadelphia',
    de: 'Philadelphia',
    fr: 'Philadelphia',
    country: 'United States of America',
    latitude: '39.96',
    longitude: '-75.13',
    total: '39.96°N -75.13°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Phoenix',
    nl: 'Phoenix',
    de: 'Phoenix',
    fr: 'Phoenix',
    country: 'United States of America',
    latitude: '33.46',
    longitude: '-112.05',
    total: '33.46°N -112.05°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Portland',
    nl: 'Portland',
    de: 'Portland',
    fr: 'Portland',
    country: 'United States of America',
    latitude: '45.53',
    longitude: '-122.67',
    total: '45.53°N -122.67°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Porto',
    nl: 'Porto',
    de: 'Porto',
    fr: 'Porto',
    country: 'Brazil',
    latitude: '41.15',
    longitude: '-8.62',
    total: '41.15°N -8.62°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Prague',
    nl: 'Praag',
    de: 'Prag',
    fr: 'Prague',
    country: 'Czech Republic',
    latitude: '50.08',
    longitude: '14.44',
    total: '50.08°N 14.44°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Quito',
    nl: 'Quito',
    de: 'Quito',
    fr: 'Quito',
    country: 'Ecuador',
    latitude: '-0.22',
    longitude: '-78.50',
    total: '-0.22°N -78.50°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Reykjavik',
    nl: 'Reykjavik',
    de: 'Reykjavik',
    fr: 'Reykjavik',
    country: 'Iceland',
    latitude: '64.13',
    longitude: '-21.87',
    total: '64.13°N -21.87°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Riga',
    nl: 'Riga',
    de: 'Riga',
    fr: 'Riga',
    country: 'Latvia',
    latitude: '56.94',
    longitude: '24.10',
    total: '56.94°N 24.10°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'Rio de Janeiro',
    nl: 'Rio de Janeiro',
    de: 'Rio de Janeiro',
    fr: 'Rio de Janeiro',
    country: 'Brazil',
    latitude: '-22.93',
    longitude: '-43.20',
    total: '-22.93°N -43.20°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Rome',
    nl: 'Rome',
    de: 'Rom',
    fr: 'Rome',
    country: 'Italy',
    latitude: '41.90',
    longitude: '12.50',
    total: '41.90°N 12.50°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Saint Petersburg',
    nl: 'Sint-Petersburg',
    de: 'Sankt Petersburg',
    fr: 'Saint-Pétersbourg',
    country: 'Russia',
    latitude: '59.94',
    longitude: '30.31',
    total: '59.94°N 30.31°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Salvador',
    nl: 'Salvador',
    de: 'Salvador',
    fr: 'Salvador',
    country: 'Brazil',
    latitude: '-12.95',
    longitude: '-38.47',
    total: '-12.95°N -38.47°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'San Diego',
    nl: 'San Diego',
    de: 'San Diego',
    fr: 'San Diego',
    country: 'United States of America',
    latitude: '32.79',
    longitude: '-117.15',
    total: '32.79°N -117.15°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'San Francisco',
    nl: 'San Francisco',
    de: 'San Francisco',
    fr: 'San Francisco',
    country: 'United States of America',
    latitude: '37.77',
    longitude: '-122.44',
    total: '37.77°N -122.44°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
  {
    en: 'San José',
    nl: 'San José',
    de: 'San José',
    fr: 'San José',
    country: 'Costa Rica',
    latitude: '9.93',
    longitude: '-84.09',
    total: '9.93°N -84.09°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: 12.428
  },
  {
    en: 'Santiago',
    nl: 'Santiago',
    de: 'Santiago',
    fr: 'Santiago',
    country: 'Chile',
    latitude: '-33.46',
    longitude: '-70.67',
    total: '-33.46°N -70.67°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'São Paulo',
    nl: 'São Paulo',
    de: 'São Paulo',
    fr: 'São Paulo',
    country: 'Brazil',
    latitude: '-23.55',
    longitude: '-46.64',
    total: '-23.55°N -46.64°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Seattle',
    nl: 'Seattle',
    de: 'Seattle',
    fr: 'Seattle',
    country: 'United States of America',
    latitude: '47.60',
    longitude: '-122.33',
    total: '47.60°N -122.33°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Seoul',
    nl: 'Seoul',
    de: 'Seoul',
    fr: 'Seoul',
    country: 'South Korea',
    latitude: '37.57',
    longitude: '126.98',
    total: '37.57°N 126.98°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Seville',
    nl: 'Sevilla',
    de: 'Sevilla',
    fr: 'Séville',
    country: 'South Korea',
    latitude: '37.39',
    longitude: '-5.98',
    total: '37.39°N -5.98°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Shanghai',
    nl: 'Shanghai',
    de: 'Shanghai',
    fr: 'Shanghai',
    country: "People's Republic of China",
    latitude: '31.23',
    longitude: '121.49',
    total: '31.23°N 121.49°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Singapore',
    nl: 'Singapore',
    de: 'Singapore',
    fr: 'Singapore',
    country: 'Singapore',
    latitude: '1.36',
    longitude: '103.83',
    total: '1.36°N 103.83°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: 11
  },
  {
    en: 'Sofia',
    nl: 'Sofia',
    de: 'Sofia',
    fr: 'Sofia',
    country: 'Bulgaria',
    latitude: '42.7',
    longitude: '23.33',
    total: '42.70°N 23.33°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Stockholm',
    nl: 'Stockholm',
    de: 'Stockholm',
    fr: 'Stockholm',
    country: 'Sweden',
    latitude: '59.33',
    longitude: '18.07',
    total: '59.33°N 18.07°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Sydney',
    nl: 'Sydney',
    de: 'Sydney',
    fr: 'Sydney',
    country: 'Australia',
    latitude: '-33.86',
    longitude: '151.17',
    total: '-33.86°N 151.17°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: 11.142
  },
  {
    en: 'Taipei',
    nl: 'Taipei',
    de: 'Taipei',
    fr: 'Taipei',
    country: 'Taiwan',
    latitude: '25.07',
    longitude: '121.51',
    total: '25.07°N 121.51°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Tokyo',
    nl: 'Tokio',
    de: 'Präfektur Tokio',
    fr: 'Tokyo',
    country: 'Japan',
    latitude: '35.68',
    longitude: '139.80',
    total: '35.68°N 139.80°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },

  {
    en: 'Toronto',
    nl: 'Toronto',
    de: 'Toronto',
    fr: 'Toronto',
    country: 'Canada',
    latitude: '43.69',
    longitude: '-79.37',
    total: '43.69°N -79.37°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'Valencia',
    nl: 'Valencia',
    de: 'Valencia',
    fr: 'Valence',
    country: 'Spain',
    latitude: '39.47',
    longitude: '-0.38',
    total: '39.47°N -0.38°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Vancouver',
    nl: 'Vancouver',
    de: 'Vancouver',
    fr: 'Vancouver',
    country: 'Canada',
    latitude: '49.27',
    longitude: '-123.10',
    total: '49.27°N -123.10°E',
    zoom: '11',
    scrollStep: '3',
    mobileZoom: 11.571
  },
  {
    en: 'Vienna',
    nl: 'Wenen',
    de: 'Wien',
    fr: 'Vienne',
    country: 'Lithuania',
    latitude: '48.22',
    longitude: '16.37',
    total: '48.22°N 16.37°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Vilnus',
    nl: 'Vilnus',
    de: 'Vilnus',
    fr: 'Vilnus',
    country: 'Lithuania',
    latitude: '54.68',
    longitude: '25.27',
    total: '54.68°N 25.27°E',
    zoom: '12',
    scrollStep: '6',
    mobileZoom: 12.142
  },
  {
    en: 'Warsaw',
    nl: 'Warschau',
    de: 'Warschau',
    fr: 'Varsovie',
    country: 'Poland',
    latitude: '52.23',
    longitude: '21.03',
    total: '52.23°N 21.03°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428,
  },
  {
    en: 'Washington',
    nl: 'Washington',
    de: 'Washington',
    fr: 'Washington',
    country: 'United States of America',
    latitude: '38.90',
    longitude: '-77.02',
    total: '38.90°N -77.02°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: 12
  },
  {
    en: 'Zagreb',
    nl: 'Zagreb',
    de: 'Zagreb',
    fr: 'Zagreb',
    country: 'Croatia',
    latitude: '15.96',
    longitude: '48.81',
    total: '45.81°N 15.96°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: 11.285714286
  },
  {
    en: 'Zurich',
    nl: 'Zürich',
    de: 'Zürich',
    fr: 'Zurich',
    country: 'Switzerland',
    latitude: '47.39',
    longitude: '8.53',
    total: '47.39°N 8.53°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: 12.285714286
  },
  {
    en: 'Buenos Aires',
    nl: 'Buenos Aires',
    de: 'Buenos Aires',
    fr: 'Buenos Aires',
    country: 'Argentina',
    latitude: '-34.6',
    longitude: '-58.44',
    total: '-58.44°E -34.61°N ',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    en: 'comuna 1',
    nl: 'comuna 1',
    de: 'comuna 1',
    fr: 'comuna 1',
    country: 'Argentina',
    latitude: '-34.6',
    longitude: '-58.44',
    total: '-58.44°E -34.61°N',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428,
    cityName: 'Buenos Aires'
  },
  {
    en: 'Houten',
    nl: 'Houten',
    de: 'Houten',
    fr: 'Houten',
    country: 'Netherlands',
    latitude: '52.03',
    longitude: '5.16',
    total: '52.03°E -5.16N',
    zoom: '11',
    scrollStep: '1',
    mobileZoom: 11.857,
    cityName: 'Houten'
  },
  {
    en: 'Kyoto',
    nl: 'Kyoto',
    de: 'Kyoto',
    fr: 'Kyoto',
    country: 'Japan',
    latitude: '35.01',
    longitude: '135.77',
    total: '35.01°N 135.77°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: 11.714
  },
];
