import React from 'react';
import PropTypes from 'prop-types';
import './thumbnail.scss';
import cx from 'classnames';
import ThumbnailBorder from './components/ThumbnailBorder';
import { isWijck } from '../../utils/general';

const Thumbnail = ({
  frame,
  currentMapOrientation,
  cloneImgSrc,
  coordinate,
  city,
  country,
  mapStyleName
}) => {
  const isWijckStyle = isWijck(mapStyleName);
  return (
    <div
      className={cx({
        'add-to-cart__frame-block': true,
        [`add-to-cart__frame-block__${frame}`]: true,
        [`add-to-cart__frame-block__${frame}-${currentMapOrientation}`]: true,
        [`add-to-cart__frame-block__${frame}-${currentMapOrientation}__${mapStyleName}`]: isWijckStyle
      })}
    >
      {isWijckStyle && <ThumbnailBorder currentMapOrientation={currentMapOrientation} frame={frame} />}
      <div className={cx({
        'add-to-cart__frame-block__image': !isWijckStyle,
        [`add-to-cart__frame-block__image-${mapStyleName}-${frame}`]: isWijckStyle
      })}
      >
        <img alt="miniMap" src={cloneImgSrc} className="miniMap" />
      </div>

      <div className={`
      ${isWijckStyle // blur
        ? `add-to-cart__textContainer__${currentMapOrientation}
         add-to-cart__textContainer__${currentMapOrientation}__${frame}` 
        : 'add-to-cart__textContainerDefault'}`}
      >
        <span className="add-to-cart__frame-block__coordinate">
          <svg height="2" width="50">
            <text textAnchor="middle" x="25" y="1.8">{coordinate}</text>
          </svg>
        </span>
        <span className="add-to-cart__frame-block__city">
          <svg height="7" width="50">
            <text textAnchor="middle" x="25" y="6">{city && city.toUpperCase()}</text>
          </svg>
        </span>
        <span className="add-to-cart__frame-block__country">
          <svg height="3" width="50">
            <text textAnchor="middle" x="25" y="2">{country}</text>
          </svg>
        </span>

        {!isWijckStyle && (
        <span className="add-to-cart__frame-block__trademark">
          <svg height="3" width="50">
            <text textAnchor="middle" x="24.5" y="1.3">WIJCK.</text>
          </svg>
        </span>
        ) }
      </div>
    </div>
  );
};

Thumbnail.propTypes = {
  mapStyleName: PropTypes.string.isRequired,
  frame: PropTypes.string.isRequired,
  currentMapOrientation: PropTypes.string.isRequired,
  cloneImgSrc: PropTypes.string.isRequired,
  coordinate: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired
};

export default Thumbnail;
