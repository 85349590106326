export default [
  {
    city: 'Amsterdam',
    latitude: '52.38',
    longitude: '4.90',
    total: '52.38°N 4.90°E',
    zoom: 11,
    scrollStep: 2,
    mobileZoom: 11.714
  },
  {
    city: 'London',
    latitude: '51.51',
    longitude: '-0.11',
    total: '51.51°N -0.11°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428
  },
  {
    city: 'Paris',
    latitude: '48.86',
    longitude: '2.34',
    total: '48.86°N 2.34°E',
    zoom: '11',
    scrollStep: '4',
    mobileZoom: 11.428,
  },
  {
    city: 'New York',
    latitude: '40.77',
    longitude: '-73.98',
    total: '40.77°N -73.98°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: '11.285714286'
  },
  {
    city: 'Stockholm',
    latitude: '59.33',
    longitude: '18.07',
    total: '59.33°N 18.07°E',
    zoom: '12',
    scrollStep: '5',
    mobileZoom: '12.285714286'
  },
  {
    city: 'Tokyo',
    latitude: '35.68',
    longitude: '139.80',
    total: '35.68°N 139.80°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: '12',
  },
  {
    city: 'Copenhagen',
    latitude: '55.68',
    longitude: '12.59',
    total: '55.68°N 12.59°E',
    zoom: '11',
    scrollStep: '5',
    mobileZoom: '11.285714286',
  },
  {
    city: 'Vienna',
    latitude: '48.22',
    longitude: '16.37',
    total: '48.22°N 16.37°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: '12'
  },
  {
    city: 'Rome',
    latitude: '41.90',
    longitude: '12.50',
    total: '41.90°N 12.50°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: '11.142'
  },
  {
    city: 'Las Vegas',
    latitude: '36.16',
    longitude: '-115.15',
    total: '36.16°N -115.15°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: '11.714'
  },
  {
    city: 'Los Angeles',
    latitude: '34.05',
    longitude: '-118.24',
    total: '34.05°N -118.24°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: '11'
  },
  {
    city: 'San Francisco',
    latitude: '37.77',
    longitude: '-122.44',
    total: '37.77°N -122.44°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: '11.714'
  },
  {
    city: 'Singapore',
    latitude: '1.36',
    longitude: '103.83',
    total: '1.36°N 103.83°E',
    zoom: '11',
    scrollStep: '7',
    mobileZoom: '11'
  },
  {
    city: 'Kyoto',
    latitude: '35.01',
    longitude: '135.77',
    total: '35.01°N 135.77°E',
    zoom: '11',
    scrollStep: '2',
    mobileZoom: '11.714'
  },
  {
    city: 'Osaka',
    latitude: '34.69',
    longitude: '135.50',
    total: '34.69°N 135.50°E',
    zoom: '12',
    scrollStep: '7',
    mobileZoom: '12'
  },
  {
    city: 'Sydney',
    latitude: '-33.86',
    longitude: '151.17',
    total: '-33.86°N 151.17°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: '11.142',
  },
  {
    city: 'Berlin',
    latitude: '52.52',
    longitude: '13.40',
    total: '52.52°N 13.40°E',
    zoom: '11',
    scrollStep: '6',
    mobileZoom: '11.142'
  },
  {
    city: 'Milan',
    latitude: '45.47',
    longitude: '9.18',
    total: '45.47°N 9.18°E',
    zoom: '12',
    scrollStep: '4',
    mobileZoom: '12.428'
  }
];
