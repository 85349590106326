import goldFramePortrait from '../assets/frame/frame_gold.png';
import goldFrameLandscape from '../assets/frame/frame_gold_landscape.png';
import backgroundGradient from '../assets/overlay/portrait/B2.png';

const createCanvasWijck = async (cloneImgSrc, coordinate, city, country, frame, orientation) => {
  const borderColor = {
    gold: '#c9b591',
    black: '#000000',
    noFrame: '#c7c7c7',
  };
  const addText = (canvas, ctx) => {
    const variables = {
      portrait: {
        coordinate: [150, 244],
        city: [150, 259],
        country: [150, 266],
        coordinateFZ: '3.5px',
        cityFZ: '16px',
        countryFZ: '3.8px',
        logoFz: '1.8px',
      },
      landscape: {
        coordinate: [150, 216],
        city: [150, 228],
        country: [150, 232.6],
        coordinateFZ: '3.5px',
        cityFZ: '11.5px',
        countryFZ: '3.3px',
        logoFz: '1.5px',
      }
    };
    ctx.fillStyle = '#000000';
    ctx.font = `${variables[orientation].coordinateFZ} SourceSansPro-Regular`;
    ctx.textAlign = 'center';
    const newCity = city ? city.split('').join(String.fromCharCode(8202)).toUpperCase() : ' ';
    const newCountry = country || ' ';
    const newCoordinate = coordinate || ' ';
    ctx.fillText(`${newCoordinate}`, variables[orientation].coordinate[0],
      variables[orientation].coordinate[1]);
    ctx.font = `${variables[orientation].cityFZ} LeagueGothic`;
    ctx.fillText(`${newCity}`,
      variables[orientation].city[0], variables[orientation].city[1]);
    ctx.font = `${variables[orientation].countryFZ} DidotLTStd-Italic`;
    ctx.fillText(`${newCountry}`, variables[orientation].country[0],
      variables[orientation].country[1]);
  };

  const addGoldBorder = async (ctx) => {
    const position = {
      portrait: [46, 5, 208, 285],
      landscape: [10, 46, 280, 208],
    };
    await new Promise((res) => {
      const image = new Image(100, 100);
      image.onload = () => {
        document.body.appendChild(image);
        ctx.drawImage(image, position[orientation][0], position[orientation][1],
          position[orientation][2], position[orientation][3]);
        res();
      };
      image.src = orientation === 'portrait' ? goldFramePortrait : goldFrameLandscape;
    });
  };

  const addGradientImage = async (canvas, ctx) => {
    const position = {
      portrait: [62, 241, 176, 36],
      landscape: [24, 210, 253, 30]
    };
    await new Promise((res) => {
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, position[orientation][0], position[orientation][1],
          position[orientation][2], position[orientation][3]);
        res();
      };
      image.src = backgroundGradient;
    });
  };

  const addBorder = async (canvas, ctx) => {
    if (frame === 'gold') {
      await addGoldBorder(ctx);
      return;
    }
    const blackFrame = 9;
    const noFrame = 0.5;
    ctx.lineWidth = frame === 'black' ? blackFrame : noFrame;
    ctx.strokeStyle = borderColor[frame];
    const position = {
      portrait: [46, 5, 208, 285],
      landscape: [10, 46, 280, 208]
    };
    ctx.strokeRect(position[orientation][0], position[orientation][1],
      position[orientation][2], position[orientation][3]);
  };

  const addDoubleBorder = async (canvas, ctx) => {
    ctx.lineWidth = 1;
    ctx.strokeStyle = borderColor.black;
    const borderPositionInner = {
      portrait: [61, 17, 177, 260],
      landscape: [23, 59, 255, 181]
    };
    // const borderPositionOuter = {
    //   portrait: [57, 13, 185, 268],
    //   landscape: [19, 55, 263, 189]
    // };
    ctx.strokeRect(borderPositionInner[orientation][0], borderPositionInner[orientation][1],
      borderPositionInner[orientation][2], borderPositionInner[orientation][3]);
    ctx.lineWidth = 2;
    ctx.strokeStyle = borderColor.black;
    // ctx.strokeRect(borderPositionOuter[orientation][0], borderPositionOuter[orientation][1],
    //   borderPositionOuter[orientation][2], borderPositionOuter[orientation][3]);
  };


  const createImage = async (canvas, ctx) => {
    const position = {
      portrait: [62, 18, 176, 259],
      landscape: [24, 60, 253, 180]
    };
    await new Promise((res) => {
      const image = new Image();
      image.onload = () => {
        ctx.drawImage(image, position[orientation][0], position[orientation][1],
          position[orientation][2], position[orientation][3]);
        res();
      };
      image.src = cloneImgSrc;
    });
  };

  const fillingCanvas = async (canvas, ctx) => {
    // add border
    await addBorder(canvas, ctx);
    // add image
    await createImage(canvas, ctx);
    // add doubleBorder
    await addDoubleBorder(canvas, ctx);
    // add gradient
    await addGradientImage(canvas, ctx);
    // add text
    addText(canvas, ctx);

    return canvas.toDataURL('image/png');
  };

  const initCanvas = async () => {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 300;
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'rgba(255, 255, 255, 0.01)';
    ctx.fillRect(0, 0, 300, 300);
    return { canvas, ctx };
  };

  const start = async () => {
    const { canvas, ctx } = await initCanvas();
    // eslint-disable-next-line no-return-await
    return await fillingCanvas(canvas, ctx);
  };
  // eslint-disable-next-line no-return-await
  return await start();
};

export default createCanvasWijck;
