import { LOCALES } from '../locales';

export default {
  [LOCALES.GERMAN]: {
    'Location.title': 'Wähle deinen Ausschnitt',
    'Location.subTitle': 'Suche nach einer Stadt, verschiebe und zoome die Karte und finden Sie Ihren Lieblingsort.',
    'Location.search_input': 'Finde deinen Lieblingsplatz',
    'Location.label_randomCity': 'Drehe das Rad!',
    'Location.button_randomCity': 'Wähle eine zufällige Stadt',
    'Location.label_bestsellers': 'Unsere Bestseller!',
    'Location.button_nextStep': 'Weiter: Anpassen',


    'Customise.title': 'Passen Sie Ihren Druck an',
    'Customise.subTitle': 'Personalisieren Sie Ihre Karte noch mehr! Schreiben Sie Ihren eigenen Text oder '
      + 'Ihre eigene Nachricht und bestimmen Sie die Ausrichtung Ihres Eindrucks',
    'Customise.label_orientation': 'Ausrichtung',
    'Customise.input_portrait': 'Hochformat',
    'Customise.input_landscape': 'Querformat',
    'Customise.label_style': 'Stil',
    'Customise.label_text': 'Text',
    'Customise.button_useCoordinatesText': 'Verwenden Sie die Stadtkoordinaten für den Titel',
    'Customise.button_nextStep': 'Weiter: Größe & Extras',


    'SizeExtras.title': 'Größe & Extras',
    'SizeExtras.subTitle': 'Wählen Sie eine Größe und vervollständigen Sie sie mit einem unserer schönen Rahmen!',
    'SizeExtras.label_size': 'Größe',
    'SizeExtras.input_small': 'Klein',
    'SizeExtras.input_medium': 'Mittel',
    'SizeExtras.input_large': 'Groß',
    'SizeExtras.input_extraLarge': 'Extra Groß',
    'SizeExtras.label_frame': 'Rahmen',
    'SizeExtras.input_noFrame': 'Kein Rahmen',
    'SizeExtras.input_black': 'Schwarz - Holzrahmen',
    'SizeExtras.input_gold': 'Gold - Aluminiumrahmen',
    'SizeExtras.button_nextStep': 'Weiter: Ergebnis und Preis',


    'AddToCart.title': 'Ergebnis und Preis',
    'AddToCart.subTitle': 'Hiermit Ihr Endergebnis und Preis. Um zu bestellen, '
      + 'klicken Sie auf "Zum Warenkorb hinzufügen”.',
    'AddToCart.size': 'Größe',
    'AddToCart.frame': 'Rahmen',
    'AddToCart.price': 'Preis',
    'AddToCart.customise': 'Karte anpassen',
    'AddToCart.button_nextStep': 'Zum Warenkorb hinzufügen - {price}',
    'AddToCart.button_nextStep1': 'Zum Warenkorb hinzufügen',


    'Save.title': 'Speichern Sie Ihren Ausdruck',
    'Save.subTitle': 'Ihre Anpassung ist fast fertig! Unten können Sie Ihren Namen und Ihre Beschreibung eingeben.'
      + ' Klicken Sie auf die Schaltfläche, um Ihren Ausdruck zu speichern.',
    'Save.details': 'Einzelheiten',
    'Save.customise': 'Anpassung',
    'Save.name': 'Name',
    'Save.description': 'Beschreibung',
    'Save.button_nextStep': 'Speichern',
    'Save.portrait': 'Hochformat',
    'Save.landscape': 'Querformat',

    'LocationB2B.button_nextStep': 'Weiter: Anpassen',
    'CustomiseB2B.button_nextStep': 'Weiter: Größe & Extras',
    'SaveB2B.button_nextStep': 'Speichern',

    'Menu.location': 'Ausschnitt',
    'Menu.customise': 'Anpassen',
    'Menu.sizeExtras': 'Größe & Extras',
    'Menu.addToCart': 'Hinzufügen',
    'Menu.save': 'Speichern',
  }
};
